import { Link, NavLink, useLocation } from "react-router-dom";
import { bemCN } from "../../configs/bem-classname";
import { navLinks } from "../../consts/navbar-links";
import { ReactComponent as ArrowBottom } from "../../shared/images/svg/arrow-bottom-big.svg";
import "./top-menu-component.scss";

const navCn = bemCN("header-nav");

const Menu = () => {
  return (
    <nav className={navCn("", "h2")}>
      <ol>
        {navLinks.map((link) => (
          <li key={link.title} className={navCn("link")}>
            <div>
              {link.path ? (
                <Link
                  key={link.title}
                  className={navCn("link", "h4")}
                  to={link.path}
                >
                  {link.title}
                </Link>
              ) : (
                <div>{link.title}</div>
              )}
              {link.children && <ArrowBottom key="ArrowBottom" />}
            </div>
            {link.children && (
              <section className={navCn("sublink")}>
                {link.children.map((l) => {
                  return (
                    <Link
                      key={l.title}
                      className={navCn("sublink_item", "h4")}
                      to={l.path}
                    >
                      {l.title}
                    </Link>
                  );
                })}
              </section>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

const TopMenu = () => {
  let location = useLocation();
  if (location.pathname.match(/card/)) {
    return null;
  }

  return <Menu />;
};

export default TopMenu;
