import { FC, useEffect, useState } from 'react';
import './feedback-component.scss';
import { ReactComponent as Phone } from '../../../shared/images/svg/icon-main-phone.svg';
import { ReactComponent as Time } from '../../../shared/images/svg/icon-main-time.svg';
import { ReactComponent as Address } from '../../../shared/images/svg/icon-main-address.svg';
import { bemCN } from '../../../configs/bem-classname';
import { phone } from '../../../consts/phones';
import Selected from '../../../components/selected-component/selected-component';
import { SelectItem } from '../../../core/view-models/select-item';
import { Modal } from '../../../components/modal-component/modal-component';
import { CaptchaTest } from '../../../ui/captcha/capthca';

interface FeedbackProps {}

const feedbackCn = bemCN('feedback');

const Feedback: FC<FeedbackProps> = () => {
  const [feetback, setFeetBact] = useState<{
    name: string;
    email: string;
    phone: string;
    speciality?: string;
    message: string;
    idSpeciality: number;
  }>({ name: '', phone: '', email: '', speciality: '', message: '', idSpeciality: 0 });
  const [roles, setRoles] = useState<{ id: number; name: string }[]>([]);

  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    setRoles([{id:1,name:"Менеджер"},{id:2,name:"Аналитик"}])
  }, [1]);

  const sendMessage = () => {
    
  };

  const onClickSendButton = () => {
    setIsShowModal(true);
  };

  return (
    <div className={feedbackCn()}>
      <div>
        <p>Связаться с нами. Это просто.</p>
        <p>
          Хотите увеличить продажи на Wildberries и Ozon? Оставьте заявку, и мы расскажем, как XSTAT
          поможет вам найти прибыльные ниши и эффективно управлять рекламой.
        </p>
        <div>
          <div className={feedbackCn('info-block')}>
            <Phone />
            <div>
              <p>Звоните сейчас</p>
              <p>{phone}</p>
            </div>
          </div>
          <div className={feedbackCn('info-block')}>
            <Time />
            <div>
              <p>График работы</p>
              <p>9:00 - 18:00</p>
            </div>
          </div>
          <div className={feedbackCn('info-block')}>
            <Address />
            <div>
              <p>График работы</p>
              <p>Ярославль, Ленина 25, офис 1</p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className={feedbackCn('row-inputs')}>
          <div className={feedbackCn('f-input')}>
            <label htmlFor="name">Ваше имя</label>
            <input
              id="name"
              type="text"
              placeholder="Имя"
              value={feetback.name}
              onChange={(v: any) => setFeetBact({ ...feetback, name: v.target.value })}
            />
          </div>
          <div className={feedbackCn('f-input')}>
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="text"
              placeholder="consult@mail.com"
              value={feetback.email}
              onChange={(v: any) => setFeetBact({ ...feetback, email: v.target.value })}
            />
          </div>
        </div>
        <div className={feedbackCn('row-inputs')}>
          <div className={feedbackCn('f-input')}>
            <label htmlFor="phone">Телефон</label>
            <input
              id="phone"
              type="number"
              placeholder="+7 999 999 99 99"
              value={feetback.phone}
              onChange={(v: any) => setFeetBact({ ...feetback, phone: v.target.value })}
            />
          </div>
          <div className={feedbackCn('f-input')}>
            <p>Специальность</p>
            <Selected
              active={feetback.idSpeciality}
              items={roles.map((r)=>{return new SelectItem(r.id,r.name)})}
              OnChange={(id: number) => {
                setFeetBact({
                  ...feetback,
                  idSpeciality: id,
                  speciality: roles.find((r: any) => r.id === id)?.name,
                });
              }}
            />
          </div>
        </div>
        <div className={feedbackCn('f-input')}>
          <label htmlFor="message">Сообщение</label>
          <textarea
            id="message"
            placeholder="Оставьте сообщение, и наши специалисты готовы предоставить вам необходимую консультацию."
            value={feetback.message}
            onChange={(v: any) => setFeetBact({ ...feetback, message: v.target.value })}
          />
        </div>
        <button onClick={() => onClickSendButton()}>Отправить</button>
      </div>
      <Modal title="" isShowDialog={isShowModal} setIsShowDialog={setIsShowModal}>
        <CaptchaTest successCaptcha={sendMessage}/>
      </Modal>
    </div>
  );
};

export default Feedback;
