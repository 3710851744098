import { bemCN } from "../../configs/bem-classname";
import { OrderCard } from "../../modules/orders-module";
import "./order-card-page.scss"
const rootCN = bemCN("OrderCardPage");

export const OrderCardPage = () => {
  return (
    <div className={rootCN()}>
      <OrderCard/>
    </div>
  );
};