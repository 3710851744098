import { TableObjectI } from "../interfaces/table-object-i";
import { TableDataItem } from "../view-models/table-data-item";
import { TableDataRow } from "../view-models/table-data-row";

export class Product implements TableObjectI {
    id?:number|undefined;
    name?:string|null;
    link?: string;
    cost?:number|null;
    category?:string;
    seller?:string;
    brand?:string;
    rating?:number;


    constructor(id:number|undefined, name: string|undefined|null, link?:string|undefined, 
        cost?:number|undefined|null, category?:string|undefined, seller?:string|undefined, brand?:string|undefined, rating?:number|undefined) {
        this.id = id;
        this.name = name;
        this.link = link;
        this.cost = cost;
        this.category = category;
        this.seller = seller;
        this.brand = brand;
        this.rating= rating;
      }

    mapToTableDataRow(): TableDataRow {
        let tableDataItems: TableDataItem[] = [];
        tableDataItems.push(new TableDataItem("name", this.name, this.link));
        tableDataItems.push(new TableDataItem("cost", this.cost));
        tableDataItems.push(new TableDataItem("category", this.category));
        tableDataItems.push(new TableDataItem("seller", this.seller));
        tableDataItems.push(new TableDataItem("brand", this.brand));
        tableDataItems.push(new TableDataItem("rating", this.rating));
        




        // Object.entries(this).forEach(([key, value]) => {
        //     if(key!=="id" && key!=="link") tableDataItems.push(new TableDataItem(key, value))});
            
        return new TableDataRow(this.id?this.id:-1,tableDataItems);
    }
}