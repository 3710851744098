import { useEffect, useState } from "react";
import "./moving-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as Delete } from "../../../../shared/images/svg/delete.svg";
import { ReactComponent as Clip } from "../../../../shared/images/svg/clip.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as TrashBin } from "../../../../shared/images/svg/trash-bin.svg";
import { Button } from "../../../../ui/button/button";
import Table from "../../../../components/table-component/table-component";
import { Modal } from "../../../../components/modal-component/modal-component";
import { DocumentsModal } from "../../../../components/modal-component/document-modal-component/document-modal-component";
import { API } from "../../../../consts/api";
import Selected from "../../../../components/selected-component/selected-component";
import { SelectItem } from "../../../../core/view-models/select-item";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { ConfirmationModal } from "../../../../components/modal-component/confirmation-modal/confirmation-modal";
import { TableDataSearchItem } from "../../../../core/view-models/table-search-item";
import HeaderCard from "../../../../components/header-card-component/header-card-component";

const movingCardCN = bemCN("movingCard");

export const MovingCard = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [movement, setMovement] = useState(
    location.state && location.state.movement
      ? location.state.movement
      : {
          id: 0,
          number: Number(Date.now().toString().slice(-9)),
          dateTime: new Date().toLocaleString("de-DE", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }),
          movementStatusId: 1,
          movementStatus: "",
          authorDocumentId: 1,
          authorDocument: "",
          contractorSourceId: 0,
          contractorSource: "",
          contractorDestinationId: 0,
          contractorDestination: "",
          storeSourceId: 0,
          storeSource: "",
          storeDestitationId: 0,
          storeDestitation: "",
          comment: "",
          priceCost: 0,
          expenses: [],
          goods: [],
          goodsString: "",
          contractorAndStoreSource: "",
          contractorAndStoreDestitation: "",
          closed: false,
        }
  );
  const [movementStatuses, setMovementStatuses] = useState<any[]>([]);
  const [contractors, setContractors] = useState<any[]>([]);
  const [stores, setStores] = useState<any[]>([]);

  const [expenseType, setExpenseType] = useState<any[]>([]);
  const [totalSummMoving, setTotalSummMoving] = useState(0);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [IsNew, SetIsNew] = useState(
    location.state && location.state.movement ? false : true
  );
  const [IsEdit, SetIsEdit] = useState(IsNew);
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [isShowModalClose, setIsShowModalClose] = useState(false);
  const [isAddingExpense, setIsAddingExpense] = useState(true); //Показывает, можно ли добавить еще один вид расходов
  const [stocksSender, setStocksSender] = useState<any[]>([]);
  const [stocksDestitation, setStocksDestitation] = useState<any[]>([]);
  const [Goods, SetGoods] = useState<any[]>([]);

  const [IsUpdate, SetIsUpdate] = useState<boolean>(false);
  const [errorDelete, setErrorDelete] = useState("");
  const [isShowErrorDelete, setIsShowErrorDelete] = useState(false);

  useEffect(() => {
    if (IsUpdate) {
      API.goodMovementUpdateCostPriceCreate(movement)
        .then((res) => {
          setMovement(res.data);
        })
        .catch((e) => console.log(e));
      SetIsUpdate(false);
    }
  }, [IsUpdate]);
  const onAddRow = (object: any) => {
    let newG: any[] = movement.goods;
    newG.push(object);

    setMovement({ ...movement, goods: newG });
    SetIsUpdate(true);
  };

  const onClose = () => {
    API.goodMovementCloseUpdate({ ...movement, closed: true }).then(() => {
      SetIsEdit(false);
      setMovement({ ...movement, closed: true });
      setIsShowModalClose(false);
    });
  };

  const onDelRow = (index: number) => {
    let newO = movement.goods;
    newO.splice(index, 1);
    setMovement({ ...movement, goods: newO });
    SetIsUpdate(true);
  };

  //Выбор товара при добавлении в таблице
  const onSelect = (index: number, object: any) => {
    let goods = movement.goods;
    if (index === -1) {
      let newG: any[] = movement.goods;
      newG.push(object);
      setMovement({ ...movement, goods: newG });
    } else {
      goods.map((e: any) => {
        if (e.goodId === index) {
          e.goodId = object.id;
          e.name = object.name;
        }
      });
    }

    setMovement({ ...movement, goods: goods });
  };

  //Обновление общей суммы перемещения
  useEffect(() => {
    let totalSum = 0;
    movement.goods.map((good: any) => {
      totalSum += good.priceOne * good.count;
    });
    setTotalSummMoving(totalSum);
  }, [movement]);

  //Обновление отправителя
  useEffect(() => {
    if (movement.contractorSourceId > 0) {
      API.storeGetAllItemsList().then((res) => {
        let stacks = res.data.items;
        API.contractorDetail(movement.contractorSourceId).then((res) => {
          let stackId = res.data.storesId;
          let sts = stacks?.filter((s: any) => stackId?.includes(s.id));
          setStocksSender(sts);
        });
      });
    }
  }, [movement.contractorSourceId]);
  //Обновление получателя
  useEffect(() => {
    if (movement.contractorDestinationId > 0) {
      API.storeGetAllItemsList().then((res) => {
        let stacks = res.data.items;
        API.contractorDetail(movement.contractorDestinationId).then((res) => {
          let stackId = res.data.storesId;
          let sts = stacks?.filter((s: any) => stackId?.includes(s.id));
          setStocksDestitation(sts);
        });
      });
    }
  }, [movement.contractorDestinationId]);

  //Загрузка доп. информации
  useEffect(() => {
    API.movementStatusGetAllItemsList().then((res) => {
      setMovementStatuses([...res.data.items]);
    });
    API.storeGetAllItemsList().then((res) => {
      setStores(res.data.items);
    });
    API.expenseGetAllItemsList().then((res) => {
      setExpenseType(res.data.items);
    });
    API.contractorGetAllItemsList().then((res) => {
      setContractors(res.data.items);
    });
    API.goodMovementGetGoodsbyStoreIdList({
      storeId: movement.storeSourceId,
    }).then((res) => {
      SetGoods([...res.data]);
    });
  }, []);

  //Удаление перемещения
  const deleteMovement = () => {
    setIsShowModalDelete(false);
    API.goodMovementDelete(movement.id)
      .then(() => navigate("/procurement/movings"))
      .catch((e) => {
        setErrorDelete(e.response.data);
        setIsShowModalDelete(false);
        setIsShowErrorDelete(true);
      });
  };

  //Добавление расходов
  const addExpense = () => {
    let expenses = movement.expenses;
    let exs = expenseType.filter(
      (e: any) => !expenses.map((ex: any) => ex.expenseId).includes(e.id)
    );
    if (exs.length > 0) {
      let newId = Math.max(...exs.map((e: any) => e.id));
      expenses.push({
        expenseId: newId,
        name: expenseType.find((e: any) => e.id === newId).name,
        price: 0,
      });
      setMovement({ ...movement, expenses: expenses });
      exs.length === 1 && setIsAddingExpense(false);
    }
    SetIsUpdate(true);
  };
  //Удаление расходов
  const deleteExpense = (id: number) => {
    let expenses = movement.expenses;
    expenses = expenses.filter((e: any) => e.expenseId !== id);
    setMovement({ ...movement, expenses: expenses });
    setIsAddingExpense(true);

    SetIsUpdate(true);
  };

  //Изменение суммы расходов
  const updatePriceExpense = (id: number, value: string | number) => {
    if (value === "") {
      value = 0;
    }
    let expenses = movement.expenses;
    let updateExpense = expenses.find((e: any) => e.expenseId === id);
    updateExpense.price = value;
    let newExpenses = expenses.map((e: any) => {
      if (e.expenseId !== id) return e;
      else return updateExpense;
    });
    setMovement({ ...movement, expenses: newExpenses });

    SetIsUpdate(true);
  };

  //Изменение названия расходов
  const updateNameExpense = (id: number, value: number) => {
    let expenses = movement.expenses;
    let updateExpense = expenses.find((e: any) => e.expenseId === id);
    updateExpense.expenseId = value;
    updateExpense.name = expenseType.find((e: any) => e.id === value).name;
    let newExpenses = expenses.map((e: any) => {
      if (e.expenseId !== id) return e;
      else return updateExpense;
    });
    setMovement({ ...movement, expenses: newExpenses });
  };
  //Обновление таблицы товаров
  const onUpdateRow = (index: number, object: any) => {
    let newGoods = movement.goods;
    newGoods[index] = object;
    setMovement({ ...movement, goods: newGoods });
    SetIsUpdate(true);
  };
  //Изменение или добавление перемещения
  const updateMovement = () => {
    let m = movement;
    m.contractorSource = contractors.find(
      (s: any) => s.id === movement.contractorSourceId
    ).name;
    m.contractorDestination = contractors.find(
      (s: any) => s.id === movement.contractorDestinationId
    ).name;
    m.storeSource = stores.find(
      (s: any) => s.id === movement.storeSourceId
    ).name;
    m.storeDestitation = stores.find(
      (s: any) => s.id === movement.storeDestitationId
    ).name;

    if (IsNew) {
      API.goodMovementAddCreate(m).then((res) => {
        setMovement(res.data);
        SetIsEdit(false);
        SetIsNew(false);
      });
    } else {
      API.goodMovementUpdateUpdate(m).then(() => {
        SetIsEdit(false);
        SetIsNew(false);
      });
    }
  };

  const filterGoodsToStoreAvalableMovement = (store: number) => {
    API.goodMovementGetGoodsbyStoreIdList({ storeId: store }).then((res) => {
      setMovement({
        ...movement,
        storeSourceId: store
      });
      SetGoods(res.data);
    });
  };
  return (
    <div className={movingCardCN()}>
      <HeaderCard
        toBack={() => {
          if (IsEdit && !IsNew) {
            API.goodMovementDetail(movement.id).then((res) => {
              setMovement(res.data);
              SetIsEdit(false);
            });
            SetIsEdit(false);
          } else {
            navigate("/procurement/movings");
          }
        }}
        isEdit={IsEdit}
        isNew={IsNew}
        titleText="Оформление перемещения"
        setIsEdit={SetIsEdit}
        number={movement.number}
        date={movement.dateTime}
        statuses={movementStatuses}
        currentStatusId={movement.movementStatusId}
        currentStatus={movement.movementStatus}
        currentWorker={movement.authorDocument}
        updateNubmber={(v: number) => {
          setMovement({ ...movement, number: v });
        }}
        updateDate={(v: string) => {
          setMovement({ ...movement, dateTime: v });
        }}
        updateStatus={(v: number) => {
          setMovement({
            ...movement,
            movementStatusId: v,
            movementStatus: movementStatuses.find((s: any) => s.id === v).name,
          });
        }}
        clickHistory={() => {}}
      />
      <div className={movingCardCN("info")}>
        <div>
          {movement && contractors.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Отправитель:"
              text={
                movement.contractorSource !== ""
                  ? movement.contractorSource
                  : ""
              }
              isActive
            />
          ) : (
            <div className={movingCardCN("selected")}>
              <p className="h4">
                <span className={movingCardCN("required")}>* </span>{" "}
                Отправитель:
              </p>
              <Selected
                OnChange={(v) => {
                  setMovement({ ...movement, contractorSourceId: v });
                }}
                active={movement.contractorSourceId}
                items={contractors.map((item) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>
        <div>
          {movement && contractors.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Получатель:"
              text={
                movement.contractorDestination !== ""
                  ? movement.contractorDestination
                  : ""
              }
              isActive
            />
          ) : (
            <div className={movingCardCN("selected")}>
              <p className="h4">
                <span className={movingCardCN("required")}>* </span> Получатель:
              </p>
              <Selected
                OnChange={(v) => {
                  setMovement({
                    ...movement,
                    contractorDestinationId: v,
                  });
                }}
                active={movement.contractorDestinationId}
                items={contractors.map((item) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>
        <div>
          {movement && contractors.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Откуда:"
              text={movement.storeSource !== "" ? movement.storeSource : ""}
              isActive
            />
          ) : (
            <div className={movingCardCN("selected")}>
              <p className="h4">
                <span className={movingCardCN("required")}>* </span> Откуда:
              </p>
              <Selected
                OnChange={(v) => {
                  filterGoodsToStoreAvalableMovement(v);
                }}
                active={movement.storeSourceId}
                items={stocksSender.map((item: any) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>
        <div>
          {movement && contractors.length > 0 && !IsEdit ? (
            <InputText
              isRequired
              header="Куда:"
              text={
                movement.storeDestitation !== ""
                  ? movement.storeDestitation
                  : ""
              }
              isActive
            />
          ) : (
            <div className={movingCardCN("selected")}>
              <p className="h4">
                <span className={movingCardCN("required")}>* </span> Куда:
              </p>
              <Selected
                OnChange={(v) => {
                  setMovement({
                    ...movement,
                    storeDestitationId: v
                  });
                }}
                active={movement.storeDestitationId}
                items={stocksDestitation.map((item: any) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>

        <div className={movingCardCN("comment")}>
          <InputText
            header="Комментарий:"
            text={movement && movement.comment != null ? movement.comment : ""}
            isActive={!IsEdit}
            OnChange={(v) => {
              setMovement({ ...movement, comment: v });
            }}
          />
        </div>
      </div>

      <div className={movingCardCN("title", ["h3"])}>Расходы</div>
      {movement && movement.expenses && movement.expenses.length > 0
        ? movement.expenses.map((item: any) => {
            return (
              <div key={item.expenseId}>
                <div className={movingCardCN("info", ["expen"])}>
                  <div>
                    <InputText
                      header="Сумма:"
                      text={item ? item.price : ""}
                      isActive={!IsEdit}
                      isDigitOnly
                      OnChange={(v) => updatePriceExpense(item.expenseId, v)}
                    />
                  </div>
                  <div>
                    {movement && !IsEdit ? (
                      <InputText
                        header="Расходы:"
                        text={item ? item.name : ""}
                        isActive={true}
                      />
                    ) : (
                      <>
                        {" "}
                        <div className={movingCardCN("selected")}>
                          <p className="h4">Расходы:</p>
                          <Selected
                            OnChange={(v) => {
                              updateNameExpense(item.expenseId, v);
                            }}
                            active={item.expenseId}
                            items={expenseType
                              .filter(
                                (expense: any) =>
                                  expense.id === item.expenseId ||
                                  !(
                                    movement.expenses.filter(
                                      (e: any) => e.expenseId === expense.id
                                    ).length > 0
                                  )
                              )
                              .map((exp: any) => {
                                return new SelectItem(exp.id, exp.name);
                              })}
                          />
                        </div>
                        <button onClick={() => deleteExpense(item.expenseId)}>
                          <Delete />
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            );
          })
        : null}
      {IsEdit && isAddingExpense ? (
        <div className={movingCardCN("add-expense-btn")}>
          <Plus />
          <Button white text="Добавить расходы" onClick={() => addExpense()} />
        </div>
      ) : null}

      <div className={movingCardCN("table_head")}>
        <div>
          <span className={movingCardCN("table_head_active", ["h3"])}>
            Наименования
          </span>
          <span className={movingCardCN("table_head_count", ["h3"])}>
            {movement && movement.goods.length > 0 ? movement.goods.length : 0}
          </span>
        </div>
        <div className={movingCardCN("table_head_buttons")}>
          {/* <Button id="add" text="+" circle />
          <Button
            text="Прикрепить"
            onClick={() => {
              setIsShowDialog(true);
            }}
            icon={<Clip />}
            text-image-left
          /> */}
          <p className="h2">Итого: {movement ? totalSummMoving : 0}</p>
        </div>
      </div>
      <Table
        isSearchTableAllData
        isAutofill
        isNames
        isEditable={IsEdit || IsNew}
        headersGenerate={location.state.headersGoods}
        dataGenerate={movement.goods}
        excludeIndexes={[1, 2, 4, 5]}
        isAdding={IsEdit || IsNew}
        onUpdateRow={onUpdateRow}
        onAddRow={onAddRow}
        onDelRow={onDelRow}
        searchData={[new TableDataSearchItem(0, "name", Goods)]}
        nameSelectSearch="goodId"
        onSelect={onSelect}
      />

      <div className={movingCardCN("buttons")}>
        <Button
          text={"Закрыть"}
          white-outline
          onClick={() => {
            if (IsEdit && !IsNew) {
              API.goodMovementDetail(movement.id).then((res) => {
                setMovement(res.data);
                SetIsEdit(false);
              });
              SetIsEdit(false);
            } else {
              navigate("/procurement/movings");
            }
          }}
        />
        {IsEdit ? (
          <Button onClick={() => updateMovement()} text={"Сохранить"} blue />
        ) : (
          movement.closed == false && (
            <Button text="Редактировать" blue onClick={() => SetIsEdit(true)} />
          )
        )}
        {IsEdit && movement.id != 0 && (
          <Button
            text="Завершить перемещение"
            blue
            onClick={() => setIsShowModalClose(true)}
          />
        )}
        {!IsNew && (
          <Button
            image-red
            icon={<TrashBin />}
            onClick={() => setIsShowModalDelete(true)}
          />
        )}
      </div>
      <Modal
        title="Прикрепленные документы"
        isShowDialog={isShowDialog}
        setIsShowDialog={setIsShowDialog}
      >
        <DocumentsModal />
      </Modal>
      <ConfirmationModal
        onClickAgree={deleteMovement}
        isShowDialog={isShowModalDelete}
        setIsShowDialog={setIsShowModalDelete}
        text={
          "Вы уверены, что хотите удалить перемещение № " +
          movement?.number +
          " от " +
          movement?.dateTime +
          "?"
        }
      />
      <ConfirmationModal
        onClickAgree={onClose}
        isShowDialog={isShowModalClose}
        setIsShowDialog={setIsShowModalClose}
        text={
          "Вы уверены, что хотите завершить перемещение № " +
          movement?.number +
          " от " +
          movement?.dateTime +
          "? После завершения перемещения изменения будут недоступны."
        }
      />
      <ConfirmationModal
        isOK
        isShowDialog={isShowErrorDelete}
        setIsShowDialog={setIsShowErrorDelete}
        text={errorDelete && errorDelete}
      />
    </div>
  );
};
