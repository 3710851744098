import React, { FC } from "react";
import "./reviews-component.scss";
import ReviewSlider from "../review-slider-component/review-slider-component";
import ClientAuthLink from "../client-auth-component/client-auth-component";
import Feedback from "../feedback-component/feedback-component";
import { bemCN } from "../../../configs/bem-classname";
import { links } from "../../../consts/links";
import { ReactComponent as Logo } from "../../../shared/images/svg/logo.svg";
import { ReactComponent as Facebook } from "../../../shared/images/svg/icon-facebook.svg";
import { ReactComponent as Instagram } from "../../../shared/images/svg/icon-instagram.svg";
import { ReactComponent as Telegram } from "../../../shared/images/svg/icon-telegram.svg";
import { ReactComponent as VK } from "../../../shared/images/svg/icon-vk.svg";
import { phone } from "../../../consts/phones";

interface MainReviewsProps {}

const mainReviewPageCn = bemCN("mainReviews");
const footerPageCn = bemCN("clientFooter");
const MainReviews: FC<MainReviewsProps> = () => {
  return (
    <div className={mainReviewPageCn()}>
      <h2 className={mainReviewPageCn("title")}>
        <span className={mainReviewPageCn("title_green")}>ОТЗЫВЫ</span> О CRM
        XSTAT
      </h2>
      <ReviewSlider></ReviewSlider>
      <h2 className={mainReviewPageCn("title")}>
        <span className={mainReviewPageCn("title_green")}>Оставьте</span> заявку
      </h2>
      <Feedback />
      <div>
        <div className={footerPageCn()}>
          <div className={footerPageCn("top")}>
            <div className={footerPageCn("logo")}>
              <Logo />
              <div className={footerPageCn("logo_text")}>STAT</div>
            </div>
            <div className={footerPageCn("links")}>
              <a href={`#tariff_section`} className={footerPageCn("link")}>
                Тарифы
              </a>
              <a href={`#advantages_section`} className={footerPageCn("link")}>
                Возможности
              </a>
            </div>
            <div className={footerPageCn("socials")}>
              {/* <a target="_blank" className={footerPageCn("social")}>
                <Instagram />
              </a>
              <a target="_blank" className={footerPageCn("social")}>
                <Facebook />
              </a> */}
              <a
                target="_blank"
                href="https://t.me/xstatru"
                className={footerPageCn("social")}
              >
                <Telegram />
              </a>
              <a
                target="_blank"
                href="https://vk.com/xstatru"
                className={footerPageCn("social")}
              >
                <VK />
              </a>
            </div>
          </div>
          <div className={footerPageCn("list")}>
            <div className={footerPageCn("item")}>
              <a
                className={footerPageCn("politics")}
                href={links.offer}
                target="_blank "
              >
                Договор-оферта
              </a>
              <a href={`mailto:sample@example.com`}>xstat@yandex.ru</a>
            </div>
            <div className={footerPageCn("item")}>
              <a
                className={footerPageCn("politics")}
                href={links.politics}
                target="_blank "
              >
                Политика обработки персональных данных
              </a>
              <a href={`tel:${"phone"}`}>{phone}</a>
            </div>
            <div className={footerPageCn("item")}>
              <span>
                Xstat © {new Date().getFullYear()}. Все права защищены
              </span>
              <ClientAuthLink />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainReviews;
