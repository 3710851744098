import "./table-component.scss";
import { bemCN } from "../../configs/bem-classname";
import { FC, useEffect, useId, useState } from "react";
import { TableItem } from "./table-item/table-item";
import { TableObjectI } from "../../core/interfaces/table-object-i";
import { TableEditableItem } from "./table-editable-item/table-editable-item";
import { statusList } from "../../consts/values/status-list";
import { MapToTableDataRow } from "../../shared/helpers/map-to-table";
import { ContainsExcludeCellRow } from "../../shared/helpers/contains-exclude-cell-row";

import { CreateObject } from "../../shared/helpers/create-object";
import { GetSuitableKey } from "../../shared/helpers/get-suitable-key";
import { TableSearchItem } from "./table-search-item/table-search-item";
import { TableDataSearchItem } from "../../core/view-models/table-search-item";
import { GuidGenerator } from "../../shared/helpers/guid-generator";

type TableProps = {
  id?: string;
  data?: TableObjectI[] | null;
  headers?: string[];
  headersGenerate?: any[];
  dataGenerate?: any[] | null;
  isNames?: boolean; //таблица наименований (строка с названием шире)
  isSortable?: boolean; //Сортируемая ли таблица
  isEditable?: boolean; //Нужно ли редактировать таблицу
  excludeIndexes?: number[]; //Индексы строки которые не нужно редактировать
  searchData?: TableDataSearchItem[]; //Индексы строки которые имеют поиск и дата
  nameSelectSearch?: string; //Название свойства идентификатора (не было id, было goodId)
  isAdding?: boolean; //Нужен ли функционал по добавлению строки в таблицу
  isAutofill?: boolean; //Нужно ли заполнять данные из выбранной ячейки
  isSearchTableAllData?: boolean; //В селекте показывает таблицу, а не список
  stickerName?: string; //Название заключающего столбца с кнопкой
  stickerButtonName?: string; //Название кнопки заключающего столбца
  stickerName2?: string; //Название заключающего столбца с кнопкой
  stickerButtonName2?: string; //Название кнопки заключающего столбца
  isClickable?: boolean;
  blockIndexes?: (number | null | undefined)[] | null | undefined; //Индексы товаров которые выглядят заблокированными
  onSelect?: (index: number, object: any) => void; //Метод изменения в поиске таблицы index - тот кого изменили, object - новый объект
  onClickRow?: (index: number) => void;
  onUpdateRow?: (index: number, object: any) => void; //Метод при обновления строки
  onAddRow?: (object: any) => void;
  onDelRow?: (index: number) => void;
  onClickSticker?: (index: number) => void;
  onClickSticker2?: (index: number) => void;
  onClickSort?: (header: string, desc: boolean) => void;
};

const Table: FC<TableProps> = ({
  id,
  data,
  headers,
  dataGenerate,
  headersGenerate,
  excludeIndexes = [],
  searchData = [],
  nameSelectSearch = "id",
  isEditable,
  isSortable,
  isAdding,
  isNames,
  isAutofill,
  isSearchTableAllData,
  stickerName,
  stickerButtonName,
  stickerName2,
  stickerButtonName2,
  isClickable,
  onClickSticker,
  onClickSticker2,
  onClickRow,
  onUpdateRow,
  onAddRow,
  onDelRow,
  onClickSort,
  blockIndexes,
}) => {
  const [selectRow, setSelectRow] = useState(-1);
  const [select, setSelect] = useState<any>();
  const initAddRow = headersGenerate && CreateObject(headersGenerate);
  const [addingRow, setAddingRow] = useState(initAddRow);
  const tableCN = bemCN("table");
  const rows = data && data.map((product) => product.mapToTableDataRow());
  const rowsGenerate =
    headersGenerate && dataGenerate
      ? dataGenerate.map((product) =>
          MapToTableDataRow(headersGenerate, product)
        )
      : [];
  const [headersSorting, setHeadersSorting] = useState<boolean[]>(
    new Array<boolean>(headersGenerate ? headersGenerate?.length : 0)
  );
  // const ids:string[] = [];
  // useEffect(()=>{
  //   Object.entries(heheadersGenerate).map((header, i) =>{})
  // },[])
  const OnAddRow = () => {
    //onAddRow && onAddRow({ ...addingRow });
    onAddRow && onAddRow(addingRow);
    let newV: any = { ...initAddRow };
    setAddingRow({ ...newV });
  };
  const OnSelect = (index: number, obj: any, isNew: boolean = false) => {
    if (index === -1 || isNew) {
      let add = addingRow;
      if (isAutofill) {
        add = obj;
      } else {
        add["goodId"] = obj.goodId ? obj.goodId : obj.id;
        add.name = obj.name;
      }
      setAddingRow({ ...add });
    } else {
      let add = select;
      if (isAutofill) {
        add = obj;
      } else {
        add.goodId = obj.id;
        add.name = obj.name;
      }

      setSelectRow({ ...add });
    }
  };
  const onClick = (index: number = -1) => {
    setSelectRow(index);
    if (index === -1) return;
    setSelect(dataGenerate && dataGenerate[index]);
    onClickRow && onClickRow(index);
  };
  const onChangeEditTable = (
    id: number,
    value: string,
    head: string,
    isNew: boolean = false
  ) => {
    if (isNew) {
      const h = GetSuitableKey(addingRow, head);
      addingRow[h] = value;
      setAddingRow({ ...addingRow });
    } else {
      if (!select) return;
      const h = GetSuitableKey(select, head);
      select[h] = Number(value);
      setSelect({ ...select });
      onUpdateRow && onUpdateRow(id, select);
    }
  };

  return (
    <div className={tableCN()}>
      <table id={id}>
        <thead>
          <tr className={isNames ? "tr" : ""}>
            {headersGenerate &&
              Object.entries(headersGenerate).map((header, i) => (
                <TableItem
                  isSortable={isSortable}
                  onClick={() => {
                    let h = headersSorting;
                    h[i] = !h[i];
                    setHeadersSorting(h);
                    onClickSort && onClickSort(header[0], headersSorting[i]);
                  }}
                  key={GuidGenerator()}
                  header
                  value={header[1]}
                />
              ))}
            {headers &&
              headers.map((header) => (
                <TableItem key={GuidGenerator()} header value={header} />
              ))}
            {stickerName && (
              <TableItem key={GuidGenerator()} header value={stickerName} />
            )}
             {stickerName2 && (
              <TableItem key={GuidGenerator()} header value={stickerName2} />
            )}
          </tr>
        </thead>
        <tbody>
          {rowsGenerate &&
            rowsGenerate.map((row, index) => (
              <tr
                className={
                  isNames
                    ? "tr"
                    : "" + blockIndexes && blockIndexes?.includes(index)
                    ? "block"
                    : ""
                }
                key={index}
                onClick={() => {
                  onClick(isClickable ? index : isEditable ? index : row.id);
                }}
              >
                {row.data.map((item, i) =>
                  isEditable &&
                  index === selectRow &&
                  !ContainsExcludeCellRow(row.data, item, excludeIndexes) ? (
                    searchData.find((s) => s.idColumn === i) ? (
                      <TableSearchItem
                        isTableAllData={isSearchTableAllData}
                        key={i + row.id + (id ? id : "")}
                        id={select[nameSelectSearch]}
                        data={searchData.find((s) => s.idColumn === i)}
                        OnSelect={OnSelect}
                        exceptionsData={dataGenerate}
                        isActive
                      />
                    ) : (
                      <TableEditableItem
                        isSetting={
                          isEditable &&
                          index === selectRow &&
                          row.data[row.data.length - 1] === item
                        }
                        onDelete={() => {
                          onDelRow && onDelRow(index);
                        }}
                        key={index.toString() + i.toString()}
                        select={item.isSelect}
                        value={item.value}
                        selectList={item.isSelect ? statusList : []}
                        onChange={(v) => {
                          onChangeEditTable(index, v, item.property);
                        }}
                        isActive
                      />
                    )
                  ) : (
                    <TableItem
                      key={GuidGenerator()}
                      onDelete={() => {
                        onDelRow && onDelRow(index);
                      }}
                      isSetting={
                        isEditable &&
                        index === selectRow &&
                        row.data[row.data.length - 1] === item
                      }
                      color={
                        isEditable && item.isSelect && isNaN(Number(item.value))
                          ? statusList[Number(item.value)].color
                          : undefined
                      }
                      text
                      value={
                        isEditable &&
                        item.isSelect &&
                        !isNaN(Number(item.value))
                          ? statusList[Number(item.value)].name
                          : item.value
                      }
                      link={item.link}
                    />
                  )
                )}
                {stickerName && (
                  <TableItem
                    key={GuidGenerator()}
                    onDelete={() => {
                      onDelRow && onDelRow(index);
                    }}
                    onClick={() => onClickSticker && onClickSticker(index)}
                    isSetting={isEditable && index === selectRow}
                    text
                    color="var(--blue)"
                    value={stickerButtonName}
                  />
                )}
                {stickerName2 && (
                  <TableItem
                    key={GuidGenerator()}
                    onDelete={() => {
                      onDelRow && onDelRow(index);
                    }}
                    onClick={() => onClickSticker2 && onClickSticker2(index)}
                    isSetting={isEditable && index === selectRow}
                    text
                    color="var(--blue)"
                    value={stickerButtonName2}
                  />
                )}
              </tr>
            ))}
          {rows &&
            rows.map((row, index) => (
              <tr
                key={index}
                onClick={() => onClick(isAdding ? index : row.id)}
              >
                {row.data.map((item) =>
                  isEditable && index === selectRow && item !== row.data[0] ? (
                    <TableEditableItem
                      key={item.property}
                      select={item.isSelect}
                      value={item.value}
                      selectList={item.isSelect ? statusList : []}
                    />
                  ) : (
                    <TableItem
                      key={item.property}
                      color={
                        isEditable && item.isSelect && isNaN(Number(item.value))
                          ? statusList[Number(item.value)].color
                          : undefined
                      }
                      text
                      value={
                        isEditable &&
                        item.isSelect &&
                        !isNaN(Number(item.value))
                          ? statusList[Number(item.value)].name
                          : item.value
                      }
                      link={item.link}
                    />
                  )
                )}
              </tr>
            ))}
          {isAdding && headersGenerate && (
            <tr onClick={() => onClick()}>
              {Object.entries(headersGenerate).map((header, i) => {
                return excludeIndexes.includes(i) ? (
                  <TableItem
                    key={i + "item"}
                    isSetting={selectRow === -1}
                    text
                    isNew
                    value={
                      addingRow[GetSuitableKey(addingRow, header[0])]
                        ? addingRow[GetSuitableKey(addingRow, header[0])]
                        : "0"
                    }
                    onUpdate={OnAddRow}
                  />
                ) : searchData.find((s) => s.idColumn === i) ? (
                  <TableSearchItem
                    //key={header[0]}
                    isTableAllData={isSearchTableAllData}
                    key={i + "search"}
                    exceptionsData={dataGenerate}
                    id={addingRow[nameSelectSearch]}
                    data={searchData.find((s) => s.idColumn === i)}
                    OnSelect={(i, o) => OnSelect(i, o, true)}
                    isActive
                  />
                ) : (
                  <TableEditableItem
                    key={i + "editable"}
                    value={
                      addingRow[GetSuitableKey(addingRow, header[0])]
                        ? addingRow[GetSuitableKey(addingRow, header[0])]
                        : "0"
                    }
                    isActive
                    isNew
                    isSetting={selectRow === -1}
                    onUpdate={OnAddRow}
                    onChange={(v) => {
                      onChangeEditTable(i, v, header[0], true);
                    }}
                  />
                );
              })}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
