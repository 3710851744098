import { AssemblyGoodItem } from "../../core/entities/assembly-good-item";

export const assemblyGoodItems = [
  new AssemblyGoodItem(1,"название", 2,"15.05", 5),
  new AssemblyGoodItem(2,"название", 2,"15.05", 5),
  new AssemblyGoodItem(3,"название", 2,"15.05", 5),
  new AssemblyGoodItem(4,"название", 2,"15.05", 5),
  new AssemblyGoodItem(5,"название", 2,"15.05", 5),
];

export const headersAssemblyGoodTable = [
  "№",
  "Товар",
  "Статус",
  "Время сборки",
  "Количество, шт"
];