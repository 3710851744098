import { bemCN } from "../../configs/bem-classname";
import "./counterparties-card-page.scss"
import { CounterpartiesCard } from "../../modules/counterparties-module";

const counterpartiesCardCN = bemCN("counterpartiesCard");

export const CounterpartiesCardPage = () => {
  return (
    <div className={counterpartiesCardCN()}>
      <CounterpartiesCard/>
    </div>
  );
};