import { bemCN } from "../../../../configs/bem-classname";
import "./tasks-component.scss";
import { FC, useEffect, useState } from "react";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { Button } from "../../../../ui/button/button";
import { ReactComponent as Additional } from "../../../../shared/images/svg/four-squares.svg";
import { groupTasks } from "../../../../consts/values/group-tasks";
import { ButtonNewGroupItem, TaskGroupItem } from "../..";
import { TaskGroup } from "../../../../core/entities/task-group";
import { API } from "../../../../consts/api";
import { Task } from "../../../../core/api/data-contracts";
type TaskProps = {
  id?: string;
};

export const Tasks: FC<TaskProps> = ({ id }) => {
  const tasksCN = bemCN("tasks");
  const [groups, SetGroups] = useState<any[]>([]);
  const [tasks, SetTasks] = useState<any[]>([]);

  const onAddNew = (value: string) => {
    const g: TaskGroup = new TaskGroup(0, value);
    API.taskFolderAddCreate(g).then((res) => {
      SetGroups([...groups, res.data]);
    });
  };

  const onDuplicateTask = (task: Task) => {
    task.id=0;
    onAdd(task);
  };

  const onUpdateTask = (task: Task) => {
    let g = tasks;
    let t = g.find((e: any) => e.id == id);
    t = task;
    SetTasks(g);
    API.taskUpdateUpdate(task);
  };
  const onDeleteTask = (id: number) => {
    let t = tasks;
    t = t.filter((e: any) => e.id !== id);
    SetTasks(t);
    API.taskDelete(id);
  };
  const onDelete = (id: number) => {
    let g = groups;
    g = g.filter((e: any) => e.id !== id);
    SetGroups(g);
    API.taskFolderDelete(id);
  };
  const onAdd = (task: Task) => {
    task.id=0;
    API.taskAddCreate(task);
    SetTasks([...tasks, task]);
  };
  const onSaveUpdateFolder = (id: number, name: string) => {
    let g = groups;
    let i: TaskGroup = g.find((e: TaskGroup) => e.id == id);
    i.name = name;
    SetGroups(g);
    API.taskFolderUpdateUpdate(i);
  };
  useEffect(() => {
    API.taskFolderGetAllItemsList().then((res: any) => {
      SetGroups(res.data.items);
    });
  },[]);
  useEffect(() => {
    API.taskGetAllItemsList().then((res: any) => {
      SetTasks(res.data.items);
    });
  }, []);
  return (
    <div id={id} className={tasksCN()}>
      <div className={tasksCN("topPanel")}>
        <Button id="additional" white-outline icon={<Additional />} />
        <RadioCalendar />
        <Calendar />
      </div>
      <div className={tasksCN("groups")}>
        <div>
          {groups.map((g) => {
            return (
              <TaskGroupItem
                OnDeleteTAsk={onDeleteTask}
                OnDuplicateTask={onDuplicateTask}
                OnUpdateTask={onUpdateTask}
                OnAddTask={onAdd}
                key={g.id + g.name}
                OnSave={onSaveUpdateFolder}
                OnDelete={onDelete}
                taskGroup={g}
                tasks={tasks.filter((x) => x.folderId == g.id)}
              />
            );
          })}
          <ButtonNewGroupItem onAddNew={onAddNew} />
        </div>
      </div>
    </div>
  );
};
