import { bemCN } from "../../configs/bem-classname";
import { Movings } from "../../modules/movings-module";
import "./movings-page.scss"
const rootCN = bemCN("movingsPage");

export const MovingsPage = () => {
  return (
    <div className={rootCN()}>
      <Movings />
    </div>
  );
};
