import React, { useState } from "react";
import "./register-verificate-email-modal.scss";
import { Modal } from "../components/modal-component/modal-component";
import { bemCN } from "../configs/bem-classname";

import { ReactComponent as Logo } from "../shared/images/svg/logo.svg";

interface RegisterVerificateEmailModalI {
  email?: string;
  setIsShow: (v: boolean) => void;
  isShow: boolean;
  changeData: () => void;
}

const registerVerificateEmailModalCn = bemCN("register-verificate-email-modal");
const RegisterVerificateEmailModal = ({
  email,
  isShow,
  setIsShow,
  changeData,
}: RegisterVerificateEmailModalI) => {
  return (
    <Modal isShowDialog={isShow} setIsShowDialog={setIsShow} title={""}>
      <div className={registerVerificateEmailModalCn()}>
        <div className={registerVerificateEmailModalCn("logo")}>
          {/* <Icon logo-middle /> */}
          <Logo />
          <span>stat</span>
        </div>
        <div className={registerVerificateEmailModalCn("title")}>
          Подтвеждение электронной почты
        </div>
        <div className={registerVerificateEmailModalCn("email")}>
          {/*Имя Фамилия,*/}
          {email}
        </div>
        <span
          className={registerVerificateEmailModalCn("change-data")}
          onClick={changeData}
        >
          Изменить данные
        </span>
        <div className={registerVerificateEmailModalCn("description")}>
          На вашу почту отправлено письмо с кодом. Пожалуйста, перейдите по
          ссылке из этого письма, чтобы войти в учетную запись.
        </div>
      </div>
    </Modal>
  );
};

export default RegisterVerificateEmailModal;
