import pdfMake from "pdfmake/build/pdfmake";
import {
  Box,
  Size,
  Supply,
} from "../../../core/api/data-contracts";
import { API } from "../../../consts/api";
import axios from "axios";
import { Buffer } from 'buffer';

// @ts-ignore
window.Buffer = Buffer;
const {createCanvas, loadImage} = require('canvas');
pdfMake.fonts = {
  Roboto: {
    normal:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf",
    italics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf",
    bolditalics:
      "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf",
  },
};

export async function CreateAssemblySheet(s: Supply) {
  let promisesGoods: any[] = [];
  s.boxes?.map((b: Box) => {
    b.goods?.map((b) => {
      promisesGoods.push(b.goodId && API.goodDetail(b.goodId));
    });
  });
  let Goods: any = await Promise.all(promisesGoods);
  s.name && pdfMake.createPdf(await createDoc(s, Goods)).download();
}

function base64SvgToBase64Png(originalBase64: any, width: any) {
  return new Promise((resolve) => {
    let img = document.createElement("img");
    img.onload = function () {
      document.body.appendChild(img);
      let canvas = document.createElement("canvas");
      let ratio = img.clientWidth / img.clientHeight || 1;
      document.body.removeChild(img);
      canvas.width = width;
      canvas.height = width / ratio;
      let ctx: any = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      try {
        let data = canvas.toDataURL("image/png");
        resolve(data);
      } catch (e) {
        resolve(null);
      }
    };
    img.onerror = function () {
      resolve(null);
    };
    img.src = originalBase64;
  });
}

//Генерация объекта файла
async function createDoc(s: Supply, goods: any[]) {
  let res: any = {
    content: [`Лист поставки ${s.id}: `],
    styles: {
      table: {
        fontSize: 10,
        margin: [0, 10, 0, 10],
      },
    },
  };
  s.boxes?.map(async (b, i) => {
    res.content.push(`Стикер короба "${b.id}":`);
    res.content.push(
      `Дата: ${new Date().toLocaleDateString("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })}    QR-код короба:${b.id} Количество товаров: ${b.goods?.length}`
    );

    let tableBody: any = [
      [
        "Номер задания",
        "Фото",
        "Бренд",
        "Наименование",
        "Размер",
        "Цвет",
        "Артикул продавца",
        "Стикер",
      ],
    ];

    b.goods?.map(async (g, i) => {
      let good: any;
      goods.forEach((r) => {
        let res = r.data.find((d: any) => d.localeID == g.goodId);
        if (res) {
          good = res;
        }
      });
      if (!good) return;
      let color = good.characteristics
        ?.find((c: any) => c.name == "14177449")
        ?.value.join(",");
      let barcode = good.sizes
        ?.find((s: Size) => s.wbSize == g.sizeRus)
        ?.skus.join(",");
        let img =  await base64SvgToBase64Png((await GetImg(good.photos[0].c246x328)).data_url,300);
      tableBody.push([
        g.wbCode,
        good?.photos
          ? { image:img, width: 30, height: 30 }
          : "-",
        good?.brand ? good.brand : "-",
        g.name,
        g.sizeEur,
        color ? color : "-",
        g.vendorCode,
        barcode ? barcode : "-",
        // { image: imgesGoods[i], width: 150, height: 100 },
      ]);
    });

    res.content.push({
      layout: "lightHorizontalLines",
      style: "table",
      table: {
        headerRows: 1,
        widths: [
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
          "auto",
        ],

        body: tableBody,
      },
    });
  });

  return res;
}

function getImage(url: any, size: any) {
  return loadImage(url).then((image:any) => {
    const canvas = createCanvas(size, size);
    let ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);
    return canvas.toDataURL();
});
}


async function GetImg(imageUrl:string) {

  // Fetch image from URL using Axios
  const response = await axios.get(imageUrl, {
      responseType: 'arraybuffer' // Ensure response data is treated as binary data
  });

  // Convert the image buffer to a base64 string
  const base64Image = Buffer.from(response.data, 'binary').toString('base64');

  // Determine the MIME type of the image (e.g., 'image/jpeg', 'image/png')
  const contentType = response.headers['content-type'];

  // Construct the data URL
  const dataUrl = `data:${contentType};base64,${base64Image}`;

  // Return data URL
  return {"data_url": dataUrl};
}