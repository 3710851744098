import { API } from "../../../consts/api";
import { Subcategory } from "../entities/subcategory";
export const GetSubCategoriesGood = (
  id_category: number,
  callback?: (res: Subcategory[]) => void
): void => {
  API.goodGetWbSubjectsList({ parentId: id_category }).then((res) => {
    callback && callback((<any>res.data).data);
  });
};
