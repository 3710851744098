import { FC, useState } from "react";
import "./assembly-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as ArrowBack } from "../../../../shared/images/svg/arrow-back.svg";
import { ReactComponent as Delete } from "../../../../shared/images/svg/delete.svg";
import { ReactComponent as Clip } from "../../../../shared/images/svg/clip.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import {
  assemblyGoodItems,
  headersAssemblyGoodTable,
} from "../../../../consts/values/assembly-goods";
import Table from "../../../../components/table-component/table-component";
import { Modal } from "../../../../components/modal-component/modal-component";
import { DocumentsModal } from "../../../../components/modal-component/document-modal-component/document-modal-component";

const assemblyCardCN = bemCN("assemblyCard");

export const AssemblyCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [assembly, Set] = useState(location.state && location.state.stock);
  const [IsEdit, SetIsEdit] = useState(assembly ? false : true);
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <div className={assemblyCardCN()}>
      <div className={assemblyCardCN("header")}>
        <div>
          <button
            onClick={() => {
              assembly && IsEdit
                ? SetIsEdit(false)
                : navigate("/sales/assembly");
            }}
          >
            <ArrowBack />
          </button>
          <h2>{assembly ? assembly.fullName : "Оформление сборки заказов"}</h2>
        </div>
        <div>
          <span className="h4">Не поставлено</span>
          <span className="h2">Петров В.В.</span>
        </div>
      </div>
      <div className={assemblyCardCN("info")}>
        <div>
          <InputText
            header="Заказ:"
            text={assembly ? assembly.fullName : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Дата сборки:"
            text={assembly ? assembly.shortName : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Срок:"
            text={assembly ? assembly.shortName : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Время:"
            text={assembly ? assembly.shortName : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>

      <div className={assemblyCardCN("info")}>
        <div>
          <InputText
            header="Склад:"
            text={assembly ? assembly.address : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Ответственный:"
            text={assembly ? assembly.comment : ""}
            isActive={!IsEdit}
          />
        </div>
        <div className={assemblyCardCN("info_com")}>
          <InputText
            id="comment"
            header="Комментарий:"
            text={assembly ? assembly.comment : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>

      <div className={assemblyCardCN("info", ["expen"])}>
        <div>
          <InputText
            header="Сумма:"
            text={assembly ? assembly.address : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Расходы:"
            text={assembly ? assembly.comment : ""}
            isActive={!IsEdit}
          />
          <button>
            <Delete />
          </button>
        </div>
      </div>
      <div>
        <span className="plus">+</span>
        <span className="h4">Расходы</span>
      </div>

      <div className={assemblyCardCN("table_head")}>
        <div>
          <span className={assemblyCardCN("table_head_active", ["h3"])}>
            Наименования
          </span>
          <span className={assemblyCardCN("table_head_count", ["h3"])}>12</span>
        </div>
        <div className={assemblyCardCN("table_head_buttons")}>
          {/* <Button id="add" text="+" circle />
          <Button
            text="Прикрепить"
            onClick={() => {
              setIsShowDialog(true);
            }}
            icon={<Clip />}
            text-image-left
          />
          <Button text="Готово" blue /> */}
          <p className="h2">12 000.58</p>
        </div>
      </div>
      <Table
        isEditable={true}
        data={assemblyGoodItems}
        headers={headersAssemblyGoodTable}
      />
      <div className={assemblyCardCN("buttons")}>
        <Button text="Отправить" blue />
        <Button text={"Закрыть"} white-outline />
      </div>
      <Modal
        title="Прикрепленные документы"
        isShowDialog={isShowDialog}
        setIsShowDialog={setIsShowDialog}
      >
        <DocumentsModal />
      </Modal>
    </div>
  );
};
