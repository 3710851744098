import { FC, useState } from "react";
import "./opportunities-component.scss";
import { bemCN } from "../../../configs/bem-classname";
import { ReactComponent as CheckMark } from "../../../shared/images/svg/check-mark-blue.svg";
import { ReactComponent as BarChartMain } from "../../../shared/images/svg/bar-chart-main-3s.svg";

interface OpportunitiesProps {}

const opportunitiesCn = bemCN("opportunities");
const Opportunities: FC<OpportunitiesProps> = () => {
  return (
    <div className={opportunitiesCn()}>
      <div>
        <div>
          <CheckMark /> <p>Управление поставками</p>
        </div>
        <div>
          <CheckMark /> <p>Мониторинг продаж</p>
        </div>
        <div>
          <CheckMark /> <p>Анализ маржинальности</p>
        </div>
        <div>
          <CheckMark /> <p>Отслеживание прогресса</p>
        </div>
      </div>
      <div>
        <p>
          <span>CRM от XSTAT</span> — это удобная и гибкая платформа с
          актуальными данными о продажах, выручке и маржинальности. С ее помощью
          легко отслеживать любые изменения в статусе и динамике закупок и
          заказов — просто включите уведомления.
        </p>
        <BarChartMain />
      </div>
    </div>
  );
};

export default Opportunities;
