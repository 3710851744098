import { bemCN } from "../../../configs/bem-classname";
import { SelectItem } from "../../../core/view-models/select-item";
import Popup from "../../../ui/popup/popup";
import { RadioGroup } from "../../radio-group-component/radio-group-component";

import "./radio-calendar-popup.scss";

import { FC } from "react";

type RadioCalendarPopupProps = {
  id?: string;
  onCheck?(): void;
  isShow: boolean;
  setIsShow(isShow: boolean): void;
  setCurrentDatePeriod(currentDate:number):void;
  items:string[];
  active:number;
};

const RadioCalendarPopup: FC<RadioCalendarPopupProps> = ({
  id,
  onCheck,
  isShow,
  setIsShow,
  setCurrentDatePeriod,
  items,
  active
}) => {
  const radioCalendarCardCN = bemCN("radio-calendar-popup");
  return (
    <Popup key={"radio-calendar"} isShow={isShow} setIsShow={setIsShow}>
      <div className={radioCalendarCardCN()}>
        <RadioGroup active={active} green-mark items={items.map((item, index) => {return new SelectItem(index, item)})} onChange={setCurrentDatePeriod}/>
      </div>
    </Popup>
  );
};

export default RadioCalendarPopup;
