import { bemCN } from "../../configs/bem-classname";
import './header-component.scss';
import { ReactComponent as Search } from "../../shared/images/svg/search.svg";
import { ReactComponent as  Favorite} from "../../shared/images/svg/favorite.svg";
import { ReactComponent as Profile } from "../../shared/images/svg/profile.svg";
import { ReactComponent as Notice } from "../../shared/images/svg/notice.svg";

const headerCn = bemCN('header'); 
 
const SearchHeader = () => { 
 
  return ( 
    <header className={headerCn()}> 
     <div className={headerCn('tariff')}>
      <span className="h3">Базовый тариф</span>
      {/* <Search />
      <input className="h3" placeholder="Поиск..."></input> */}
     </div>
    <div className={headerCn('toolbar')}>
      <Notice/>
      <Favorite/>
      <Profile/>
    </div>
    </header> 
  ); 
}; 
 
export default SearchHeader;