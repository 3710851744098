export const countries = [
  {
    id: 1,
    name: 'Азербайджан',
    code: 'AZ'
  },
  {
    id: 2,
    name: 'Армения',
    code: 'AM'
  },
  {
    id: 3,
    name: 'Белоруссия',
    code: 'BY'
  },
  {
    id: 4,
    name: 'Казахстан',
    code: 'KZ'
  },
  {
    id: 11,
    name: 'Китай',
    code: 'CH'
  },
  {
    id: 5,
    name: 'Кыргызстан',
    code: 'KG'
  },
  {
    id: 6,
    name: 'Молдавия',
    code: 'MD'
  },
  {
    id: 7,
    name: 'Россия',
    code: 'RU'
  },
  {
    id: 8,
    name: 'Таджикистан',
    code: 'TJ'
  },
  {
    id: 12,
    name: 'Тайвань',
    code: 'TW'
  },
  {
    id: 10,
    name: 'Турция',
    code: 'TR'
  },

  {
    id: 9,
    name: 'Туркменистан',
    code: 'TM'
  },
  {
    id: 8,
    name: 'Узбекистан',
    code: 'UZ'
  },
];
