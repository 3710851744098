import "./modal-component.scss";
import { FC, ReactNode, useEffect } from "react";
import { ReactComponent as Cross } from "../../shared/images/svg/cross.svg";
import { bemCN } from "../../configs/bem-classname";
import { Button } from "../../ui/button/button";

type ModalProps = {
  children: ReactNode;
  title: string;
  isShowDialog: boolean;
  setIsShowDialog(isShow: boolean): void;
};

export const Modal: FC<ModalProps> = ({
  children,
  title,
  setIsShowDialog,
  isShowDialog,
}) => {
  const modalCN = bemCN("modal");
  let dialog: HTMLDialogElement | any;

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setIsShowDialog(false);
      }
    });

    dialog = document.getElementById("modalDialog");
    isShowDialog ? dialog?.showModal() : dialog?.close();
  }, [isShowDialog]);

  return isShowDialog ? (
    <dialog id="modalDialog" className={modalCN()}>
      <div className={modalCN("header")}>
        <div className="h3">{title}</div>
        <Button
          image-white
          icon={<Cross />}
          onClick={() => {
            setIsShowDialog(false);
          }}
        />
      </div>
      <div className={modalCN("content")}>{children}</div>
    </dialog>
  ) : null;
};
