import './selected.scss';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as ArrowBottom } from '../../shared/images/svg/arrow-bottom-line.svg';
import { ReactComponent as ArrowTop } from '../../shared/images/svg/arrow-top-line.svg';
import { RadioGroup } from './radio-group/radio-group';
import { CheckGroup } from './check-group/check-group';
import { bemCN } from '../../configs/bem-classname';
import Popup from '../../ui/popup/popup';
import SearchInput from '../../ui/search-input/search-input';
type SelectedProps = {
  titleText?:string;
  isRadio: boolean;
  isCheck: boolean;
  items: any[];
  activeId?: number;
  activeIds?: number[];
  onChange(id: number): void;
  onChangeCheck?: (ids: number[]) => void;
};

export const Selected: FC<SelectedProps> = ({
  titleText,
  isCheck,
  isRadio,
  activeId,
  activeIds,
  items,
  onChange,
  onChangeCheck,
}) => {
  const selectedCN = bemCN('selected');
  const [isShow, setIsShow] = useState(false);
  const [active, setActive] = useState(activeId);
  const [actIds, setActIds] = useState(activeIds ? activeIds : []);
  const [filterItams, setFilterItems] = useState(items);
  const [filterValue, setFilterValue] = useState("");
  let title = titleText ? titleText : 'Выберите из списка';

  const onChangeRadio = (id: number) => {
    setActive(id);
    onChange(id);
    setIsShow(false);
  };

  const onChangeCheck1 = (ids: number[]) => {
    setActIds(ids);
    onChangeCheck && onChangeCheck(ids);
  };

  useEffect(() => {
    setFilterItems(items);
  }, [items]);

  const onSearchChange = (v: any) => {
    setFilterValue(v.target.value);
    let f = items.filter((item: any) => item.value.toLowerCase().includes(v.target.value.toLowerCase()));
    setFilterItems(f);
    
  };

  return (
    <div className={selectedCN()}>
      <div onClick={() => setIsShow(!isShow)} className={selectedCN('button')}>
        <div>
          {items.find((item: any) => item.id == active) == undefined
            ? title
            : items.find((item: any) => item.id == active).value}
        </div>
        <div>{isShow ? <ArrowTop /> : <ArrowBottom />}</div>
      </div>

      <Popup isShow={isShow} setIsShow={setIsShow}>
        <div className={selectedCN('content')}>
          <SearchInput
            onChange={(v: any) => onSearchChange(v)}
            className={selectedCN('search')}
            name="name"
            inputValue={filterValue}
          />
          {isRadio && (
            <RadioGroup
              items={filterItams}
              activeId={activeId ? activeId : -1}
              onChange={(id: number) => {
                onChangeRadio(id);
              }}
            />
          )}
          {isCheck && (
            <CheckGroup
              items={filterItams}
              activeIds={actIds}
              onChange={(ids: number[]) => onChangeCheck1 && onChangeCheck1(ids)}
            />
          )}
        </div>
      </Popup>
    </div>
  );
};
