import "./input-time.scss";
import { bemCN } from "../../configs/bem-classname";
import { InputMask } from "primereact/inputmask";
import { FC, memo } from "react";

export const availableInputTime = ["bottom-line", "border-all"] as const;

type InputTimeProp = {
  [icon in (typeof availableInputTime)[number]]?: boolean;
} & {
  date: string;
  onChange: (value: string) => void;
  header?: string;
  isActive?:boolean;
};

const InputTime = memo((props: InputTimeProp) => {
  const inputTimeCN = bemCN("input-time");
  let { date, onChange, header, isActive } = props;
  const adding_class = availableInputTime.find((i) => props[i]) ?? "";
  let className = inputTimeCN(adding_class);

  return (
    <div className={inputTimeCN()}>
       {header ? <p className="h4">{header}</p> : null}
      <InputMask
        readOnly={isActive}
        className={className}
        value={date}
        slotChar="01.01.2000"
        onChange={(v: any) => {
          v.value && onChange(v.value.toString());
        }}
        mask="99.99.9999"
        placeholder="01.01.2000"
      />
    </div>
  );
});

export default InputTime;
