import { useEffect, useState } from "react";
import "./stock-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as ArrowBack } from "../../../../shared/images/svg/arrow-back.svg";
import { ReactComponent as StoryIcon } from "../../../../shared/images/svg/story.svg";
import { ReactComponent as TrashBin } from "../../../../shared/images/svg/trash-bin.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import { API } from "../../../../consts/api";
import Selected from "../../../../components/selected-component/selected-component";
import { UpdateObject } from "../../../../shared/helpers/update-object";
import { SelectItem } from "../../../../core/view-models/select-item";
import { ConfirmationModal } from "../../../../components/modal-component/confirmation-modal/confirmation-modal";
import { LocalStorageListenner } from "../../../../shared/helpers/localstorage-listener";
import { useLocalStorage } from "../../../../shared/hooks/localStorage";
import { VariablesLocalStorage } from "../../../../consts/variables-localstorage";
import "./stock-card-component.scss"
const stockCN = bemCN("stock-card");

export const StockCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [Stock, SetStock] = useState(
    location.state && location.state.stock
      ? location.state.stock
      : {
          id: 0,
          name: "",
          nameShort: "",
          nameContractor: "",
          contractorId: -1,
          storeTypeId: -1,
          address: "",
          comment: "",
        }
  );
  const [IsEdit, SetIsEdit] = useState(false);
  const [IsNew, SetIsNew] = useState(
    location.state && location.state.stock ? false : true
  );
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [isUpdate, SetIsUpdate] = useLocalStorage(VariablesLocalStorage.IS_UPDATE_STOCK, false);
  const [isContractorsLoading, SetIsContractorsLoading] = useState(true);
  const [ContractorsList, SetContractorsList] = useState<any[]>([]);
  const [TypeStocksList, SetTypeStocksList] = useState<any[]>([]);

  
  const OnBack = () => {
    navigate("/directories/stocks/");
  };

  useEffect(() => {

     LocalStorageListenner(VariablesLocalStorage.IS_UPDATE_CONTRACTORS,SetIsContractorsLoading);
  }, []);

  useEffect(() => {
    API.storeTypeGetAllItemsList().then((res) => {
      SetTypeStocksList([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    if (isContractorsLoading) {
      API.contractorGetAllItemsList().then((res) => {
        SetContractorsList([...res.data.items]);
        SetIsContractorsLoading(false);
      });
    }
  }, [isContractorsLoading]);

  const onDelete = () => {
    API.storeDelete(Stock.id).then(() => {
      SetIsUpdate(true);
      navigate("/directories/stocks/");
    });
  };

  const onUpdate = () => {
    API.storeUpdateUpdate(Stock).then(() => {
      SetIsEdit(false);
      SetIsUpdate(true);
    });
  };
  const onAdd = () => {
    API.storeAddCreate(Stock).then(() => {
      SetIsEdit(false);
      SetIsNew(false);
      SetIsUpdate(true);
    });
  };
  return (
    <div className={stockCN()}>
      <div className={stockCN("header")}>
        <div>
          <button onClick={OnBack}>
            <ArrowBack />
          </button>
          <h2>{IsNew ? "Создание склада" : Stock.name}</h2>
        </div>
        <div>
          <StoryIcon />
          <span className="h2">Петров В.В.</span>
        </div>
      </div>
      <div className={stockCN("info")}>
        <div>
          <InputText
            header="Название:"
            text={Stock ? Stock.name : ""}
            isActive={IsNew === false ? !IsEdit : !IsNew}
            OnChange={(v) => {
              SetStock(UpdateObject(Stock, "name", v));
            }}
          />
        </div>
        <div>
          <InputText
            header="Короткое название:"
            text={Stock ? Stock.nameShort : ""}
            isActive={IsNew === false ? !IsEdit : !IsNew}
            OnChange={(v) => {
              SetStock(UpdateObject(Stock, "nameShort", v));
            }}
          />
        </div>
        <div>
          {Stock && !IsEdit && !IsNew ? (
            <InputText
            isRequired
              header="Владелец:"
              text={Stock ? Stock.nameContractor : ""}
              isActive
            />
          ) : (
            <div className={stockCN("popap")}>
              <p className="h4"><span className={stockCN("required")}>* </span>Владелец:</p>
              <div>
                <Selected
                  active={Stock ? Stock.contractorId : -1}
                  items={ContractorsList.map((item) => {
                    return new SelectItem(item.id, item.name);
                  })}
                  OnChange={(value) => {
                    const select = ContractorsList.find(
                      (item) => item.id === value
                    );
                    SetStock(UpdateObject(Stock, "contractorId", select.id));
                    SetStock(
                      UpdateObject(
                        Stock,
                        "nameContractor",
                        ContractorsList.find(({ id }) => id === select.id).name
                      )
                    );
                  }}
                  link="/directories/counterparties/card/new"
                  textLink="Добавить контрагента"
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {Stock && !IsEdit && !IsNew ? (
            <InputText
            isRequired
              header="Тип склада:"
              text={Stock ? Stock.storeType : ""}
              isActive
            />
          ) : (
            <div className={stockCN("popap")}>
              <p className="h4"><span className={stockCN("required")}>* </span>Тип склада:</p>
              <Selected
                active={Stock ? Stock.storeTypeId : -1}
                items={TypeStocksList.map((item) => {
                  return new SelectItem(item.id, item.name);
                })}
                OnChange={(value) => {
                  const select = TypeStocksList.find(
                    (item) => item.id === value
                  );
                  SetStock(UpdateObject(Stock, "storeTypeId", select.id));
                  SetStock(
                    UpdateObject(
                      Stock,
                      "storeType",
                      TypeStocksList.find(({ id }) => id === select.id).name
                    )
                  );
                }}
              />
            </div>
          )}
        </div>
        <div id="address">
          <InputText
            header="Адрес:"
            text={Stock ? Stock.address : ""}
            isActive={IsNew === false ? !IsEdit : !IsNew}
            OnChange={(v) => {
              SetStock(UpdateObject(Stock, "address", v));
            }}
          />
        </div>
        <div id="comment">
          <InputText
            header="Комментарий:"
            text={Stock ? Stock.comment : ""}
            isActive={IsNew === false ? !IsEdit : !IsNew}
            OnChange={(v) => {
              SetStock(UpdateObject(Stock, "comment", v));
            }}
          />
        </div>
      </div>
      <div className={stockCN("buttons")}>
        <Button text="Закрыть" white-outline onClick={OnBack} />
        {IsEdit || IsNew ? (
          <Button onClick={IsNew ? onAdd : onUpdate} text={"Сохранить"} blue />
        ) : (
          <Button onClick={() => SetIsEdit(true)} text={"Редактировать"} blue />
        )}
        {
          <Button
            image-red
            icon={<TrashBin />}
            onClick={() => {
              setIsShowModalDelete(true);
            }}
          />
        }
      </div>
      <ConfirmationModal
        onClickAgree={onDelete}
        isShowDialog={isShowModalDelete}
        setIsShowDialog={setIsShowModalDelete}
        text={'Вы уверены, что хотите удалить склад "' + Stock?.name + '"?'}
      />
    </div>
  );
};
