import { bemCN } from "../../configs/bem-classname";
import { Counterparties } from "../../modules/counterparties-module";

import "./counterparties-page.scss";

const counterpartiesCN = bemCN("counterpart");

export const CounterpartiesPage = () => {
  return (
    <div className={counterpartiesCN()}>
      <Counterparties />
    </div>
  );
};
    