import { bemCN } from "../../../../configs/bem-classname";
import "./button-new-group-component.scss";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { useState } from "react";
import { Button } from "../../../../ui/button/button";
const groupCN = bemCN("new_group");
type ButtonNewGroupProps = {
  onAddNew?: (name: string) => void;
};

export const ButtonNewGroupItem = (props: ButtonNewGroupProps) => {
  let { onAddNew } = props;
  const [IsNew, SetIsNew] = useState(false);
  const [Value, SetValue] = useState("");
  return (
    <div className={groupCN()}>
      <div className={groupCN("head", ["h4"])}>
        {IsNew ? (
          <div className={groupCN("head_new")}>
            <input className="h4"
              type="text"
              placeholder="Название папки"
              value={Value}
              onChange={(e) => SetValue(e.target.value)}
            />
            <div className={groupCN("buttons")}>
              <Button
                text="Добавить папку"
                onClick={() => {
                  onAddNew && onAddNew(Value);
                  SetIsNew(false);
                }}
              />
              <Button text="Отмена" onClick={() => SetIsNew(false)} />
            </div>
          </div>
        ) : (
          <div className={groupCN("head_grey")} onClick={() => SetIsNew(true)}>
            <Plus />
            Добавить папку
          </div>
        )}
      </div>
    </div>
  );
};
