import { bemCN } from "../../configs/bem-classname";
import { Acceptances } from "../../modules/acceptance-module";
import "./acceptance-page.scss"

const acceptanceCN = bemCN("acceptance");

export const AcceptancePage = () => {
  return (
    <div className={acceptanceCN()}>
     <Acceptances/>
    </div>
  );
};