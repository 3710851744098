import { bemCN } from "../../configs/bem-classname";
import { AssemblyCard } from "../../modules/assembly-module";
import "./assembly-card-page.scss"

const rootCN = bemCN("AssemblyCard");

export const AssemblyCardPage = () => {
  return (
    <div className={rootCN()}>
      <AssemblyCard/>
    </div>
  );
};