import { TableObjectI } from "../interfaces/table-object-i";
import { TableDataItem } from "../view-models/table-data-item";
import { TableDataRow } from "../view-models/table-data-row";

export class AssemblyGoodItem implements TableObjectI {
  number: number;
  name: string;
  status: number;
  time: string;
  count: number;

  constructor(
    number: number,
    name: string,
    status: number,
    time: string,
    count: number
  ) {
    this.number = number;
    this.name = name;
    this.status = status;
    this.time = time;
    this.count = count;
  }
  mapToTableDataRow(): TableDataRow {
    let tableDataItems: TableDataItem[] = [];
    tableDataItems.push(new TableDataItem("number", this.number));
    tableDataItems.push(new TableDataItem("name", this.name));
    tableDataItems.push(new TableDataItem("status", this.status, undefined, true));
    tableDataItems.push(new TableDataItem("time", this.time));
    tableDataItems.push(new TableDataItem("count", this.count));

    return new TableDataRow(this.number,tableDataItems);
  }
}
