import { Navigate, Outlet, useLocation } from "react-router-dom";
import { bemCN } from "../../configs/bem-classname";
import "./root-page.scss";
import SearchHeader from "../../components/header-component/header-component";
import SideBar from "../../components/sidebar-component/sidebar-component";
import TopMenu from "../../components/top-menu-component/top-menu-component";
import { useEffect, useState } from "react";
import { HomeCardPage } from "../home-card-page/home-card-page";
import { useAuth } from "../../shared/hooks/useAuth";

const rootCN = bemCN("root");
function RequireAuth({ children }: any) {
  //console.log(isAuth)
  return localStorage.getItem("auth") === 'true' ? children : <Navigate to="/home" replace />;
}

export const Root = () => {
  let location = useLocation();
  const [isShowMenu, setIsShowMenu] = useState(false);
  useEffect(() => {
    if (
      location.pathname == "/home" ||
      location.pathname == "/login" ||
      location.pathname == "/register"
    ) {
      setIsShowMenu(false);
    } else {
      setIsShowMenu(true);
    }
  }, [location]);
  return (
    <div className={rootCN()}>
      {isShowMenu == true ? (
        <RequireAuth>
          <SideBar />
          <div >
            <SearchHeader />
            <TopMenu />
            <Outlet />
          </div>
        </RequireAuth>
      ) : (
        <Outlet />
      )}
    </div>
  );
};
