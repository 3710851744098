import "./bar-chart-colors.scss";
import { bemCN } from "../../configs/bem-classname";
import { memo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BarChartDataItem } from "../../core/view-models/bar-chart-data-item";
import { StatisticsItemModel } from "../../core/api/data-contracts";

export const availableColor = ["blue", "green"] as const;

type CustomBarChartProps = {
  [icon in (typeof availableColor)[number]]?: boolean;
} & {
  id?: string;
  data?: StatisticsItemModel[];
  labelY?: string;
  labelX?: string;
  width?: number;
  height?: number;
};

const barColors = ["#1f77b4", "#ff7f0e", "#2ca02c"];
const colors = [
  ["rgba(65, 188, 168, 0.8)", "rgba(65, 188, 168, 0.25)"],
  ["rgba(239, 11, 11, 0.7)", "rgba(239, 11, 11, 0.15)"],
  ["rgba(81, 149, 191, 0.8)", "rgba(81, 149, 191, 0.25)"],
  ["rgba(255, 129, 59, 0.7)", "rgba(255, 129, 59, 0.15)"],
];

export const CustomBarChartColors = memo((props: CustomBarChartProps) => {
  let { labelY, data, width, height, labelX } = props;

  const colorType = availableColor.find((i) => props[i]) ?? "";
  let idGradient = colorType ? colorType : "blue";
  let w = width !== undefined ? width : 854;
  let h = height !== undefined ? height : 378;

  const customBarChartCN = bemCN("barChart");

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={customBarChartCN("custom-tooltip")}>
          <div className={customBarChartCN("label", ["h4"])}>{label}:</div>
          <div className={customBarChartCN("value", ["h4"])}>
            {payload[0].payload.value} руб.
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={customBarChartCN()}>
      <BarChart
        width={w}
        height={h}
        data={data}
        margin={{ top: 30, right: 0, left: 35, bottom: 0 }}
      >
        {colors.map((c, i) => {
          return (
            <defs key={i + "color"}>
              {" "}
              <linearGradient id={i.toString()} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={c[0]} />
                <stop offset="95%" stopColor={c[1]} />
              </linearGradient>
            </defs>
          );
        })}
        <XAxis
          style={{
            fontSize: "25px",
            fontFamily: "Manrope",
            fontStyle: "normal",
            fontWeight: "500",
            fill: "#222",
          }}
          axisLine={false}
          tickLine={false}
          dataKey="name"
        />
        <Bar dataKey="count" radius={[5, 5, 0, 0]}>
          <LabelList dataKey="count" position="top" fontSize={25}  />
          {data?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={`url(#${index})`} />
          ))}
        </Bar>
      </BarChart>
    </div>
  );
});

export default BarChart;
