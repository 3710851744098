import { Task } from "./task";

export class TaskGroup {
  id: number | undefined;
  name: string;
  constructor(id: number | undefined, name: string) {
    this.id = id;
    this.name = name;
  }
}
