import "./radio-calendar-component.scss";
import { bemCN } from "../../configs/bem-classname";
import { FC, useState } from "react";
import { ReactComponent as Arrow } from "../../shared/images/svg/arrow-bottom-triangle.svg";
import RadioCalendarPopup from "./radio-calendar-popup/radio-calendar-popup";
import { Button } from "../../ui/button/button";

type RadioCalendarProps = {
  id?: string;
  onCheck?(): void;
};

const RadioCalendar: FC<RadioCalendarProps> = ({ id, onCheck }) => {
  const radioCalendarCardCN = bemCN("radio-calendar");
  const items = ["Сегодня", "Вчера", "Неделя", "Месяц", "За все время"];

  const [currentDatePeriod, setCurrentDatePeriod] = useState(4);
  const [isShow, setIsShow] = useState(false);

  const onClick = () =>{
    setIsShow(!isShow);
  }

  return (
    <div className={radioCalendarCardCN("", ["h4"])}>
      <Button text-image-right icon={<Arrow />} onClick={onClick} text={items[currentDatePeriod]}/>

      <RadioCalendarPopup active={currentDatePeriod} isShow={isShow} setIsShow={setIsShow} items={items} setCurrentDatePeriod={setCurrentDatePeriod}/>
    </div>
  );
};

export default RadioCalendar;
