export class TableDataSearchItem{
    idColumn:number;//id столбца
    value:string;//свойство объекта, которое будет выводиться в строку, например Name
    data:any[];// список объектов, по которым осуществляется поиск

    constructor(idColumn:number, value:string, data:any[]) {
        this.idColumn = idColumn;
        this.value = value;
        this.data = data;
      }
}

