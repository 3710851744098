import { useMemo, useState } from "react";
import { bemCN } from "../../configs/bem-classname";
import "./input-search.scss";
import Popup from "../popup/popup";

import { ReactComponent as Cross } from "../../shared/images/svg/cross.svg";

const inputCN = bemCN("input-search");

type InputTextProps = {
  data?: any;
  selectList?: any[];
  isActive?: boolean;
  maxCount?: number;
  OnSelect?: (oblect: any) => void;
  OnDelete?: (index: number) => void;
};

export const InputSearch = (props: InputTextProps) => {
  let { selectList, data, maxCount, isActive, OnSelect, OnDelete } = props;
  const [valueInput, setValueInput] = useState("");
  const [isShow, setIsShow] = useState(false);
  const onClick = () => {
    setIsShow(true);
  };
  const onSelect = (object: any) => {
    if (maxCount && selectList && maxCount < selectList.length) return;
    setValueInput("");
    OnSelect && OnSelect(object);
  };
  const onDaelete = (object: number) => {
    OnDelete && OnDelete(object);
  };
  const filterData = useMemo(() => {
    return (
      data &&
      data.filter((d: any) =>
        d.toLowerCase().includes(valueInput.toLowerCase())
      )
    );
  }, [valueInput, data]);
  return (
    <div className={inputCN("div")}>
      <div className={inputCN("select", ["h4"])}>
        {selectList &&
          selectList.map((m, i) => (
            <div key={i + "select"}>
              {m}
              {!isActive&&<button onClick={()=>{onDaelete(i)}}><Cross/></button>}
            </div>
          ))}
      </div>
      <input
        onClick={onClick}
        className={inputCN("text", ["h4"])}
        value={valueInput}
        type="text"
        onChange={(e) => {
          setValueInput(e.target.value);
        }}
        readOnly={isActive}
      />
      {isShow && !isActive && (
        <Popup isShow={isShow} setIsShow={setIsShow}>
          {filterData &&
            filterData.length > 0 &&
            filterData.map((g: any, i: number) => {
              return (
                <div
                  key={i + "color"}
                  onClick={() => {
                    setIsShow(false);
                    onSelect(g);
                  }}
                  className="h4"
                >
                  {g}
                </div>
              );
            })}
        </Popup>
      )}
    </div>
  );
};
