import { ReactComponent as Cross } from "../../../shared/images/svg/cross.svg";
import { bemCN } from "../../../configs/bem-classname";
import { Button } from "../../../ui/button/button";
import "./confirmation-modal.scss";
import { FC, useEffect } from "react";
import { descriptors } from "chart.js/dist/core/core.defaults";

type ConfirmationModalProps = {
  isOK?: boolean;
  onClickAgree?(): void;
  isShowDialog?: boolean;
  setIsShowDialog?(isShow: boolean): void;
  text?: string;
  description?: string;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  onClickAgree,
  setIsShowDialog,
  isShowDialog,
  isOK,
  text,
  description,
}) => {
  const documentsModalCN = bemCN("confirmation-modal");
  let dialog: HTMLDialogElement | any;

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setIsShowDialog && setIsShowDialog(false);
      }
    });

    dialog = document.getElementById("modalDialog");
    isShowDialog ? dialog?.showModal() : dialog?.close();
  }, [isShowDialog]);

  return isShowDialog ? (
    <dialog id="modalDialog" className={documentsModalCN()}>
      <div className={documentsModalCN("header")}>
        {" "}
        <div className={documentsModalCN("title", ["h4"])}>{text}</div>
        <Button
          image-white
          icon={<Cross />}
          onClick={() => {
            setIsShowDialog && setIsShowDialog(false);
          }}
        />
      </div>
      {description && (
        <span className={documentsModalCN("desc", ["h5"])}>{description}</span>
      )}
      <div className={documentsModalCN("content")}>
        {isOK ? (
          <Button
            white
            text="Ок"
            onClick={() => {
              setIsShowDialog && setIsShowDialog(false);
              onClickAgree && onClickAgree();
            }}
          />
        ) : (
          <>
            <Button white text="Да" onClick={onClickAgree} />
            <Button
              white
              text="Нет"
              onClick={() => setIsShowDialog && setIsShowDialog(false)}
            />
          </>
        )}
      </div>
    </dialog>
  ) : null;
};
