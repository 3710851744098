import { API } from "../../../consts/api";
import { Subcategory } from "../entities/subcategory";
export const SearchSubCategoriesGood = (
  name: string,
  callback?: (res: any) => void
): void => {
  let res = null;
   res = API.goodGetWbCategoriesAndSubjectsByNameList({ name: name }).then((res) => {
    callback && callback((<any>res.data).data);
  });
};
