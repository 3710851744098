import LoginForm from "../../components/login-form/login-form";
import { bemCN } from "../../configs/bem-classname";
import "./login-page.scss"


const mainCN = bemCN("login");
const Login = () => (
  <div className={mainCN()}>
    {" "}
    <LoginForm />
  </div>
);

export default Login;
