export class Tariff {
  id: number;
  name: string;
  basePrice: number;
  durationMonths: number;
  salePercent: number;
  isAvailable: boolean;
  description: string[];

  constructor(
    id: number,
    name: string,
    basePrice: number,
    durationMonths: number,
    salePercent: number,
    isAvailable: boolean,
    description: string[],
  ) {
    this.id = id;
    this.name = name;
    this.basePrice = basePrice;
    this.durationMonths = durationMonths;
    this.salePercent = salePercent;
    this.isAvailable = isAvailable;
    this.description = description;
  }
}
