import { bemCN } from "../../../../configs/bem-classname";
import { FilterGoodTableModel } from "../../../../core/api/data-contracts";
import { InputText } from "../../../../ui/input-text/input-text";
import { SearchCheckFilter } from "../../../filter-module";
import "./goods-filter-component.scss";
import { FC, useEffect, useState } from "react";

type GoodsFilterProps = {
  id?: string;
  filters?: FilterGoodTableModel;
  onChange?: (model: FilterGoodTableModel) => void;
};

export const GoodsFilter: FC<GoodsFilterProps> = ({
  id,
  filters,
  onChange,
}) => {
  const goodsFilterCN = bemCN("goodsFilter");
  const [model, setModel] = useState<FilterGoodTableModel | undefined>(filters);
  useEffect(() => {
    model && onChange && onChange(model);
  }, [model]);
  return (
    <div id={id} className={goodsFilterCN()}>
      <div className={goodsFilterCN("cost")}>
        <p className="h4">Последняя цена закупки:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.pricePurchase?.min
                  ? model.pricePurchase.min.toString()
                  : "0"
              }
              OnChange={(v) => {
                filters!.pricePurchase!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.pricePurchase?.max
                  ? model.pricePurchase.max.toString()
                  : "0"
              }
              OnChange={(v) => {
                filters!.pricePurchase!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div className={goodsFilterCN("cost")}>
        <p className="h4">Последняя цена продажи:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.priceSelling?.min
                  ? model.priceSelling.min.toString()
                  : "0"
              }
              OnChange={(v) => {
                filters!.priceSelling!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.priceSelling?.max
                  ? model.priceSelling.max.toString()
                  : "0"
              }
              OnChange={(v) => {
                filters!.priceSelling!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div className={goodsFilterCN("cost")}>
        <p className="h4">Себестоимость:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.priceCost?.min
                  ? model.priceCost.min.toString()
                  : "0"
              }
              OnChange={(v) => {
                filters!.priceCost!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.priceCost?.max
                  ? model.priceCost.max.toString()
                  : "0"
              }
              OnChange={(v) => {
                filters!.priceCost!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div className={goodsFilterCN("cost")}>
        <p className="h4">Продажи:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={model && model.sales?.min ? model.sales.min.toString() : "0"}
              OnChange={(v) => {
                filters!.sales!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={model && model.sales?.max ? model.sales.max.toString() : "0"}
              OnChange={(v) => {
                filters!.sales!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div className={goodsFilterCN("cost")}>
        <p className="h4">Остаток на складах:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.balance?.min ? model.balance.min.toString() : "0"
              }
              OnChange={(v) => {
                filters!.balance!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.balance?.max ? model.balance.max.toString() : "0"
              }
              OnChange={(v) => {
                filters!.balance!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div className={goodsFilterCN("cost")}>
        <p className="h4">В пути:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.transit?.min ? model.transit.min.toString() : "0"
              }
              OnChange={(v) => {
                filters!.transit!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.transit?.max ? model.transit.max.toString() : "0"
              }
              OnChange={(v) => {
                filters!.transit!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
