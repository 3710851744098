import { memo, useEffect, useState } from "react";
import "./table-editable-item.scss";
import { bemCN } from "../../../configs/bem-classname";
import { Select } from "../../../core/entities/select";
import { Button } from "../../../ui/button/button";
import { ReactComponent as CheckMark } from "../../../shared/images/svg/check-mark-round.svg";
import { ReactComponent as Cross } from "../../../shared/images/svg/cross.svg";
import { GuidGenerator } from "../../../shared/helpers/guid-generator";

const tableItemCN = bemCN("table-item");

export const availableTableItems = ["select"] as const;

type TableEditableItemProps = {
  [typeItem in (typeof availableTableItems)[number]]?: boolean;
} & {
  id?: string;
  value?: string | number | null;
  selectList?: Select[];
  isActive?: boolean;
  isSetting?: boolean; //Надо ли вставлять окно удаления, добавления и т.д.
  isNew?: boolean;
  onDelete?: () => void;
  onClick?: () => void;
  onChange?: (value: string) => void;
  onUpdate?: () => void;
};

export const TableEditableItem = (props: TableEditableItemProps) => {
  let {
    id,
    value,
    selectList,
    isSetting,
    isActive,
    isNew,
    onClick,
    onChange,
    onDelete,
    onUpdate,
  } = props;
  const [select, setSelect] = useState(value);
  const [valueInput, setValueInput] = useState(value ? value.toString() : "");

  const [color, setColor] = useState(
    selectList &&
      selectList?.length > 0 &&
      selectList[select && typeof select == "number" ? select : 0].color
  );
  const adding_class = availableTableItems.find((i) => props[i]) ?? "";
  let type = adding_class;
  let className = tableItemCN(adding_class, ["h5"]);
  return type === "select" ? (
    <td onClick={onClick} id={id} className={className}>
      <select
        value={select == null ? "" : select}
        className={tableItemCN("input")}
        onChange={(e) => {
          setSelect(e.target.value);
          setColor(
            selectList &&
              selectList?.length > 0 &&
              selectList[Number(e.target.value)].color
          );
        }}
        style={color ? { color: color } : {}}
      >
        {selectList?.map((item) => (
          <option value={item.id} key={item.id} style={{ color: item.color }}>
            {item.name}
          </option>
        ))}
      </select>
    </td>
  ) : (
    <td className={className}>
      <input
        className={tableItemCN("input")}
        value={valueInput}
        type="text"
        onChange={(e) => {
          setValueInput(e.target.value);
          onChange && onChange(e.target.value);
        }}
        readOnly={!isActive}
      />
      {isSetting && (
        <div className={tableItemCN("adding")}>
          <div></div>
          {isNew ? (
            <Button icon={<CheckMark />} image-white onClick={onUpdate} />
          ) : (
            <Button icon={<Cross />} image-white onClick={onDelete} />
          )}
        </div>
      )}
    </td>
  );
};
