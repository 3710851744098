import "./image-card.scss";
import { bemCN } from "../../configs/bem-classname";
import { FC } from "react";
import { ReactComponent as TrashBin } from "../../shared/images/svg/trash-bin.svg";

type ImageCardProps = {
  id?: string;
  isVisible?: boolean;
  src?: string | undefined | null;
  onClick?: () => void;
};

const ImageCard: FC<ImageCardProps> = ({ id, src, onClick, isVisible }) => {
  const imageCardCN = bemCN("imageCard");
  return (
    <div id={id} onClick={onClick} className={imageCardCN()}>
      {src ? <img src={src} alt="Фото" /> : "Добавить фото"}
      {isVisible && (
        <div className={imageCardCN("del")}>
          <TrashBin />
        </div>
      )}
    </div>
  );
};

export default ImageCard;
