import { CheckGroup } from "../../../../components/check-group-component/check-group-component";
import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import Popup from "../../../../ui/popup/popup";
import "./categories-popup.scss";
import { FC, useState } from "react";


type CategoriesPopupProps = {
  id?: string;
  isShow: boolean;
  setIsShow(isShow: boolean): void;
  items: any[];
  type?:string;
  onChange?(itemIndex: Map<any, any>): void;
};

export const CategoriesPopup: FC<CategoriesPopupProps> = ({
  id,
  items,
  isShow,
  setIsShow,
  onChange
}) => {
  const categoriesPopupCN = bemCN("categoriesPopup");
  const [Items, SetItems] = useState(new Map());
  const onClick = ()=>{
    setIsShow(false);
    onChange&&onChange(Items);
  }
  const onCh = (itemIndex: Map<any, any>)=>{
    itemIndex&&SetItems(itemIndex);
  }
  return (
    <Popup key={id} isShow={isShow} setIsShow={setIsShow}>
      <div id={id} className={categoriesPopupCN()}>
        <CheckGroup items={items} onChange={onCh}/>
        <div className={categoriesPopupCN("bottom")}>
          <Button blue text="Применить" onClick={onClick}/>
        </div>
      </div>
    </Popup>
  );
};
