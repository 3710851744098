import { FC, useEffect, useState } from "react";
import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import "./button-select-category.scss";
import { GetCategoriesGood } from "../../api/get-categories";
import { Category } from "../../entities/category";
import Popup from "../../../../ui/popup/popup";
import { GetSubCategoriesGood } from "../../api/get-subcategory";
import { Subcategory } from "../../entities/subcategory";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search-small.svg";
import { SearchSubCategoriesGood } from "../../api/search-subcategory";

type ButtonSelectCategoryProps = {
  DefaultCategory?: Subcategory;
  OnSelectSubCategory?: (s: Subcategory) => void;
  IsEdit?: boolean;
};

export const ButtonSelectCategory: FC<ButtonSelectCategoryProps> = ({
  OnSelectSubCategory,
  DefaultCategory: defaultCategory,
  IsEdit,
}) => {
  const buttonSelectCN = bemCN("button-select");
  const [categories, setCategories] = useState<Category[]>();
  const [AllCategories, setAllCategories] = useState<Category[]>();
  const [subcategories, setSubcategories] = useState<Subcategory[]>();
  const [filterSubcategories, setFilterSubcategories] =
    useState<Subcategory[]>();
  const [isShow, setIsShow] = useState<boolean>(false);
  const [selectCategory, setSelectCategory] = useState<Category>();
  const [selectSubcategory, setSelectSubcategory] = useState<Subcategory>();
  const [finder, setFinder] = useState<string>("");
  useEffect(() => {
    setSelectSubcategory(defaultCategory);
  }, [defaultCategory]);
  useEffect(() => {
    GetCategoriesGood((res) => {
      setAllCategories(res);
      setCategories(res);
    });
  }, []);
  useEffect(() => {
    categories && setSelectCategory(categories[0]);
  }, [categories]);
  const filterCategory = (s_list: Subcategory[]) => {
    let c: Category[] | undefined;
    const con = s_list.map((s) => s.parentID);
    c = AllCategories?.filter((cat) => con.includes(cat.id));
    setFilterSubcategories(s_list);
    setCategories(c);
  };

  const filterSubCategory = (s_list: Subcategory[]) => {
    if (!filterSubcategories) {
      setSubcategories(s_list);
      return;
    }
    let c: Subcategory[] | undefined;
    const con = filterSubcategories.map((s) => s.subjectID);
    c = s_list?.filter((cat) => con.includes(cat.subjectID));
    setSubcategories(c);
  };
  useEffect(() => {
    if ((finder == "" && categories && categories?.length > 0) || !categories)
      return;
    finder == ""
      ? GetCategoriesGood(setCategories)
      : SearchSubCategoriesGood(finder, filterCategory);
  }, [finder]);
  useEffect(() => {
    categories &&
      selectCategory &&
      selectCategory.id &&
      GetSubCategoriesGood(selectCategory.id, filterSubCategory);
  }, [selectCategory]);
  return (
    <div className={buttonSelectCN()}>
      <p>Категория: </p>
      {selectSubcategory && <p>{selectSubcategory.subjectName}</p>}
      {IsEdit && (
        <Button
          text={selectSubcategory ? "Изменить" : "Выбрать"}
          blue
          onClick={() => setIsShow(true)}
        />
      )}
      {isShow && (
        <Popup isShow={isShow} setIsShow={setIsShow}>
          <div>
            <InputText
              placeholder="Поиск..."
              text={finder}
              OnChange={setFinder}
              icon={<Search />}
              icon-right
            />
          </div>
          <div className={buttonSelectCN("list")}>
            <div>
              {categories?.map((c, i) => {
                return (
                  <div
                    key={c.id+"cat"}
                    className={
                      selectCategory?.id == c.id
                        ? buttonSelectCN("category", ["select_c"])
                        : buttonSelectCN("category")
                    }
                    onClick={() => setSelectCategory(categories[i])}
                  >
                    {c.name}
                  </div>
                );
              })}
            </div>
            <div>
              {subcategories?.map((s, i) => {
                return (
                  <div
                    key={s.subjectID}
                    className={
                      selectSubcategory?.subjectID == s.subjectID
                        ? buttonSelectCN("subcategory", ["select_c"])
                        : buttonSelectCN("subcategory")
                    }
                    onClick={() => {
                      setSelectSubcategory(subcategories[i]);
                      OnSelectSubCategory &&
                        OnSelectSubCategory(subcategories[i]);
                      setIsShow(false);
                    }}
                  >
                    {s.subjectName}
                  </div>
                );
              })}
            </div>
          </div>
        </Popup>
      )}
    </div>
  );
};
