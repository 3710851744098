import "./radio-group-component.scss";
import { bemCN } from "../../configs/bem-classname";
import { ReactNode, memo, useState } from "react";
import { ReactComponent as CheckMark } from "../../shared/images/svg/check-mark.svg";
import { SelectItem } from "../../core/view-models/select-item";

export const availableRadioGroups = [
  'black-mark',
  'green-mark',
  'double-image'
] as const;


type RadioGroupProps = {
  [color in (typeof availableRadioGroups)[number]]?: boolean;
} & {
  id?: string;
  //items: string[];
  items:SelectItem[];
  onChange?(itemIndex:number):void;
  active?:number;
  icons?:ReactNode[];
};

export const RadioGroup = memo( (props:RadioGroupProps) => {

  let {items, onChange, active, icons} = props;
 
  const adding_class = availableRadioGroups.find((i) => props[i]) ?? '';
  const radioGroupCN = bemCN("radio-group__"+ adding_class);

  const [activeItem, setAcriveItem] = useState(active);

  const onClickItem = (index:number) => {
    setAcriveItem(index);
    onChange && onChange(index);
  };

  return (
    <div className={radioGroupCN()}>
      {items.map((item, index) => (
        <div key={item.id} onClick={e => onClickItem(item.id)} className={radioGroupCN("item")}>
          {adding_class==="double-image" && icons ? (icons[index] ? icons[index] :icons[0] ) : null}
          <span className="h4">{item.value}</span>
          {item.id===activeItem?  <CheckMark className={radioGroupCN("mark")} /> : null}
        </div>
      ))}
    </div>
  );
});

