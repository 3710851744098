import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import "./category-filter-button.scss";
import { ReactComponent as Folder } from "../../../../shared/images/svg/folder.svg";
import { ReactComponent as ArrowBottomTriangle } from "../../../../shared/images/svg/arrow-bottom-triangle.svg";
import { FC, memo, useState } from "react";
import { Category } from "../../../../core/entities/category";
import { CategoriesPopup } from "../categories-popup/categories-popup";

export const availableFilterButtons = ["image", "white"] as const;

type CategoriesFilterButtonProps = {
  [icon in (typeof availableFilterButtons)[number]]?: boolean;
} & {
  id?: string;
  category: any;
  onChange?(catigory: any, itemIndex: Map<any, any>): void;
};

export const CategoriesFilterButton = memo(
  (props: CategoriesFilterButtonProps) => {
    let { id, category, onChange } = props;

    const categoriesFilterButtonCN = bemCN("categoriesFilterButton");

    const adding_class = availableFilterButtons.find((i) => props[i]) ?? "";
    let type = adding_class;
    let className = categoriesFilterButtonCN(adding_class, [
      "h4",
      "categoriesFilterButton",
    ]);

    const [isShow, setIsShow] = useState(false);
    const onClick = () => {
      setIsShow(!isShow);
    };

    return (
      <div id={id} className={className}>
        {type === "white" ? (
          <Button
            text-image-right
            text={category.parentName}
            icon={<ArrowBottomTriangle />}
            onClick={onClick}
          />
        ) : (
          <Button
            onClick={onClick}
            text-double-image
            text={category.parentName}
            icon={<Folder />}
            icon2={<ArrowBottomTriangle />}
          />
        )}
        {category.subjects && (
          <CategoriesPopup
            id={category.parentName}
            items={category.subjects}
            isShow={isShow}
            onChange={(a) => onChange && onChange(category, a)}
            setIsShow={setIsShow}
          />
        )}
      </div>
    );
  }
);
