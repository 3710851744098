import { bemCN } from "../../configs/bem-classname";
import { Orders } from "../../modules/orders-module";
import "./orders-page.scss"
const rootCN = bemCN("order");

export const OrderPage = () => {
  return (
    <div className={rootCN()}>
      <Orders/>
    </div>
  );
};