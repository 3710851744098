import { bemCN } from "../../../../configs/bem-classname";
import { InputText } from "../../../../ui/input-text/input-text";
import {
  MarketplaceCheckFilter,
  SearchRadioFilter,
} from "../../../filter-module";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import { ReactComponent as ArrowBottom } from "../../../../shared/images/svg/arrow-bottom.svg";
import { ReactComponent as PeoplePlus } from "../../../../shared/images/svg/people-plus.svg";
import { ReactComponent as Profile } from "../../../../shared/images/svg/profile-grey.svg";



import "./assembly-filter-component.scss";
import { FC, useEffect, useState } from "react";
import { RadioGroup } from "../../../../components/radio-group-component/radio-group-component";
import { Button } from "../../../../ui/button/button";
import Popup from "../../../../ui/popup/popup";
import { statuses } from "../../../../consts/values/statuses";
import { API } from "../../../../consts/api";
import { SelectItem } from "../../../../core/view-models/select-item";

type AssemblyFilterProps = {
  id?: string;
};

export const AssemblyFilter: FC<AssemblyFilterProps> = ({ id }) => {
  const assemblyFilterCN = bemCN("assemblyFilter");

  const [isShowStatus, setIsShowStatus] = useState(false);
  const [counterparties, setCounterparties] = useState<any[]>([]);


  useEffect(() => {
    API.contractorGetAllItemsList().then((res) => {
      setCounterparties([...res.data.items]);
    });
  }, []);

  return (
    <div id={id} className={assemblyFilterCN()}>
      <div>
        <InputText icon-right icon={<Search />} placeholder="Поиск" />
      </div>
      <div className={assemblyFilterCN("cost")}>
        <p className="h4">Время:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText />
          </div>
        </div>
      </div>
      <div>
        <p className="h4">Маркетплейс:</p>
        <MarketplaceCheckFilter />
      </div>

      <div>
        <p className="h4">Статус:</p>
        <Button
          onClick={() => {
            setIsShowStatus(!isShowStatus);
          }}
          text-image-right
          text="Не выбрано"
          icon={<ArrowBottom />}
        />
        <Popup isShow={isShowStatus} setIsShow={setIsShowStatus}>
          <RadioGroup black-mark items={statuses} />
        </Popup>
      </div>

      <div>
        <p className="h4">Ответсвенный:</p>
        <SearchRadioFilter icons={[<PeoplePlus/>, <Profile/>, <Profile/>, <Profile/>]}  placeholder="Введите имя исполнителя" items={counterparties.map((item)=>{return new SelectItem(item.id, item.name)})} />
      </div>
    </div>
  );
};
