import "./selected-component.scss";
import { bemCN } from "../../configs/bem-classname";
import { FC, useState } from "react";
import { ReactComponent as Arrow } from "../../shared/images/svg/arrow-bottom-triangle.svg";
import { ReactComponent as Plus } from "../../shared/images/svg/plus.svg";
import { Button } from "../../ui/button/button";
import { RadioGroup } from "../radio-group-component/radio-group-component";
import Popup from "../../ui/popup/popup";
import { SelectItem } from "../../core/view-models/select-item";


type SelectedProps = {
  id?: string;
  active:number;
  items:SelectItem[];
  link?:string;
  textLink?:string;
  OnChange?:(index:number)=>void;
};

const Selected: FC<SelectedProps> = ({ id, active, link, textLink, items, OnChange}) => {
  const selectedCN = bemCN("selected");
  const [selectedItem, setSelectedItem]= useState(active);
  const [isShow, setIsShow] = useState(false);
 
  return (
    <div className={selectedCN()}>
        <Button id={id} text-image-right text={items.find((item)=>item.id===selectedItem)?.value?.toString()} icon={<Arrow/>} onClick={()=>{setIsShow(!isShow)}}/>
        <Popup isShow={isShow} setIsShow={setIsShow}>
            <RadioGroup onChange={(id)=>{ setSelectedItem(id); OnChange&&OnChange(id)}} items={items} active={selectedItem}/>
            {
              link&&
              <a
                  href={link}
                  className={selectedCN('link', ['h5'])}
                  target="_blank"
                >
                  <Plus />
                  {textLink}
                </a>
            }
        </Popup>

    </div>
  );
};

export default Selected;
