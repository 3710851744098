import React, { InputHTMLAttributes, ChangeEvent, FC, useState } from 'react';
import styles from './InputFieldPassword.scss';

import { ReactComponent as CloseEye } from '../../shared/images/svg/close-eye.svg';
import { ReactComponent as OpenEye } from '../../shared/images/svg/open-eye.svg';
import { validateField, ValidatorFunc } from '../../shared/helpers/validation';
import { bemCN } from '../../configs/bem-classname';



interface InputFieldPasswordProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  classNameInput?: string;
  validators?: ValidatorFunc[];
}
const formCn = bemCN('InputFieldPassword');
// TODO: Сделать рефакторинг
const InputFieldPassword: FC<InputFieldPasswordProps> = ({
  className,
  classNameInput,
  label,
  onInput,
  validators,
  ...rest
}) => {
  const [haveValue, setHaveValue] = useState<boolean>(false);
  const [isPassword, setIsPassword] = useState<boolean>(true);
  const [error, setError] = useState<string>();

  const validate = (v: any) => {
    if (validators?.length) {
      setError(validateField(v, validators));
    }
  }

  const inputUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof onInput === 'function') onInput(e);

    validate(e.target.value);

    if (!e.target.value) {
      setHaveValue(false);
      return;
    }

    setHaveValue(true);
  };

  return (
    <label
      className={formCn("",[ (haveValue?"":"InputFieldPassword_active"),className])
      }
    >
      <fieldset className={styles.InputFieldPassword__fieldset + (error ? ' invalid': '')}>
        <input
          autoComplete="off"
          className={formCn("input",[classNameInput])}
          type={isPassword ? 'password' : 'text'}
          onInput={inputUpdate}
          {...rest}
        />
        <legend className={styles.InputFieldPassword__legend}>{label}</legend>
        <span className={styles.InputFieldPassword__name}>{label}</span>
        <span
          onClick={() => setIsPassword((prev) => !prev)}
          className={styles.InputFieldPassword__icon}
        >
          {isPassword ? <CloseEye/> : <OpenEye/>}
        </span>
      </fieldset>
      {error && <span className={styles.InputFieldPassword__error_text}>{error}</span>}
    </label>
  );
};

export default InputFieldPassword;
