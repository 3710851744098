import { ReactNode, memo } from "react";
import { bemCN } from "../../configs/bem-classname";
import "./button.scss";

const buttonCN = bemCN("button");

export const availableButtons = [
  "image-red",
  "image-white",
  "text-image-left",
  "text-image-right",
  "text-double-image",
  "blue",
  "white",
  "white-outline",
  "light-violet",
  "circle",
] as const;

type ButtonProps = {
  [icon in (typeof availableButtons)[number]]?: boolean;
} & {
  id?: string;
  text?: string | undefined;
  link?: string;
  isTargetBlank?: boolean;
  icon?: ReactNode | undefined;
  icon2?: ReactNode | undefined;
  disabled?: boolean;
  onClick?: () => void;
};

export const Button = (props: ButtonProps) => {
  let { id, text, link, isTargetBlank, icon, icon2, disabled, onClick } = props;

  disabled === undefined ? (disabled = false) : (disabled = true);
  const adding_class = availableButtons.find((i) => props[i]) ?? "";
  let type = adding_class;
  let className = buttonCN(adding_class, ["h4", "button"]);
  switch (type) {
    case "text-image-left": {
      {
        return link ? (
          <a
            type="button"
            href={link}
            onClick={onClick}
            id={id}
            className={className}
            target={isTargetBlank ? "_blank" : undefined}
          >
            {" "}
            {icon} {text}
          </a>
        ) : (
          <button type="button" onClick={onClick} id={id} className={className}>
            {" "}
            {icon} {text}
          </button>
        );
      }
    }
    case "text-image-right": {
      return link ? (
        <a
          type="button"
          href={link}
          onClick={onClick}
          id={id}
          className={className}
          target={isTargetBlank ? "_blank" : undefined}
        >
          {text} {icon}{" "}
        </a>
      ) : (
        <button type="button" onClick={onClick} id={id} className={className}>
          {text} {icon}{" "}
        </button>
      );
    }
    case "text-double-image": {
      return link ? (
        <a
          type="button"
          href={link}
          onClick={onClick}
          id={id}
          className={className}
          target={isTargetBlank ? "_blank" : undefined}
        >
          {icon} {text} {icon2}{" "}
        </a>
      ) : (
        <button type="button" onClick={onClick} id={id} className={className}>
          {icon} {text} {icon2}{" "}
        </button>
      );
    }
  }
  return link ? (
    <a
      type="button"
      href={link}
      onClick={onClick}
      id={id}
      className={className}
      target={isTargetBlank ? "_blank" : undefined}
    >
      {" "}
      {text} {icon}
    </a>
  ) : (
    <button type="button" disabled={disabled} onClick={onClick} id={id} className={className}>
      {" "}
      {text} {icon}
    </button>
  );
  //return type==="text-image-left" ?<button type='button' onClick={onClick} id={id} className={className}> {icon} {text} {icon2}</button>: <button type='button' onClick={onClick} id={id} className={className}>{text}</button>;
};
