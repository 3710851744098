import { bemCN } from "../../configs/bem-classname";
import { Assemblies } from "../../modules/assembly-module";
import "./assembly-page.scss"

const rootCN = bemCN("assembly");

export const AssemblyPage = () => {
  return (
    <div className={rootCN()}>
      <Assemblies/>
    </div>
  );
};