import "./input.scss";
import { FC, useEffect, useId, useState } from "react";

import { InputMask } from "primereact/inputmask";
import { validateField, ValidatorFunc } from "../../shared/helpers/validation";
import { bemCN } from "../../configs/bem-classname";

type InputProps = {
  value?: string;
  label: string;
  validators?: ValidatorFunc[];
  isDate?: boolean;
  isPassword?: boolean;
  onChange(value: string): void;
};

export const Input: FC<InputProps> = ({
  value,
  label,
  validators,
  isDate,
  isPassword,
  onChange,
}) => {
  const inputCN = bemCN("input");
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState<string>("");
  const idInput = useId();

  const [valueString, setValueString] = useState(value);

  const validateData = () => {
    if (validators) {
      let error = validateField(valueString, validators);
      if (error) {
        setErrorText(error);
        setIsError(true);
      } else {
        setErrorText("");
        setIsError(false);
      }
    }
  };

  const onBlur = () => {
    validateData();
  };

  const onChangeValue = (v: any) => {
    setValueString(v.target.value);
    validateData();
    onChange(v.target.value);
  };

  return (
    <div className={inputCN()}>
      <fieldset>
        {!isDate && !isPassword && (
          <input
            autoComplete="off"
            onChange={(v: any) => onChangeValue(v)}
            className={isError ? inputCN({ isError: true }) : ""}
            onBlur={() => onBlur()}
            id={idInput}
            value={valueString}
          ></input>
        )}
        {isDate && (
          <InputMask
            className={isError ? inputCN({ isError: true }) : ""}
            onChange={(v: any) => onChangeValue(v)}
            onBlur={() => onBlur()}
            value={valueString}
            id={idInput}
            mask="99.99.9999"
            autoComplete="off"
          />
        )}
        {isPassword && (
          <input
            type="password"
            onChange={(v: any) => onChangeValue(v)}
            className={isError ? inputCN({ isError: true }) : ""}
            onBlur={() => onBlur()}
            id={idInput}
            value={valueString}
            autoComplete="off"
          ></input>
        )}

        <legend>
          <label htmlFor={idInput}>{label}</label>
        </legend>
      </fieldset>

      {isError && <p>{errorText}</p>}
    </div>
  );
};
