import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import "./employees-compont.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { FC, useEffect, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import Table from "../../../../components/table-component/table-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { useNavigate } from "react-router-dom";
import { API } from "../../../../consts/api";
import Loading from "../../../../components/loading-component/loading-component";

type EmployeersProps = {
  id?: string;
};

export const Employeers: FC<EmployeersProps> = ({ id }) => {
  const employeersCN = bemCN("employeers");
  const navigate = useNavigate();
  const onClickRow = (id: number) => {
    navigate("card/" + id, {
      replace: true,
      state: { employeer: EmployeersList.find((e) => e.id === id) },
    });
  };
  const onClickAdd = () => {
    navigate("card/new");
  };
  const [EmployeersList, SetEmployeersList] = useState<any[]>([]);
  const [Headers, SetHeaders] = useState<any>();
  const [IsLoading, SetIsLoading] = useState(false);
  const [Error, SetError] = useState<Error>();

  useEffect(() => {
    API.workerGetAllItemsList().then((res) => {
      SetHeaders(res.data.nameFields);
      SetEmployeersList([...res.data.items]);
      SetIsLoading(true);
    }).catch((e) => {
      SetError(e);
      SetIsLoading(false);
    });
  }, []);
  if (Error){
    throw Error;
  }
  if (!IsLoading) {
    return <Loading color="dark" />;
  }
  return (
    <div id={id} className={employeersCN()}>
      <div className={employeersCN("topPanel")}>
        {/* <RadioCalendar /> */}
        <Calendar />
      </div>
      <div className={employeersCN("title", ["h1"])}>Сотрудники</div>
      <div className={employeersCN("buttons")}>
        <Button
          text-image-left
          text="Добавить сотрудника"
          icon={<PlusCircle />}
          onClick={onClickAdd}
        />
        {/* <ExportButton></ExportButton> */}
        <InputText
          placeholder="Поиск по сотрудникам"
          icon={<Search />}
          icon-left
        />
      </div>
      <Table
        dataGenerate={EmployeersList}
        headersGenerate={Headers}
        onClickRow={onClickRow}
      />
    </div>
  );
};
