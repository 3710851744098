import { bemCN } from "../../../configs/bem-classname";
import "./advanteres-features-component.scss";
import { FC } from "react";
import { ReactComponent as BackChart1 } from "../../../shared/images/svg/main-bar-chart-1.svg";
import { ReactComponent as BackChart2 } from "../../../shared/images/svg/main-bar-chart-2.svg";

interface MainAdvantagesFeaturesProps {}

const mainAdvantagesPageCn = bemCN("mainAdvantagesFeatures");
const MainAdvantagesFeatures: FC<MainAdvantagesFeaturesProps> = () => (
  <div className={mainAdvantagesPageCn()}>
    <h2 className={mainAdvantagesPageCn("title")}>
      <span className={mainAdvantagesPageCn("title_green")}>ПРЕИМУЩЕСТВА</span>{" "}
      СЕРВИСА
    </h2>
    <ul className={mainAdvantagesPageCn("list")}>
      <li className={mainAdvantagesPageCn("item_gradient")}>
        <div className={mainAdvantagesPageCn("title-card")}>
          Интуитивно понятный интерфейс
        </div>
        <div className={mainAdvantagesPageCn("description")}>
          Легкость в использовании, которая позволяет вам сосредоточиться на
          бизнесе, а не на обучении.
        </div>
        <div className={mainAdvantagesPageCn("number")}>1</div>
      </li>
      <li className={mainAdvantagesPageCn("item_gradient")}>
        <div className={mainAdvantagesPageCn("title-card")}>
          Персонализированная поддержка
        </div>
        <div className={mainAdvantagesPageCn("description")}>
          Наша команда готова помочь вам с любыми вопросами, чтобы вы могли
          сосредоточиться на росте бизнеса.
        </div>
        <div className={mainAdvantagesPageCn("number")}>2</div>
      </li>
      <li className={mainAdvantagesPageCn("item")}>
      <BackChart2 />
      </li>
      <li className={mainAdvantagesPageCn("item")}>
        <BackChart1 />
      </li>
      <li className={mainAdvantagesPageCn("item_gradient")}>
        <div className={mainAdvantagesPageCn("title-card")}>
          Аналитика в реальном времени
        </div>
        <div className={mainAdvantagesPageCn("description")}>
          Мгновенный доступ к актуальным данным, что позволяет принимать быстрые
          и обоснованные решения.
        </div>
        <div className={mainAdvantagesPageCn("number")}>3</div>
      </li>
      <li className={mainAdvantagesPageCn("item_gradient")}>
        <div className={mainAdvantagesPageCn("title-card")}>
          Гибкость и адаптивность
        </div>
        <div className={mainAdvantagesPageCn("description")}>
          Настройка системы под ваши уникальные бизнес-процессы для достижения
          максимальной эффективности.
        </div>
        <div className={mainAdvantagesPageCn("number")}>4</div>
      </li>
    </ul>
  </div>
);

export default MainAdvantagesFeatures;
