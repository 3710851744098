import { FC, useState } from "react";
import "./quick-reg-component.scss";
import { useNavigate } from "react-router-dom";
import { bemCN } from "../../../configs/bem-classname";
import { links } from "../../../consts/links";
import { ReactComponent as LeftChart } from "../../../shared/images/svg/left-char-main.svg";
import { ReactComponent as RighrChart } from "../../../shared/images/svg/right-chart-main.svg";

interface QuickRegProps {
  title?: string;
  greenWord?: string;
}

const quickRegCn = bemCN("quickReg");
const QuickReg: FC<QuickRegProps> = ({ title, greenWord }) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  return (
    <div className={quickRegCn()}>
      <h2 className={quickRegCn("title")}>
        {greenWord && title?.split(greenWord)[0]}
        <span> {greenWord}</span> {greenWord && title?.split(greenWord)[1]}
      </h2>
      <div className={quickRegCn("content")}>
        <div><LeftChart /></div>
        <div>
          <div>
            <input
              onChange={(v: any) => setName(v.target.value)}
              value={name}
              type="name"
              placeholder="Имя"
            ></input>
            <input
              onChange={(v: any) => setEmail(v.target.value)}
              value={email}
              type="text"
              placeholder="Email"
            ></input>
          </div>
          <div
            onClick={() =>
              navigate(links.register, {
                state: { email: email, name: name },
              })
            }
          >
            Получить доступ
          </div>
        </div>
        <div><RighrChart /></div>
      </div>
    </div>
  );
};

export default QuickReg;
