export class TableDataItem{
    property:string;
    value?:string|number|null;
    link?:string;
    isSelect?:boolean;


    constructor(property: string, value?:string|number|null, link?:string, isSelect?:boolean) {
        this.property = property;
        this.value = value;
        this.link = link;
        this.isSelect = isSelect;
      }
}

