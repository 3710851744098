import { bemCN } from "../../configs/bem-classname";
import { AcceptanceCard } from "../../modules/acceptance-module/components/acceptance-card-component/acceptance-card-component";

import "./acceptance-card-page.scss"

const acceptanceCardPageCN = bemCN("acceptanceCardPage");

export const AcceptanceCardPage = () => {
  return (
    <div className={acceptanceCardPageCN()}>
      <AcceptanceCard/>
    </div>
  );
};