import RegisterForm from "../../components/register-form/register-form";
import { bemCN } from "../../configs/bem-classname";
import "./register-page.scss"


const mainCN = bemCN("login");

const Register = () => (
  <div className={mainCN()}>
    {" "}
    <RegisterForm />
  </div>
);

export default Register;
