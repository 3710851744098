import { bemCN } from "../../configs/bem-classname";
import { Stocks } from "../../modules/stock-module";


import "./stocks-page.scss";

const stocksCN = bemCN("stock");

export const StocksPage = () => {
  return (
    <div className={stocksCN()}>
      <Stocks/>
    </div>
  );
};
    