export const ContainsExcludeCellRow = (
  data: any[],
  item: any,
  exclude: any[]
): any => {
  let res = false;
  exclude.map((e) => {
    if (data[e] === item) {
      res = true;
      return;
    }
  });
  return res;
};
