import { bemCN } from "../../../configs/bem-classname";
import { Button } from "../../../ui/button/button";
import { InputText } from "../../../ui/input-text/input-text";
import "./check-data-modal-component copy.scss";
import { FC, useEffect, useState } from "react";
import { ReactComponent as Arrow } from "../../../shared/images/svg/arrow-bottom-triangle.svg";
import Popup from "../../../ui/popup/popup";
import { RadioGroup } from "../../radio-group-component/radio-group-component";
import { API } from "../../../consts/api";
import { SelectItem } from "../../../core/view-models/select-item";

type CheckDataModalProps = {
  setIsShow(isShow: boolean): void;
};

export const ChechDataModal: FC<CheckDataModalProps> = ({setIsShow}) => {
  const checkDataModalCN = bemCN("check-data-modal");

  useEffect(() => {
    API.workerGetAllItemsList().then((res) => {
      setEmployeers([...res.data.items]);
    });
  }, []);

  const [isShowPersonal, setIsShowPersonal] = useState(false);
  const [employeers, setEmployeers] = useState<any[]>([]);

  return (
    <div className={checkDataModalCN()}>
      <div className={checkDataModalCN("title", ["h4"])}>Поставщик:</div>
      <InputText />

      <div className={checkDataModalCN("title", ["h4"])}>Сумма:</div>
      <InputText />

      <div className={checkDataModalCN("title", ["h4"])}>Товар:</div>
      <InputText />

      <div className={checkDataModalCN("title", ["h4"])}>Ответственный:</div>
      <div>
        <Button
          text-image-right
          text="Имя"
          icon={<Arrow />}
          onClick={() => {
            setIsShowPersonal(!isShowPersonal);
          }}
        />
        <Popup isShow={isShowPersonal} setIsShow={setIsShowPersonal}>
          <RadioGroup items={employeers.map((empl) => {return new SelectItem(empl.id, empl.surname)})} />
        </Popup>
      </div>
      <div className={checkDataModalCN("buttons")}>
        <Button white-outline text="Отменить" onClick={() => setIsShow(false)}/>
        <Button blue text="Отправить" />
      </div>
    </div>
  );
};
