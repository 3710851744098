import { bemCN } from "../../configs/bem-classname";
import { GoodsCard } from "../../modules/goods-module";
import "./goods-card-page.scss";

const goodsCardPageCN = bemCN("goods-card-page");

export const GoodsCardPage = () => {
  return <div className={goodsCardPageCN()}>
    <GoodsCard />
  </div>;
};
