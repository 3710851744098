import { FC, memo, useEffect, useState } from "react";
import "./supply-component.scss";
import {
  Box,
  OrderGoodModel,
  Supply,
} from "../../../../core/api/data-contracts";
import { bemCN } from "../../../../configs/bem-classname";
import { Button, availableButtons } from "../../../../ui/button/button";

import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { ReactComponent as Barcode } from "../../../../shared/images/svg/barcode.svg";
import { ReactComponent as Delete } from "../../../../shared/images/svg/delete.svg";
import { ReactComponent as Arrow } from "../../../../shared/images/svg/arrow-bottom-triangle.svg";
import { ReactComponent as QR } from "../../../../shared/images/svg/qr-code.svg";
import { ReactComponent as List } from "../../../../shared/images/svg/list.svg";
import Table from "../../../../components/table-component/table-component";
import saveAs from "file-saver";
import { TableDataSearchItem } from "../../../../core/view-models/table-search-item";
import { InputText } from "../../../../ui/input-text/input-text";
import { Modal } from "../../../../components/modal-component/modal-component";
import { AvailableGoodsComponent } from "../available-goods-component/available-goods-component";
import { CreateAssemblySheet } from "../../helpers/create-assembly-sheet";
import { Goods } from "../../../goods-module";
import { API } from "../../../../consts/api";

const supplyCN = bemCN("orders-card");

type SupplyProps = {
  Headers?: string[];
  Supplies?: Supply[] | null;
  IsEdit?: boolean;
  AllGoods?: OrderGoodModel[] | null;
  AddSupply?: (name: string) => void;
  AddBox?: (idSupply: string) => void;
  DelBox?: (idSuply: string, idsBoxes: string[]) => void;
  AddGoodToBox?: (idBox: string, orders: OrderGoodModel[]) => void;
  DelSupply?: (idSuply?: string | null) => void;
  OnDelGoodBox?: (idSupply: string, idBox: string, index: number) => void;
  OnCloseSupply?: (idSuply: string) => void;
  OnUpdateSupply?: (supply: Supply) => void;
};
export const SupplyComponent: FC<SupplyProps> = memo(
  ({
    Supplies,
    IsEdit,
    Headers,
    AllGoods,
    AddSupply,
    AddBox,
    DelBox,
    DelSupply,
    AddGoodToBox,
    OnCloseSupply,
    OnDelGoodBox,
    OnUpdateSupply,
  }) => {
    const [addingSuplies, setAddingSuplies] = useState<string>("");
    const [isShowDialog, setIsShowDialog] = useState<boolean>(false);
    const [currentBox, setCurrentBox] = useState<Box>();

    return (
      <div className={supplyCN()}>
        {IsEdit && AllGoods!.length > 0 ? (
          <div className="supply add">
            <InputText
              text={addingSuplies}
              OnChange={(value) => {
                setAddingSuplies(value);
              }}
            />
            <Plus />
            <Button
              white
              text="Добавить поставку"
              onClick={() => {
                AddSupply && AddSupply(addingSuplies);
                setAddingSuplies("");
              }}
            />
          </div>
        ) : null}
        {Supplies?.map((s, i) => (
          <div key={i + "supply"}>
            <div className="supply">
              <h3>{s.name}</h3>
              {!s.done && (
                <Button
                  icon={<Delete />}
                  onClick={() => {
                    DelSupply && DelSupply(s.id);
                  }}
                  white
                />
              )}
            </div>
            <div className={supplyCN("assembly")}>
              <div>
                <Button
                  text-image-right
                  icon={<Arrow />}
                  text={"Ответственный"}
                />
              </div>
              <div>
                <Button
                  text-image-right
                  icon={<QR />}
                  text={"QR-код поставки"}
                  onClick={() =>
                    saveAs(
                      `data:image/svg+xml;base64,${s.qrCode}`,
                      `${s.id}.svg`
                    )
                  }
                />
              </div>
              <div>
                <Button
                  text-image-right
                  icon={<List />}
                  text={"Сборочный лист"}
                  onClick={() => {
                    CreateAssemblySheet(s);
                  }}
                />
              </div>
            </div>
            {s.boxes?.map((b) => (
              <div key={b.id + " " + s.id}>
                <h5>Короб {b.id}</h5>
                <div className={supplyCN("box")}>
                  <div>
                    <Table
                      id={b.id ? b.id : ""}
                      headersGenerate={Headers}
                      dataGenerate={b.goods}
                      excludeIndexes={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
                      isEditable={!s.done}
                      stickerName="Стикер WB"
                      stickerName2="Баркод товара"
                      stickerButtonName="Печать"
                      stickerButtonName2="Печать"
                      onDelRow={(i) =>
                        OnDelGoodBox &&
                        b?.id &&
                        s?.id &&
                        OnDelGoodBox(s.id, b.id, i)
                      }
                      onClickSticker={(i) =>
                        b?.goods &&
                        saveAs(
                          `data:image/svg+xml;base64,${b?.goods[i].sticker}`,
                          `${b?.goods[i].name}.svg`
                        )
                      }
                      onClickSticker2={(i) => {
                        if(!b?.goods) return;
                        let id = b.goods[i].goodId;
                        b?.goods && API.goodDetail(Number(id))
                          .then((res) => {
                              let g = res.data.find((r) => r.localeID == Number(id))
                              g?.sizes?.map((s) => {
                                  s.skusImg?.map(
                                    (i: any, index: string | number) =>
                                      s.skus &&
                                      s.skus[0] &&
                                      saveAs(`data:image//png;base64,${i}`, `${s.skus[0]}.png`)
                                  );
                                });
                          })
                          .catch((e) => {
                          });
                      }}
                    />
                    <div>
                      <Plus />
                      <Button
                        white
                        text="Добавить сборочное задание"
                        onClick={() => {
                          setIsShowDialog(true);
                          setCurrentBox(b);
                        }}
                      />
                    </div>
                  </div>

                  <div className={supplyCN("box_setting")}>
                    <Button
                      text={"Стикер короба"}
                      icon={<Barcode />}
                      white-outline
                      onClick={() =>
                        saveAs(
                          `data:image/svg+xml;base64,${b.sticker}`,
                          `${b.id}.svg`
                        )
                      }
                    />
                    {!s.done && (
                      <Button
                        text={"Удалить короб"}
                        blue
                        onClick={() =>
                          s?.id && DelBox && b?.id && DelBox(s?.id, [b.id])
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
            {IsEdit && !s.done && (
              <>
                <div>
                  <Plus />
                  <Button
                    white
                    text="Добавить короб"
                    onClick={() => {
                      s?.id && AddBox && AddBox(s.id);
                    }}
                  />
                </div>
                <div>
                  <Button
                    blue
                    text="Закрыть поставку"
                    onClick={() => {
                      s?.id && OnCloseSupply && OnCloseSupply(s.id);
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ))}
        <Modal
          title="Выбрать сборочные задания"
          isShowDialog={isShowDialog}
          setIsShowDialog={setIsShowDialog}
        >
          <AvailableGoodsComponent
            AllGoods={AllGoods}
            OnAdd={(goods: OrderGoodModel[]) => {
              setIsShowDialog(false);
              AddGoodToBox &&
                currentBox?.id &&
                AddGoodToBox(currentBox?.id, goods);
            }}
          />
        </Modal>
      </div>
    );
  }
);
