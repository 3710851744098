import { useEffect, useState } from "react";
import { bemCN } from "../../../../configs/bem-classname";
import "./task-item-component.scss";
import { CheckItem } from "../../../../ui/check-item/check-item";
import { ReactComponent as AddArtist } from "../../../../shared/images/svg/add-artist.svg";
import { ReactComponent as Additionally } from "../../../../shared/images/svg/additionally.svg";
import { ReactComponent as Calendar } from "../../../../shared/images/svg/calendar.svg";
import { ReactComponent as Status } from "../../../../shared/images/svg/status.svg";
import { TaskItemMenu } from "../task-item-menu/task-item-menu";
import { calendarDateMonths } from "../../../../shared/helpers/date";
import { TasksModalItem } from "../task-modal-item/task-modal-item";
import { Task } from "../../../../core/api/data-contracts";
import { TaskGroup } from "../../../../core/entities/task-group";

const taskCN = bemCN("task");
type TaskItemProps = {
  Task: Task;
  Group: TaskGroup;
  OnClick?: () => void;
  OnUpdate?: (task: Task) => void;
  OnDuplicate?: (task: Task) => void;
  OnDelete?: (id: number) => void;
};
export const TaskItem = (props: TaskItemProps) => {
  let { Task, Group, OnClick, OnUpdate, OnDuplicate, OnDelete } = props;
  const [task, setTask] = useState<Task>(Task);
  const [isActive, setIsActive] = useState<boolean>(
    Task.active ? Task.active : false
  );
  const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
  const [isShowWinTask, setIsShowWinTask] = useState<boolean>(false);
  const setActive = (act: boolean) => {
    let t = task;
    t.active = act;
    setTask(t);
    setIsActive(act);
    OnUpdate && OnUpdate(task);
  };

  return (
    <div className={taskCN()}>
      <div className={taskCN("click")} onClick={() => setIsShowWinTask(true)} />
      <div className={taskCN("color")} style={{ color: "red" }}></div>
      <div className={taskCN("main")}>
        <div className={taskCN("head")}>
          <div className={taskCN("mark")}>
            <CheckItem
              Active={task && task.active != null && task.active}
              round
              onActive={setActive}
            />
          </div>
          <div className={taskCN("text", { disactive: !isActive }, ["h4"])}>
            {task.name}
          </div>
          <div className={taskCN("functions", [isShowMenu ? "visible" : ""])}>
            <div onClick={() => setIsShowMenu(true)}>
              <Additionally />
              <TaskItemMenu
                OnDuplicate={() => {
                  OnDuplicate && OnDuplicate(task);
                }}
                OnDelete={() => OnDelete && OnDelete(task.id ? task.id : -1)}
                IsShowMenu={isShowMenu}
                SetIsShowMenu={setIsShowMenu}
              />
            </div>
            <AddArtist />
          </div>
        </div>
        {task.text && (
          <div className={taskCN("description", ["h5"])}>{task.text}</div>
        )}
        {task.end && (
          <div className={taskCN("info", ["h5"])}>
            {task.end && (
              <div className={taskCN("info_calendar")}>
                <Calendar />
                {calendarDateMonths(
                  new Date(Date.parse(task.end ? task.end : ""))
                )}
              </div>
            )}
            {task.taskStatusId && (
              <div className={taskCN("info_item")}>
                <Status />
                {task.taskStatusId}
              </div>
            )}
            {/* {task.subtasks && (
              <div className={taskCN("info_item")}>
                <Subtasks />
                1/3
              </div>
            )} */}
          </div>
        )}
      </div>
      <TasksModalItem
        group={Group}
        task={task}
        isShowDialog={isShowWinTask}
        setIsShowDialog={setIsShowWinTask}
      />
    </div>
  );
};
