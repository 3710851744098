import { Form, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { bemCN } from '../../configs/bem-classname';
import './login-form.scss';
import React, { useCallback, useEffect, useState } from 'react';
import { links } from '../../consts/links';
import { useAuth } from '../../shared/hooks/useAuth';
import { Input } from '../../ui/input/input';
import InputFieldPhone from '../../ui/input-field-phone/InputFieldPhone';
import InputField from '../../ui/input-field/input-field';
import InputFieldPassword from '../../ui/InputFieldPassword/InputFieldPassword';

const loginFormCn = bemCN('login-form');

const LoginForm = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const [values, setValues] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  });

  useEffect(() => {
    const uid = searchParams.get('key');
    const email = searchParams.get('email');
    if (uid&&email) {
      navigate(`/register?key=${uid}&email=${email}`);
    }
  }, [searchParams]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
     setValues({ ...values, [e.currentTarget.name]: e.currentTarget.value });
  };

  const { onLogin } = useAuth();
  const handleLogin =  async (e: React.FormEvent<HTMLFormElement>) =>  {
    e.preventDefault();
    if (onLogin && values.email.trim() !== '' && values.password.trim() !== '') {
      try {
        const res = await onLogin(values.email, values.password);
        if (res) {
          navigate('/');
        } else {
          alert('Что то пошло не так, попробуйте ещё раз');
          setValues({ ...values, password: '' });
        }
      } catch (e: any) {
        if (e.message === 'Unauthorized') {
          alert('Неверный email или пароль');
        }
      }
    } else {
      alert(`Заполните поля`);
    }
  };

  return (
    <div className={loginFormCn()}>
      <div className={loginFormCn('title')}>Вход</div>
      <Form onSubmit={handleLogin}>
        <div className={loginFormCn('inputs')}>
        <InputField
            className={loginFormCn('field')}
            classNameInput={loginFormCn('field_input')}
            onChange={handleChange}
            value={values.email}
            name="email"
            label="Почта"
            type="email"
            
          />
          <InputFieldPassword
            className={loginFormCn('field')}
            value={values.password}
            onChange={handleChange}
            name="password"
            placeholder="Пароль"
          />
        </div>
        <div className={loginFormCn('application')}>
          <Link to={links.passwordRecovery} className={loginFormCn('link')}>
            Забыли пароль?
          </Link>
        </div>
        <button  className="button-auth">
          Войти
        </button>
      </Form>
      <div className={loginFormCn('bottom')}>
        <span>Еще не с нами?</span>&nbsp;
        <Link className={loginFormCn('link')} to={links.register}>
          Зарегистрироваться
        </Link>
      </div>
    </div>
  );
};

export default LoginForm;


