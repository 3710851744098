import "./solutions-component.scss";
import { FC, useState } from "react";
import { bemCN } from "../../../configs/bem-classname";
import { ReactComponent as ArrowRight } from "../../../shared/images/svg/arrow-top-line.svg";
import { ReactComponent as Back1 } from "../../../shared/images/svg/background1-main-6s.svg";
import { ReactComponent as Back2 } from "../../../shared/images/svg/background2-main-6s.svg";

interface SolutionsProps {}

const solutionsCn = bemCN("solutions");

const solutionsText = [
  {
    id: 1,
    title: "Увеличение продаж",
    summary: [
      "Оптимизация приводит к повышению конверсии",
      "Анализ данных помогает находить прибыльные ниши",
      "Автоматизация процессов снижает время на рутинные задачи",
    ],
  },
  {
    id: 2,

    title: "Экономия времени",
    summary: [
      "Персональная поддержка избавит от рутинных задач и решит любые вопросы",
      "Все данные собраны в одном месте, что упрощает доступ к информации",
      "Удобные инструменты для фильтрации и сортировки данных",
    ],
  },
  {
    id: 3,

    title: "Прозрачность бизнеса",
    summary: [
      "Полный контроль над заказами и закупками",
      "Доступ к аналитике по всем ключевым показателям",
      "Легкий доступ к информации в режиме реального времени",
    ],
  },
  {
    id: 4,

    title: "Гибкость и адаптивность",
    summary: [
      "Возможность быстро реагировать на изменения рынка",
      "Настройка системы под ваши бизнес-процессы",
      "Инструменты для анализа и прогноза спроса",
    ],
  },
];
const Solutions: FC<SolutionsProps> = () => {
  const [activeId, setActiveId] = useState(1);
  return (
    <div className={solutionsCn()}>
      <h2 className={solutionsCn("title")}>
        <span className={solutionsCn("title_green")}>Преобразите </span> свой
        бизнес{" "}
        <span className={solutionsCn("title_green")}>с CRM от XSTAT! </span>
      </h2>
      <div className={solutionsCn("content")}>
        <div>
          {solutionsText.map((sol: any, index: number) => (
            <div
              onClick={() => setActiveId(sol.id)}
              key={sol.id}
              className={sol.id === activeId ? solutionsCn("active") : ""}
            >
              {index + 1}. {sol.title} <ArrowRight />
            </div>
          ))}
        </div>
        <div>
          <p>{solutionsText.find((s: any) => s.id === activeId)?.title}</p>
          {solutionsText
            .find((s: any) => s.id === activeId)
            ?.summary.map((sum: string) => (
              <div>
                <p> — {sum}</p>
              </div>
            ))}
        </div>
      </div>
      <div className={solutionsCn("b1")}>
        <Back1 />
      </div>
      <div className={solutionsCn("b2")}>
        <Back2 />
      </div>
    </div>
  );
};

export default Solutions;
