export const GetSuitableKey = (object: any, header: string): string => {
  let dataKeys = Object.keys(object);
  let res = "";
  dataKeys.forEach((d) => {
    if (d.toString().toLowerCase() == header.toString().toLowerCase()) {
      res = d.toString();
    }
  });
  return res;
};
