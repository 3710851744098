import { FC, memo, useEffect, useState } from "react";
import "./available-goods-component.scss";
import { OrderGoodModel, Supply } from "../../../../core/api/data-contracts";
import { bemCN } from "../../../../configs/bem-classname";
import { Button, availableButtons } from "../../../../ui/button/button";

import { CheckItem } from "../../../../ui/check-item/check-item";

const avGoodCN = bemCN("avGood");

type GoodsProps = {
  AllGoods?: OrderGoodModel[] | null;
  OnAdd?: (orders: OrderGoodModel[]) => void;
};
export const AvailableGoodsComponent: FC<GoodsProps> = memo(
  ({ AllGoods, OnAdd }) => {
    const [addingGoods, setAddingGoods] = useState<OrderGoodModel[]>([]);
    const onAdd = (order: OrderGoodModel) => {
      if (!addingGoods) return;
      addingGoods.push(order);
      setAddingGoods([...addingGoods]);
    };
    const onDel = (order: OrderGoodModel) => {
      if (!addingGoods || !order) return;
      setAddingGoods([...addingGoods.filter((g) => g.goodId != order.goodId)]);
    };
    return (
      <div className={avGoodCN()}>
        <div>
          {AllGoods?.map((g, i) => (
            <div key={i.toString() + (g && g.goodId ? g.goodId : "")}>
              <CheckItem
                Active={true}
                onActive={(isActive) => {
                  isActive ? onDel(g) : onAdd(g);
                }}
              />
              <div>{g.name}</div>
            </div>
          ))}
        </div>
        <Button
          text="Выбрать"
          blue
          onClick={() => {
            addingGoods && OnAdd && OnAdd(addingGoods);
          }}
        />
      </div>
    );
  }
);
