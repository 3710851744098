import React, { InputHTMLAttributes, ReactNode } from 'react';
import './search-input.scss';
import { ReactComponent as Cross } from '../../shared/images/svg/cross.svg';
import { ReactComponent as SearchBig } from '../../shared/images/svg/search.svg';
import { ReactComponent as SearchLink } from '../../shared/images/svg/search.svg';
import { bemCN } from '../../configs/bem-classname';




interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  inputValue?: string;
  type?: string;
  isDigitOnly?: boolean;
  onChangeValue?: (v: any) => void;
  isCross? : boolean;
  onCrossClick?: () => void;

}

const searchInput = bemCN('search-input');

const SearchInput = ({
  className,
  inputValue,
  type,
  isDigitOnly,
  onChangeValue,
  isCross,
  onCrossClick,
  ...other
}: SearchInputProps) => {
  return (
    <label className={searchInput("", [className])}>
      <span className={searchInput('icon')}>
        {type == 'big' ? <SearchBig/> : <SearchLink/>}
      </span>
      {isDigitOnly ? (
        <input
          onChange={(v: any) => onChangeValue && onChangeValue(v)}
          className={type == 'big' ? searchInput('input') : searchInput('input-small')}
          type="number"
          value={inputValue}
          {...other}
        />
      ) : (
        <input
          onChange={(v: any) => onChangeValue && onChangeValue(v)}
          className={type == 'big' ? searchInput('input') : searchInput('input-small')}
          type="text"
          value={inputValue}
          {...other}
        />
      )}
      {isCross && <Cross onClick={() => onCrossClick && onCrossClick()}/>}
    </label>
  );
};

export default SearchInput;
