export class SelectItem{
    id:number;
    value:string|number;


    constructor(id: number, value:string|number) {
        this.id = id;
        this.value = value;
      }
}

