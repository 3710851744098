import React, {
  InputHTMLAttributes,
  ChangeEvent,
  FC,
  useState,
  Validator,
} from "react";
import "./input-field.scss";
import { InputMask } from "primereact/inputmask";
import { bemCN } from "../../configs/bem-classname";
import { validateField, ValidatorFunc } from "../../shared/helpers/validation";

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  classNameInput?: string;
  hasTransparentBorders?: boolean;
  fieldSize?: "medium";
  validators?: ValidatorFunc[];
  isDate?: boolean;
  isPassword?: boolean;
  dateChange?: (v: any) => void;
}

const inputFieldCn = bemCN("input-field");

const InputField: FC<InputFieldProps> = ({
  className,
  classNameInput,
  label,
  onInput,
  hasTransparentBorders,
  fieldSize,
  validators,
  isDate,
  isPassword,
  dateChange,
  ...rest
}) => {
  const [haveValue, setHaveValue] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const validate = (v: any) => {
    if (validators?.length) {
      setError(validateField(v, validators));
    }
  };

  const inputUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    if (typeof onInput === "function") onInput(e);

    validate(e.target.value);

    if (!e.target.value) {
      setHaveValue(false);
      return;
    }

    setHaveValue(true);
  };

  const inputBlur = (e: any) => {
    if (typeof rest.onBlur === "function") rest.onBlur(e);

    validate(e.target.value);
  };

  return (
    <>
      <label
        className={
          inputFieldCn("", { active: haveValue, invalid: !!error }) +
          " " +
          className +
          " " +
          fieldSize
        }
      >
        <fieldset
          className={inputFieldCn("fieldset", {
            "transparent-borders": hasTransparentBorders,
          })}
        >
          {!isDate && !isPassword && (
            <input
              readOnly={true}
              onFocus={(e) => {
                e.target.removeAttribute("readonly");
              }}
              className={inputFieldCn("input") + " " + classNameInput}
              type="text"
              onInput={inputUpdate}
              onBlur={inputBlur}
              {...rest}
            />
          )}
          {isDate && (
            <InputMask
              readOnly={false}
              className={inputFieldCn("input") + " " + classNameInput}
              onFocus={(e) => {
                e.target.removeAttribute("readonly");
              }}
              //slotChar="01.01.2000"
              onBlur={inputBlur}
              onChange={(e: any) => {
                inputUpdate(e);
                dateChange && dateChange(e);
              }}
              mask="99.99.9999"
            />
          )}

          {isPassword && (
            <input
              readOnly={true}
              onFocus={(e) => {
                e.target.removeAttribute("readonly");
              }}
              className={inputFieldCn("input") + " " + classNameInput}
              type="password"
              onInput={inputUpdate}
              onBlur={inputBlur}
              {...rest}
            />
          )}

          <legend className={inputFieldCn("legend")}>{label}</legend>
          <span className={inputFieldCn("name")}>{label}</span>
        </fieldset>
        {error && <span className={inputFieldCn("error-text")}>{error}</span>}
      </label>
    </>
  );
};

export default InputField;
