import React, { useEffect } from "react";
import MainAdvantagesFeatures from "./advanteres-features-component/advanteres-features-component";
import MainTariff from "./main-tariff-component/main-tariff-component";
import MainReviews from "./reviews-component/reviews-component";
import MainServiceFeatures from "./main-service-features-component/main-service-features-component";
import MainTop from "./top-component/top-component";
import "./home-component.scss";
import { redirect, useNavigate } from "react-router-dom";
import QuickReg from "./quick-reg-component/quick-reg-component";
import Opportunities from "./opportunities-component/opportunities-component";
import Solutions from "./solutions-component/solutions-component";
import { bemCN } from "../../configs/bem-classname";

const mainPageCn = bemCN("home");
const Home = () => {
  // const { onLogin, getAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // if (getAuth()) {
    //navigate(links.analyitcs + '/' + links.analyticsProduct);
    // }
  });

  return (
    <div className={mainPageCn()}>
      <div className={mainPageCn("container")}>
        <section className={mainPageCn("section")}>
          <MainTop />
        </section>
        <section>
          <QuickReg />
        </section>
        <section className={mainPageCn("section")}>
          <MainAdvantagesFeatures />
        </section>
        <section id="advantages_section" className={mainPageCn("section")}>
          <MainServiceFeatures />
        </section>
        <section>
          <QuickReg />
        </section>
        <section>
          <Opportunities />
        </section>
        <section className={mainPageCn("section")}>
          <Solutions />{" "}
        </section>
        <section>
          <QuickReg />
        </section>

        <section id="tariff_section" className={mainPageCn("section")}>
          <MainTariff />
        </section>
        <section>
          <QuickReg />
        </section>
        <section className={mainPageCn("section")}>
          <MainReviews />
        </section>
      </div>
    </div>
  );
};

export default Home;
