import { bemCN } from "../../../../configs/bem-classname";
import "./side-filter-panel.scss";
import { FC, ReactNode } from "react";
import { ReactComponent as Cross } from "../../../../shared/images/svg/cross.svg";
import { Button } from "../../../../ui/button/button";

type SideFilterPanelProps = {
  id?: string;
  children: ReactNode;
  isShow: boolean;
  setIsShow(isShow: boolean): void;
  onSave?: () => void;
  onCancel?: () => void;
};

export const SideFilterPanel: FC<SideFilterPanelProps> = ({
  id,
  children,
  setIsShow,
  onSave,
  onCancel,
}) => {
  const sideFilterPanelCN = bemCN("sideFilterPanel");

  return (
    <div id={id} className={sideFilterPanelCN()}>
      <div className={sideFilterPanelCN("header")}>
        <div className="h3">Фильтры</div>
        <div
          onClick={() => {
            setIsShow(false);
          }}
        >
          <Cross />
        </div>
      </div>
      <div className={sideFilterPanelCN("content")}>{children}</div>
      <div className={sideFilterPanelCN("buttons")}>
        <Button white-outline text="Сбросить" onClick={onCancel} />
        <Button blue text="Применить" onClick={onSave} />
      </div>
    </div>
  );
};
