import { useState, useEffect } from 'react'
import { VariablesLocalStorage } from '../../consts/variables-localstorage';

export function useLocalStorage(key:VariablesLocalStorage, initialValue:any) {
  const [value, setValue] = useState(() => {
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : initialValue;
  })

  useEffect(() => {
    const item = JSON.stringify(value);
    window.localStorage.setItem(key, item);
  }, [value])

  return [value, setValue]
}