import React, { FC } from 'react';
import './client-auth-component.scss';
import { Link } from 'react-router-dom';
import { links } from '../../../consts/links';
import { bemCN } from '../../../configs/bem-classname';

interface ClientAuthLinkProps {}

const loginPageCn = bemCN('auth');
const ClientAuthLink: FC<ClientAuthLinkProps> = () => (
  <div className={loginPageCn()}>
    <Link className={loginPageCn('button_outline')} to={links.login}>
      Войти
    </Link>
    <Link className={loginPageCn('button_fill')} to={links.register}>
      Зарегистироваться
    </Link>
  </div>
);

export default ClientAuthLink;
