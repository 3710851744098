import { bemCN } from "../../configs/bem-classname";
import { Tasks } from "../../modules/task-module";


import "./tasks-page.scss";

const tasksCN = bemCN("tasks-page");

export const TasksPage = () => {
  return (
    <div className={tasksCN()}>
      <Tasks/>
    </div>
  );
};
    