import { Saler } from "../../core/entities/saller";


export const salers = [
  new Saler(1, "Шмот", "https://monopoly-one.com/", "Россия", "Иванов И.И."),
  new Saler(2, "Игрушки Про", "https://monopoly-one.com/", "Узбекистан", "Петрова А.А."),
  new Saler(3, "Кубик", "https://monopoly-one.com/", "Россия", "Сидоров М.Н."),

];

export const headersSaler=[
    "Названия",
    "Ссылка",
    "Страна",
    "Владелец"
]