import React, { FC, useState } from "react";
import "./main-service-features-component.scss";
import { bemCN } from "../../../configs/bem-classname";
import { ReactComponent as Screen1 } from "../../../shared/images/svg/Screen1.svg";
import { ReactComponent as Screen2 } from "../../../shared/images/svg/Screen2.svg";

interface MainServiceFeaturesProps {}

const servicePageCn = bemCN("MainServiceFeatures");
const MainServiceFeatures: FC<MainServiceFeaturesProps> = () => {
  const [activeBlock, setActiveBlock] = useState(0);

  const blockClick = (id: number) => {
    setActiveBlock(id);
  };
  return (
    <div className={servicePageCn()}>
      <h2 className={servicePageCn("title")}>
        <span className={servicePageCn("title_green")}>Возможности</span>{" "}
        сервиса
      </h2>

      <div className={servicePageCn("content")}>
        <div>
          <Screen1 />
          <div>
            <p className={servicePageCn("h1-block")}>Отчетность</p>
            <div className={servicePageCn("line")}></div>
            <p className={servicePageCn("h2-block")}>Отчеты о продажах</p>
            <p className={servicePageCn("p-block")}>
              Получайте детализированные отчеты о продажах, которые помогут вам
              оценить эффективность своего бизнеса. Вы будете сразу видеть
              успешные товары и категории, чтобы адаптировать стратегию продаж.{" "}
            </p>

            <p className={servicePageCn("h2-block")}>Анализ закупок</p>
            <p className={servicePageCn("p-block")}>
              Отчеты о закупках предоставляют полную картину ваших затрат и
              позволяют оптимизировать расходы. Вы сможете видеть, какие
              поставщики наиболее выгодны и какие товары требуют пересмотра.
            </p>
            <p className={servicePageCn("h2-block")}>
              Мониторинг маржинальности
            </p>
            <p className={servicePageCn("p-block")}>
              Анализируйте маржинальность товаров, чтобы выявлять наиболее
              прибыльные позиции. Это поможет вам принимать обоснованные решения
              по ассортименту и ценовой политике.
            </p>
            <p className={servicePageCn("h2-block")}>Прогресс магазинов</p>
            <p className={servicePageCn("p-block")}>
              Следите за прогрессом магазинов на маркетплейсах, чтобы вносить
              необходимые коррективы в стратегию продаж для стабильного роста
              вашего бизнеса. С помощью CRM от XSTAT вы сможете эффективно
              управлять магазином на маркетплейсах.
            </p>
          </div>
        </div>
        <div>
          <div>
            <p className={servicePageCn("h1-block")}>Управление</p>
            <div className={servicePageCn("line")}></div>
            <p className={servicePageCn("h2-block")}>Информация о заказах</p>
            <p className={servicePageCn("p-block")}>
              Получайте мгновенные обновления о статусах заказов: новые, в
              доставке, отмененные и проданные. Это поможет контролировать
              процесс на каждом этапе и оперативно реагировать на изменения.
            </p>

            <p className={servicePageCn("h2-block")}>Таблица закупок</p>
            <p className={servicePageCn("p-block")}>
              Вся информация о закупках собрана в одном месте: номер товара,
              дата, сумма, статус, поставщик и так далее. Удобные функции
              сортировки и фильтрации позволяют быстро находить нужные данные и
              упрощают работу с большими объемами информации.
            </p>
            <p className={servicePageCn("h2-block")}>
              Приемка и перемещение товаров
            </p>
            <p className={servicePageCn("p-block")}>
              Управление процессами приемки и перемещения товаров между складами
              стало проще. Система позволяет избежать путаницы и потерь, отражая
              все операции абсолютно прозрачно.
            </p>
            <p className={servicePageCn("h2-block")}>Управление запасами</p>
            <p className={servicePageCn("p-block")}>
              Следите за уровнем запасов и автоматизируйте процессы пополнения.
              Это помогает избежать дефицита или избытка товаров, что в свою
              очередь улучшает финансовые показатели вашего бизнеса.
            </p>
          </div>
          <Screen2 />
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default MainServiceFeatures;
