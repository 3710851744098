import { bemCN } from "../../configs/bem-classname";
import { StockCard } from "../../modules/stock-module";

const stockCN = bemCN("StockCardPage");

export const StockCardPage = () => {
  return (
    <div >
      <StockCard/>
    </div>
  );
};