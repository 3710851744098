import { BarChartDataItem } from "../../core/view-models/bar-chart-data-item";

export const data = [
  new BarChartDataItem("20.10",401),
  new BarChartDataItem("21.10",452),
  new BarChartDataItem("22.10",405),
  new BarChartDataItem("23.10",500),
  new BarChartDataItem("24.10",350),
  new BarChartDataItem("25.10",250),
  new BarChartDataItem("26.10",550),
  new BarChartDataItem("27.10",450),
  new BarChartDataItem("28.10",355),
  new BarChartDataItem("29.10",500),
  new BarChartDataItem("30.10",510),
  new BarChartDataItem("31.10",410),
];
