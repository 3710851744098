import { useEffect, useState } from "react";
import "./good-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as ArrowBack } from "../../../../shared/images/svg/arrow-back.svg";

import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { ReactComponent as History } from "../../../../shared/images/svg/active-history.svg";
import { ReactComponent as TrashBin } from "../../../../shared/images/svg/trash-bin.svg";
import { ReactComponent as Delete } from "../../../../shared/images/svg/delete.svg";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import ImageCard from "../../../../ui/image-card/image-card";
import Expander from "../../../../ui/expander/expander";
import { CustomBarChart as BarChart } from "../../../../ui/bar-chart/bar-chart";
import { data } from "../../../../consts/values/bar-chart-data";
import Table from "../../../../components/table-component/table-component";
import { headersProduct, products } from "../../../../consts/values/products";
import MarketplaceBarcodeButton from "../marketplace-barcode-button-component/marketplace-barcode-button-component";
import { API } from "../../../../consts/api";
import { UpdateObject } from "../../../../shared/helpers/update-object";
import { ConfirmationModal } from "../../../../components/modal-component/confirmation-modal/confirmation-modal";
import { ButtonSelectCategory } from "../../../category-module";
import { Subcategory } from "../../../category-module/entities/subcategory";
import { CharacteristicGood } from "../../../../core/entities/characteristic-good";
import { Size, StatisticsItemModel } from "../../../../core/api/data-contracts";
import { GetContainsCharacteristic } from "../../helpers/get-contains-characteristic";
import Loading from "../../../../components/loading-component/loading-component";
import { Characteristic } from "../../../../core/api/data-contracts";
import { Color } from "../../../../core/entities/color";
import { InputSearch } from "../../../../ui/input-search/input-search";
import { Country } from "../../../../core/entities/country";
import saveAs from "file-saver";

const goodsCardCN = bemCN("goods-card");

export const GoodsCard = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isSizeChar = 14177456;
  const [good, SetGood] = useState<any>();
  const [cardGoods, setCardGoods] = useState<any[]>();
  const [newGoods, setNewGoods] = useState<any[]>();
  const [IsEdit, SetIsEdit] = useState(false);
  const [graphWidth, setGraphWidth] = useState(0);
  const [GoodMeasurements, SetGoodMeasurements] = useState<any[]>([]);
  const [Contractors, SetContractors] = useState<any[]>([]);
  const [subCategory, setSubCategory] = useState<Subcategory>();
  const [colors, setColors] = useState<Color[]>();
  const [kinds, setKinds] = useState<string[]>();
  const [countries, setCountries] = useState<Country[]>();
  const [seasons, setSeasons] = useState<string[]>();
  const [tnved, setTnved] = useState<string[]>();
  const [vat, setVat] = useState<string[]>();
  const [tablePurchase, setTablePurchase] = useState<any[]>();
  const [tablePurchaseHeaders, setTablePurchaseHeaders] = useState<string[]>();
  const [tableRemainder, setTableRemainder] = useState<any[]>();
  const [tableRemainderHeaders, setTableRemainderHeaders] =
    useState<string[]>();
  const [chartPurchase, setChartPurchase] = useState<StatisticsItemModel[]>();
  const [chartSales, setChartSales] = useState<StatisticsItemModel[]>();

  const [characteristics, setСharacteristics] =
    useState<CharacteristicGood[]>();
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [isShowModalDeleteVariant, setIsShowModalDeleteVariant] =
    useState(false);
  const [IsNew, SetIsNew] = useState(false);
  const [IsLoading, SetIsLoading] = useState(false);
  const [Error, SetError] = useState<Error>();

  const [selectedFile, setSelectedFile] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  //Удаление варианта товара
  const onDeleteVariant = () => {
    cardGoods && cardGoods.length <= 1
      ? onDelete()
      : good &&
        API.goodDeleteDelete([good])
          .then((res) => {
            let cg =
              cardGoods &&
              cardGoods.filter((e) => e.localeID !== good.localeID);
            let ng =
              newGoods && newGoods.filter((e) => e.localeID !== good.localeID);
            setCardGoods(cg);
            setNewGoods(ng);
            cg && SetGood(cg[0]);
            setIsShowModalDeleteVariant(false);
          })
          .catch((e) => {
            console.log(e);
          });
  };

  const onDelete = () => {
    cardGoods &&
      API.goodDeleteDelete(cardGoods).then((res) => {
        setIsShowModalDelete(false);
        navigate("/directories/goods");
      });
  };
  //Добавление карточки
  const onSave = () => {
    IsNew
      ? cardGoods &&
        cardGoods.map((g, i) => {
          i == 0
            ? API.goodAddCreate(g)
                .then((res) => {
                  SetIsEdit(false);
                  SetIsNew(false);
                  setCardGoods([
                    ...cardGoods.slice(0, i),
                    res.data,
                    ...cardGoods.slice(i + 1),
                  ]);
                })
                .catch((e) => {
                  console.log(e);
                })
            : API.goodAddVariantCreate(g)
                .then((res) => {
                  SetIsEdit(false);
                  SetIsNew(false);
                  setCardGoods([
                    ...cardGoods.slice(0, i),
                    res.data,
                    ...cardGoods.slice(i + 1),
                  ]);
                })
                .catch((e) => {
                  console.log(e);
                });
        })
      : OnUpdate();
  };
  //Удаление варианта характеристики
  const OnDeleteVariantCharacteristic = (
    index: number,
    ch: CharacteristicGood
  ) => {
    let g = good;
    let c = g?.characteristics?.find(
      (m: { id: number | undefined }) => m.id == ch.charcID
    )?.value;
    c.splice(index, 1);
    SetGood({ ...g });
  };
  //Обновление карточки
  const OnUpdate = () => {
    newGoods &&
      newGoods.map((g, i) => {
        API.goodAddVariantCreate(g)
          .then((res) => {
            SetIsEdit(false);
            SetIsNew(false);
            setNewGoods([
              ...newGoods.slice(0, i),
              res.data,
              ...newGoods.slice(i + 1),
            ]);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    let newId = newGoods?.map((n) => n.vendorCode);
    let update = cardGoods?.filter((c) => !newId?.includes(c.vendorCode));

    update?.map((u) => {
      if (u.sizes && u.sizes.length > 0)
        (u.sizes as any)[0].skus = [Date.now().toString()];
      if (!characteristics?.find((c) => c.charcID == isSizeChar)) {
        (u.sizes as any)[0].techSize = undefined;
        (u.sizes as any)[0].wbSize = undefined;
      }
      API.goodUpdateUpdate(u)
        .then(() => {
          SetIsEdit(false);
          SetIsNew(false);
        })
        .catch((e) => {
          SetIsEdit(false);
          SetIsNew(false);
          console.log(e);
        });
    });
  };

  //Удаление картинки
  const deleteFile = (position: number) => {
    API.goodDeleteMediaCreate(good, { position }).then(() => {
      API.goodDetail(Number(id))
        .then((res) => {
          setCardGoods(res.data);
          !IsNew && setNewGoods([]);
          SetGood(res.data.find((r) => r.localeID == Number(id)));
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };
  //Добавление картинки
  const handleFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    event.target.value = null;
  };
  useEffect(() => {
    if (selectedFile) {
      API.goodAddMediaCreate(
        { formFile: selectedFile },
        {
          articleWB: good.nmID,
          position: good.photos != null ? good.photos.length + 1 : 1,
        }
      )
        .then((res) => {
          setIsLoading(true);
          API.goodDetail(Number(id)).then((res) => {
            setCardGoods(res.data);
            !IsNew && setNewGoods([]);
            SetGood(res.data.find((r) => r.localeID == Number(id)));
          });
        })
        .catch((e) => {
          console.log(e);
          setUploadError(true);
        });
    }
  }, [selectedFile]);
  //Получение товара по id
  useEffect(() => {
    if (id) {
      API.goodDetail(Number(id))
        .then((res) => {
          setCardGoods(res.data);
          !IsNew && setNewGoods([]);
          SetGood(res.data.find((r) => r.localeID == Number(id)));
          if (!res.data[0].subjectID) return;
          if (!res.data[0].subjectName) return;
          setSubCategory(
            new Subcategory(res.data[0].subjectID, res.data[0].subjectName)
          );
          SetIsLoading(true);
        })
        .catch((e) => {
          SetError(e);
          SetIsLoading(false);
        });
    } else {
      SetIsNew(true);
      let g: any = {
        vendorCode: Date.now().toString(),
        sizes: [],
      };
      SetGood(g);
      setCardGoods([g]);
      SetIsLoading(true);
    }
  }, [id]);

  //Получение таблиц по id
  useEffect(() => {
    if (id) {
      API.goodGetGoodPurchaseList({ id: Number(id) })
        .then((res) => {
          setTablePurchaseHeaders(res.data.nameFieldsPurchase);
          setTablePurchase(res.data.dataPurchase);
        })
        .catch((e) => {});
    }
  }, [id]);
  //Получение таблиц по id
  useEffect(() => {
    if (id) {
      API.goodGetRemaindersOnStoresList({ id: Number(id) })
        .then((res) => {
          setTableRemainder(res.data.dataRemainder);
          setTableRemainderHeaders(res.data.nameFieldsRemainder);
        })
        .catch((e) => {});
    }
  }, [id]);

  //Получение графиков по id
  useEffect(() => {
    if (id) {
      API.goodGetPurchaseAcceptanceGoodForChartList({ id: Number(id) })
        .then((res) => {
          setChartPurchase(res.data);
        })
        .catch((e) => {});
    }
  }, [id]);
  //Получение графиков по id
  useEffect(() => {
    if (id) {
      API.goodGetSalesGoodForChartList({ id: Number(id) })
        .then((res) => {
          setChartSales(res.data);
        })
        .catch((e) => {});
    }
  }, [id]);

  //Получение характеристик по категориям
  useEffect(() => {
    subCategory &&
      API.goodGetWbCharacteristicsBySubjectIdList({
        subjectId: subCategory.subjectID,
      }).then((res) => {
        let data: any = res.data;
        setСharacteristics(
          data.data.sort((a: any, b: any) => b.popular - a.popular)
        );
      });
    good &&
      SetGood({
        ...good,
        subjectID: subCategory?.subjectID,
        subjectName: subCategory?.subjectName,
      });
  }, [subCategory]);

  useEffect(() => {
    API.goodMeasurementGetAllItemsList().then((res) => {
      SetGoodMeasurements([...res.data.items]);
    });
  }, []);
  //Получение цветов
  useEffect(() => {
    API.goodGetWbColorsList().then((res) => {
      let r: any = res?.data;
      res.data && setColors(r.data);
    });
  }, []);
  //Получение пола пользователя
  useEffect(() => {
    API.goodGetWbKindsList().then((res) => {
      let r: any = res?.data;
      res.data && setKinds(r.data);
    });
  }, []);
  //Получение стран
  useEffect(() => {
    API.goodGetWbCountriesList().then((res) => {
      let r: any = res?.data;
      res.data && setCountries(r.data);
    });
  }, []);
  //Получение сезона
  useEffect(() => {
    API.goodGetWbSeasonsList().then((res) => {
      let r: any = res?.data;
      res.data && setSeasons(r.data);
    });
  }, []);
  //Получение НДС
  useEffect(() => {
    API.goodGetWbVatList().then((res) => {
      let r: any = res?.data;
      res.data && setVat(r.data);
    });
  }, []);
  //Получение tnved
  useEffect(() => {
    subCategory &&
      API.goodGetWbTnvedList({ subjectId: subCategory.subjectID }).then(
        (res) => {
          let r: any = res?.data;
          r && setTnved(r.data.map((m: any) => m.tnved));
        }
      );
  }, [subCategory]);

  useEffect(() => {
    API.goodMeasurementGetAllItemsList().then((res) => {
      SetGoodMeasurements([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    API.contractorGetAllItemsList().then((res) => {
      SetContractors([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    if (!good || !cardGoods) return;
    let card = cardGoods?.map((g) => {
      if (g.localeID == good.localeID) {
        g = good;
        g.sizes = good.sizes;
      }
      return g;
    });
    setCardGoods(card);
    if (!newGoods) return;
    let newG = newGoods?.map((g) => {
      if (g.localeID == good.localeID) {
        g = good;
      }
      return g;
    });
    setNewGoods(newG);
  }, [good]);
  useEffect(() => {
    let d =
      document.getElementsByClassName("goods-card__info")[0] &&
      document
        .getElementsByClassName("goods-card__info")[0]
        .getBoundingClientRect().width + 49;
    d && setGraphWidth(d);
  });
  // const onUpdate = () => {
  //   API.goodUpdateUpdate(good).then(() => {
  //     SetIsEdit(false);
  //   });
  // };
  // const onAdd = () => {
  //   API.goodAddCreate(good).then(() => {
  //     SetIsEdit(false);
  //     SetIsNew(false);
  //   });
  // };
  // const onDelete = () => {
  //   API.goodDelete(good.id).then(() => {
  //     navigate("/directories/goods/");
  //   });
  // };

  // //Добавление размеров
  // const addSize = () => {
  //   let sizes: Size[] = good?.sizes ? good?.sizes : [];
  //   sizes?.push({ techSize: "", wbSize: "", price: 0, chrtID: Date.now() });
  //   SetGood({ ...good, sizes: sizes });
  // };

  //Добавление размеров
  const addSize = () => {
    let sizes = good?.sizes;
    sizes!.push({
      wbSize: "",
      techSize: "",
      skus: [""],
      price: 0,
    });
    SetGood({ ...good, sizes: sizes });
  };
  //Обновление размеров
  const updateSize = (i: number, item: Size) => {
    let sizes = good?.sizes;
    if (!sizes) return;
    sizes[i] = item;
    SetGood({ ...good, sizes: sizes });
  };

  //Удаление размеров
  const deleteSizes = (i: number) => {
    let sizes = good?.sizes;
    sizes = sizes?.filter((e: any, index: number) => index !== i);
    SetGood({ ...good, sizes: sizes });
  };

  //Обновление характеристик товара
  const updateCharacteristic = (ch: CharacteristicGood, value: any) => {
    let characteristics: Characteristic[] = good?.characteristics
      ? good?.characteristics
      : [];
    if (ch.charcType && ch.charcType == 4 && !Array.isArray(value)) {
      ch.value = Number(value);
    } else {
      ch.value = value;
    }
    ch.id = ch.charcID;
    let c: Characteristic | undefined = characteristics.find(
      (c) => c.id == ch.id
    );
    if (c) {
      let f = characteristics.filter((fg) => fg.id != c?.id);
      f.push(c);
      characteristics = f;
    } else {
      characteristics.push(ch as Characteristic);
    }
    SetGood({ ...good, characteristics: characteristics });
  };
  const saveImageSkus = (index: number) => {
    if (index == 1) {
      good?.sizes?.map((s: { skusImg: any[]; skus: { [x: string]: any } }) => {
        s.skusImg?.map(
          (i: any, index: string | number) =>
            s.skus &&
            s.skus[index] &&
            saveAs(`data:image//png;base64,${i}`, `${s.skus[index]}.png`)
        );
      });
    }
  };
  const addVariant = () => {
    let g = { ...good };
    g!.vendorCode = Date.now().toString();
    g!.localeID = Date.now();
    let arr = cardGoods;
    let arrNew = newGoods;
    arr?.push(g);
    arrNew?.push(g);
    cardGoods && g && setCardGoods(arr);
    newGoods && g && setNewGoods(arrNew);
    SetGood(g);
  };
  if (Error) {
    throw Error;
  }
  if (!IsLoading) {
    return <Loading color="dark" />;
  }
  return (
    <div className={goodsCardCN()}>
      <div className={goodsCardCN("header")}>
        <div>
          <button
            onClick={() => {
              good && IsEdit
                ? SetIsEdit(false)
                : navigate("/directories/goods/");
            }}
          >
            <ArrowBack />
            <h2>{IsNew ? "Новый товар" : good?.title}</h2>
          </button>
        </div>
        <div>
          {/* <Button circle text="+" /> */}
          <MarketplaceBarcodeButton onCheck={saveImageSkus} />
          {IsEdit ? (
            <Button
              white-outline
              text="Закрыть"
              onClick={() => {
                SetIsEdit(!IsEdit);
              }}
            />
          ) : null}
          {IsEdit || IsNew ? (
            <Button onClick={onSave} text={"Сохранить"} blue />
          ) : (
            <Button
              onClick={() => SetIsEdit(true)}
              text={"Редактировать"}
              blue
            />
          )}
          {/* {!IsEdit ? <Button image-white icon={<History />} /> : null} */}

          {IsEdit ? (
            <Button
              image-red
              icon={<TrashBin />}
              onClick={() => {
                setIsShowModalDelete(true);
              }}
            />
          ) : null}
        </div>
      </div>
      <div className={goodsCardCN("main")}>
        <div>
          <div className={goodsCardCN("info")}>
            <InputText
              header="Название:"
              text={good?.title ? good.title : ""}
              isActive={IsNew === false ? !IsEdit : !IsNew}
              OnChange={(v) => {
                SetGood({ ...good, title: v });
              }}
            />
            <InputText
              header="Артикул:"
              text={good?.vendorCode ? good.vendorCode : ""}
              isActive={IsNew === false ? !IsEdit : !IsNew}
              OnChange={(v) => {
                SetGood({ ...good, vendorCode: v });
              }}
            />
            {!IsNew && (
              <>
                <InputText
                  header="Код озон:"
                  text={good?.vendorCode ? good.vendorCode : ""}
                  isActive={true}
                  OnChange={(v) => {}}
                />
                <InputText
                  header="Код WB:"
                  text={good?.nmID ? good.nmID.toString() : ""}
                  isActive={true}
                  OnChange={(v) => {}}
                />
              </>
            )}
            {!characteristics?.find((c) => c.charcID == isSizeChar) && (
              <InputText
                header="Цена товара:"
                text={
                  good?.sizes && good.sizes.length > 0 && good.sizes[0].price
                    ? good.sizes[0].price.toString()
                    : ""
                }
                isActive={IsNew === false ? !IsEdit : !IsNew}
                OnChange={(v) => {
                  SetGood({
                    ...good,
                    sizes: [
                      {
                        techSize:
                          good?.sizes && good?.sizes.length > 0
                            ? good.sizes[0].techSize?.toString()
                            : "",
                        wbSize:
                          good?.sizes && good?.sizes.length > 0
                            ? good.sizes[0].wbSize?.toString()
                            : "",
                        price: Number(v),
                        chrtID:
                          good?.sizes && good?.sizes.length > 0
                            ? good.sizes[0].chrtID
                            : 0,
                      },
                    ],
                  });
                }}
              />
            )}
            <InputText
              header="Бренд:"
              text={good?.brand ? good.brand : ""}
              isActive={IsNew === false ? !IsEdit : !IsNew}
              OnChange={(v) => {
                SetGood({ ...good, brand: v });
              }}
            />
            <InputText
              header="Длина:"
              text={
                good?.dimensions?.length ? String(good.dimensions.length) : ""
              }
              isActive={IsNew === false ? !IsEdit : !IsNew}
              OnChange={(v) => {
                SetGood({
                  ...good,
                  dimensions: {
                    height: good?.dimensions?.height,
                    width: good?.dimensions?.width,
                    length: Number(v),
                  },
                });
              }}
            />
            <InputText
              header="Ширина:"
              text={
                good?.dimensions?.width ? String(good?.dimensions?.width) : ""
              }
              isActive={IsNew === false ? !IsEdit : !IsNew}
              OnChange={(v) => {
                SetGood({
                  ...good,
                  dimensions: {
                    height: good?.dimensions?.height,
                    length: good?.dimensions?.length,
                    width: Number(v),
                  },
                });
              }}
            />
            <InputText
              header="Высота:"
              text={
                good?.dimensions?.height ? String(good?.dimensions?.height) : ""
              }
              isActive={IsNew === false ? !IsEdit : !IsNew}
              OnChange={(v) => {
                SetGood({
                  ...good,
                  dimensions: {
                    width: good?.dimensions?.width,
                    length: good?.dimensions?.length,
                    height: Number(v),
                  },
                });
              }}
            />
            <InputText
              id="description"
              isTextArea
              header="Описание:"
              text={good?.brand ? String(0) : ""}
              isActive={IsNew === false ? !IsEdit : !IsNew}
              OnChange={(v) => {
                SetGood(UpdateObject(good, "priceSelling", v));
              }}
              maxLengh={5000}
            />
          </div>
          {characteristics?.find((c) => c.charcID == isSizeChar) && (
            <div className={goodsCardCN("title", ["h3"])}>Размеры</div>
          )}

          <div className={goodsCardCN("sizes")}>
            {good &&
            good.sizes &&
            characteristics?.find((c) => c.charcID == isSizeChar)
              ? good.sizes.map((item: Size, i: number) => {
                  return (
                    <div key={"size" + i.toString()}>
                      <InputText
                        header="Размер*:"
                        text={item.techSize ? item.techSize : ""}
                        isActive={IsNew === false ? !IsEdit : !IsNew}
                        OnChange={(v) => {
                          item.techSize = v;
                          updateSize(i, item);
                        }}
                      />
                      <InputText
                        header="Рос. размер*:"
                        text={item.wbSize ? item.wbSize : ""}
                        isActive={IsNew === false ? !IsEdit : !IsNew}
                        OnChange={(v) => {
                          item.wbSize = v;
                          updateSize(i, item);
                        }}
                      />
                      <InputText
                        header="Баркод:"
                        text={item.skus ? item.skus[0] : " "}
                        isActive={IsNew === false ? !IsEdit : !IsNew}
                        OnChange={(v) => {
                          item.skus = [v];
                          updateSize(i, item);
                        }}
                      />
                      <InputText
                        header="Цена*:"
                        text={item.price ? item.price.toString() : ""}
                        isActive={IsNew === false ? !IsEdit : !IsNew}
                        OnChange={(v) => {
                          item.price = Number(v);
                          updateSize(i, item);
                        }}
                      />
                      <button onClick={() => deleteSizes(i)} className="del">
                        <Delete />
                      </button>
                    </div>
                  );
                })
              : null}
          </div>
          {(IsEdit || IsNew) &&
          characteristics?.find((c) => c.charcID == isSizeChar) ? (
            <div className={goodsCardCN("add-size-btn")}>
              <Plus />
              <Button white text="Добавить размер" onClick={() => addSize()} />
            </div>
          ) : null}
          <div className={goodsCardCN("expanders")}>
            <ButtonSelectCategory
              IsEdit={IsEdit || IsNew}
              DefaultCategory={subCategory}
              OnSelectSubCategory={setSubCategory}
            />

            <Expander title="Характеристики">
              <div className={goodsCardCN("characteristics")}>
                {characteristics &&
                  characteristics.map((ch) => (
                    <div key={ch.charcID}>
                      <div className={goodsCardCN("", ["h4"])}>{ch.name}:</div>
                      {
                        //Цвет
                        ch.charcID == 14177449 ? (
                          <InputSearch
                            isActive={IsNew === false ? !IsEdit : !IsNew}
                            data={colors?.map((c) => c.name)}
                            selectList={
                              good?.characteristics?.find(
                                (m: { id: number | undefined }) =>
                                  m.id == ch.charcID
                              )?.value
                            }
                            OnSelect={(object) => {
                              let g = good;
                              let c = g?.characteristics?.find(
                                (m: { id: number | undefined }) =>
                                  m.id == ch.charcID
                              )?.value;
                              c ? c.push(object) : (c = [object]);
                              updateCharacteristic(ch, c);
                            }}
                            maxCount={5}
                            OnDelete={(index) => {
                              OnDeleteVariantCharacteristic(index, ch);
                            }}
                          />
                        ) : //Пол
                        ch.charcID == 204557 ? (
                          <InputSearch
                            isActive={IsNew === false ? !IsEdit : !IsNew}
                            data={kinds}
                            selectList={
                              good?.characteristics?.find(
                                (m: { id: number | undefined }) =>
                                  m.id == ch.charcID
                              )?.value
                            }
                            OnSelect={(object) => {
                              updateCharacteristic(ch, [object]);
                            }}
                            maxCount={1}
                            OnDelete={(index) => {
                              OnDeleteVariantCharacteristic(index, ch);
                            }}
                          />
                        ) : //Страна
                        ch.charcID == 14177451 ? (
                          <InputSearch
                            isActive={IsNew === false ? !IsEdit : !IsNew}
                            data={countries?.map((c) => c.name)}
                            selectList={
                              good?.characteristics?.find(
                                (m: { id: number | undefined }) =>
                                  m.id == ch.charcID
                              )?.value
                            }
                            OnSelect={(object) => {
                              updateCharacteristic(ch, [object]);
                            }}
                            maxCount={1}
                            OnDelete={(index) => {
                              OnDeleteVariantCharacteristic(index, ch);
                            }}
                          />
                        ) : // сезон
                        ch.charcID == 18769 ? (
                          <InputSearch
                            isActive={IsNew === false ? !IsEdit : !IsNew}
                            data={seasons}
                            selectList={
                              good?.characteristics?.find(
                                (m: { id: number | undefined }) =>
                                  m.id == ch.charcID
                              )?.value
                            }
                            OnSelect={(object) => {
                              let g = good;
                              let c = g?.characteristics?.find(
                                (m: { id: number | undefined }) =>
                                  m.id == ch.charcID
                              )?.value;
                              c ? c.push(object) : (c = [object]);
                              updateCharacteristic(ch, c);
                            }}
                            maxCount={3}
                            OnDelete={(index) => {
                              OnDeleteVariantCharacteristic(index, ch);
                            }}
                          />
                        ) : // ндс
                        ch.charcID == 15001405 ? (
                          <InputSearch
                            isActive={IsNew === false ? !IsEdit : !IsNew}
                            data={vat}
                            selectList={
                              good?.characteristics?.find(
                                (m: { id: number | undefined }) =>
                                  m.id == ch.charcID
                              )?.value
                            }
                            OnSelect={(object) => {
                              updateCharacteristic(ch, [object]);
                            }}
                            maxCount={1}
                            OnDelete={(index) => {
                              OnDeleteVariantCharacteristic(index, ch);
                            }}
                          />
                        ) : // tnved
                        ch.charcID == 15000001 ? (
                          <InputSearch
                            isActive={IsNew === false ? !IsEdit : !IsNew}
                            data={tnved}
                            selectList={
                              good?.characteristics?.find(
                                (m: { id: number | undefined }) =>
                                  m.id == ch.charcID
                              )?.value
                            }
                            OnSelect={(object) => {
                              updateCharacteristic(ch, [object]);
                            }}
                            maxCount={1}
                            OnDelete={(index) => {
                              OnDeleteVariantCharacteristic(index, ch);
                            }}
                          />
                        ) : (
                          <InputText
                            isActive={IsNew === false ? !IsEdit : !IsNew}
                            text={
                              ch.required
                                ? "* "
                                : "" + GetContainsCharacteristic(good, ch)
                            }
                            OnChange={(v) => updateCharacteristic(ch, v)}
                          />
                        )
                      }

                      <div>{ch.unitName}</div>
                    </div>
                  ))}
              </div>
            </Expander>

            <div className={goodsCardCN("images")}>
              <div className="h3">Изображения:</div>
              <div>
                {good?.photos?.map(
                  (res:any, i: number) => (
                    <ImageCard
                      isVisible={IsNew || IsEdit}
                      src={res.big}
                      key={"img" + i}
                      onClick={() => {
                        (IsNew || IsEdit) && deleteFile(i + 1);
                      }}
                    />
                  )
                )}
                {(IsNew || IsEdit) && (
                  <div className={goodsCardCN("new_img")}>
                    <input type="file" id="file" onChange={handleFileChange} />
                    <label htmlFor="file">Добавить файл</label>
                  </div>
                )}
              </div>
            </div>
            {!IsNew && (
              <>
                <div className={goodsCardCN("expanders")}>
                  <Expander title="Закупки">
                    <Table
                      dataGenerate={tablePurchase}
                      headersGenerate={tablePurchaseHeaders}
                    />
                  </Expander>
                  <Expander title="Остатки">
                    <Table
                      dataGenerate={tableRemainder}
                      headersGenerate={tableRemainderHeaders}
                    />
                  </Expander>
                </div>
                <div className={goodsCardCN("chart")}>
                  <div className="h3">Закупки:</div>
                  <BarChart data={chartPurchase} />
                </div>
                <div className={goodsCardCN("chart")}>
                  <div className="h3">Продажи:</div>
                  <BarChart data={chartSales} />
                </div>
              </>
            )}
          </div>
        </div>
        <div className={goodsCardCN("menu_v")}>
          {cardGoods?.map((g) => {
            return (
              <div
                className={
                  g.vendorCode == good?.vendorCode
                    ? goodsCardCN("variants", ["select"])
                    : goodsCardCN("variants")
                }
                key={g.vendorCode}
                onClick={() => {
                  SetGood(g);
                }}
              >
                {g.vendorCode}
              </div>
            );
          })}
          {(IsEdit || IsNew) && (
            <div className={goodsCardCN("variants")} onClick={addVariant}>
              + Добавить
            </div>
          )}
          {IsEdit ? (
            <Button
              image-red
              text="Удалить вариант"
              onClick={() => {
                setIsShowModalDeleteVariant(true);
              }}
            />
          ) : null}
        </div>
      </div>
      <ConfirmationModal
        isOK
        isShowDialog={uploadError}
        setIsShowDialog={setUploadError}
        text="Ошибка загрузки"
        description={`Требования к изображениям:
          - максимум изображений для одного товара (номенклатуры) — 30;
          - минимальное разрешение – 700 × 900 пикселей;
          - максимальный размер — 32 Мб;
          - минимальное качество — 65%;
          - форматы — JPG, PNG, BMP, GIF (статичные), WebP.

          Требования к видео:
          - максимум 1 видео для одного товара (номенклатуры);
          - максимальный размер — 50 Мб;
          - форматы — MOV, MP4.`}
      />
      <ConfirmationModal
        onClickAgree={onDeleteVariant}
        isShowDialog={isShowModalDeleteVariant}
        setIsShowDialog={setIsShowModalDeleteVariant}
        text={
          'Вы уверены, что хотите удалить вариант товара  "' +
          good?.title +
          " (" +
          good?.vendorCode +
          ")" +
          '"?'
        }
      />
      <ConfirmationModal
        onClickAgree={onDelete}
        isShowDialog={isShowModalDelete}
        setIsShowDialog={setIsShowModalDelete}
        text={"Вы уверены, что хотите удалить карточку товара?"}
      />
    </div>
  );
};
