import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import "./assembly-component.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { ReactComponent as Filter } from "../../../../shared/images/svg/filter.svg";

import { FC, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import { headersProduct, products } from "../../../../consts/values/products";
import Table from "../../../../components/table-component/table-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { useNavigate } from "react-router-dom";
import { SideFilterPanel } from "../../../filter-module";
import { AssemblyFilter } from "../assembly-filter-component/assembly-filter-component";


type AssemblyProps = {
  id?: string;
};

export const Assemblies: FC<AssemblyProps> = ({ id }) => {
  const assemblyCN = bemCN("assembles");
  const navigate = useNavigate();
  const onClickRow=(id:number)=>{
    navigate("card/"+id);
  }
  const [isShowFilter, setIsShowFilter] = useState(false);
  return (
    <div id={id} className={assemblyCN()}>
            {isShowFilter ? (
        <SideFilterPanel isShow={isShowFilter} setIsShow={setIsShowFilter}>
          <AssemblyFilter />
        </SideFilterPanel>
      ) : null}
      <div className={assemblyCN("topPanel")}>
        {/* <RadioCalendar/> */}
        <Calendar/>
      </div>
      <div className={assemblyCN("title", ["h1"])}>Сборка заказов</div>
      <div className={assemblyCN("buttons")}>
      <Button text-image-left text="Фильтры" onClick={() => setIsShowFilter(!isShowFilter)} icon={<Filter />} />
        <Button text-image-left text="Добавить сборку" icon={<PlusCircle />} />
        {/* <ExportButton></ExportButton> */}
        <InputText placeholder="Поиск по сборке заказов" icon={<Search />} icon-left/>
      </div>
      <Table onClickRow={onClickRow} data={products} headers={headersProduct} />
    </div>
  );
};
