
import { bemCN } from "../../../configs/bem-classname";
import "./document-modal-component.scss";
import { FC } from "react";

type DocumentModalProps = {};

export const DocumentsModal: FC<DocumentModalProps> = () => {
  const documentsModalCN = bemCN("document-modal");

  return (
    <div className={documentsModalCN()}>
      {[1, 2, 3, 4, 5].map((i) => {
        return (
          <div key={i} className={documentsModalCN("docs-item")}>
            <div></div>
            <a href="#">Документ</a>
          </div>
        );
      })}
    </div>
  );
};
