import { bemCN } from "../../../../configs/bem-classname";
import "./marketplace-check-filter.scss";
import { FC, useState } from "react";
import { ReactComponent as ArrowBottom } from "../../../../shared/images/svg/arrow-bottom.svg";
import { ReactComponent as WBLogo } from "../../../../shared/images/svg/wildberries.svg";
import { ReactComponent as OzonLogo } from "../../../../shared/images/svg/ozon.svg";
import { ReactComponent as KazanLogo } from "../../../../shared/images/svg/kazan.svg";
import { ReactComponent as YandexLogo } from "../../../../shared/images/svg/yandex.svg";

import { Button } from "../../../../ui/button/button";

import Popup from "../../../../ui/popup/popup";
import { RadioGroup } from "../../../../components/radio-group-component/radio-group-component";
import { marketplaceItems } from "../../../../consts/values/marketplace-list";

type MarketplaceCheckFilterProps = {
  //items: string[];
};

export const MarketplaceCheckFilter: FC<MarketplaceCheckFilterProps> = ({  }) => {
  const marketplaceCheckFilterCN = bemCN("marketplaceCheckFilter");
  
  const [isShow, setIsShow] = useState(true);
  const onClick = () => {
    setIsShow(!isShow);
  };

  return (
    <div className={marketplaceCheckFilterCN()}>
      <div className={marketplaceCheckFilterCN("button")}>
        <Button
          onClick={onClick}
          text-image-right
          text="Не выбрано"
          icon={<ArrowBottom />}
        />
        <Popup isShow={isShow} setIsShow={setIsShow}>
          <RadioGroup double-image items={marketplaceItems} icons={[ <OzonLogo/>,<WBLogo/>, <KazanLogo/>, <YandexLogo/>]} />
        </Popup>
      </div>
    </div>
  );
};
