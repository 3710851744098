import { Value } from "react-calendar/dist/cjs/shared/types";
import { bemCN } from "../../configs/bem-classname";
import "./calendar-component.scss";
import { FC, useEffect, useState } from "react";
import ReactCalendar from "react-calendar";
import { ReactComponent as IconCalendar } from "../../shared/images/svg/calendar.svg";
import { ReactComponent as CloseCalendar } from "../../shared/images/svg/close-calendar.svg";
import { ReactComponent as Left } from "../../shared/images/svg/small-arrow-left.svg";
import { ReactComponent as Right } from "../../shared/images/svg/small-arrow-right.svg";
import { calendarSelctedDate } from "../../shared/helpers/date";
import Popup from "../../ui/popup/popup";
import { Button } from "../../ui/button/button";

const calenderCn = bemCN("calendar");
type CalendarProps = {
  ChangeDate?: (date: any[]) => void;
};
const Calendar: FC<CalendarProps> = ({ ChangeDate }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [date, setDate] = useState<Value>([
    new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate()
    ),
    new Date(),
  ]);
  const [isShow, setIsShow] = useState<boolean>(false);

  const options: Intl.DateTimeFormatOptions = {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  };

  const getDate = () => {
    if (!Array.isArray(date)) return;
    ChangeDate && ChangeDate(date);
    return (
      date?.[0]?.toLocaleDateString("de-DE", options) +
      " - " +
      date?.[1]?.toLocaleDateString("de-DE", options)
    );
  };

  const dateChangeHandler = (date: Value) => {
    if (Array.isArray(date)) {
      setDate([date[0], date[1]]);
    } else {
      setDate(date);
    }
  };

  const getCalendarRange = () => {
    if (!Array.isArray(date)) return;

    return (
      calendarSelctedDate(date?.[0]) + " - " + calendarSelctedDate(date?.[1])
    );
  };

  return (
    <div className={calenderCn()}>
      <Button
        onClick={() => setIsShow(!isShow)}
        text-image-left
        text={getDate()}
        icon={<IconCalendar />}
      />

      <Popup isShow={isShow} setIsShow={setIsShow}>
        <div className={calenderCn("wrapper")}>
          <div className={calenderCn("top")}>
            <div className={calenderCn("top_right")}>
              <span>Календарь</span>
              <span className={calenderCn("range")}>{getCalendarRange()}</span>
            </div>

            <button type="button" onClick={() => setIsShow(false)}>
              <CloseCalendar />
            </button>
          </div>
          <ReactCalendar
            tileClassName={(d) => {
              return d.date >= (date as any)[0] && d.date <= (date as any)[1]
                ? "select"
                : "";
            }}
            defaultActiveStartDate={new Date()}
            value={date}
            onChange={dateChangeHandler}
            selectRange={true}
            prev2Label={null}
            prevLabel={<Left />}
            next2Label={null}
            nextLabel={<Right />}
          />
          {isShow && (
            <div className={calenderCn("line")}>
              <button
                type="button"
                onClick={() =>
                  dateChangeHandler([
                    new Date(
                      new Date().getFullYear() - 1,
                      new Date().getMonth(),
                      new Date().getDate()
                    ),
                    new Date(),
                  ])
                }
                className={calenderCn("clear-btn", ["h5"])}
              >
                Cбросить
              </button>
            </div>
          )}
        </div>
      </Popup>
    </div>
  );
};

export default Calendar;
