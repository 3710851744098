import { bemCN } from "../../../../configs/bem-classname";
import "./categoty-filter.scss";
import { FC } from "react";
import { CategoriesFilterButton } from "../category-filter-button-componnt/category-filter-button-componnt";

type CategoriesFilterProps = {
  id?: string;
  categories: any[];
  onChange?(catigory: any, itemIndex: Map<any, any>): void;
};

export const CategoriesFilter: FC<CategoriesFilterProps> = ({
  id,
  categories,
  onChange,
}) => {
  const categoriesFilterCN = bemCN("categoriesFilter");

  return (
    <div id={id} className={categoriesFilterCN()}>
      <div className={categoriesFilterCN("title", ["h2"])}>
        Выберите категорию
      </div>
      <div className={categoriesFilterCN("buttons")}>
        {categories.map((item, index) => (
          <CategoriesFilterButton
            image
            key={index}
            category={item}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};
