import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as Filter } from "../../../../shared/images/svg/filter.svg";

import { Button } from "../../../../ui/button/button";
import "./orders-component.scss";
import { FC, useEffect, useMemo, useState } from "react";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import Table from "../../../../components/table-component/table-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { SideFilterPanel } from "../../../filter-module";

import { useNavigate } from "react-router-dom";
import { API } from "../../../../consts/api";
import Loading from "../../../../components/loading-component/loading-component";
import { CheckItem } from "../../../../ui/check-item/check-item";
import { FilterOrderTableModel } from "../../../../core/api/data-contracts";
import { OrdersFilter } from "../orders-filter-component/orders-filter-component";

type OrdersProps = {
  id?: string;
};
export interface FilterOrder extends FilterOrderTableModel {
  statusAll?: any[];
}
export const Orders: FC<OrdersProps> = ({ id }) => {
  const ordersCN = bemCN("orders");
  const navigate = useNavigate();
  const onClickRow = (id: number) => {
    navigate("card/" + id);
  };
  const [IsLoading, SetIsLoading] = useState(false);
  const [Error, SetError] = useState<Error>();
  const [OrdersList, SetOrdersList] = useState<any[]>([]);
  const [Headers, SetHeaders] = useState<any>();
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [finder, setFinder] = useState<string | undefined>();
  const [date, setDate] = useState<any[]>();
  const [filters, setFilters] = useState<FilterOrder>({});

  useEffect(() => {
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;
    API.orderGetAllItemsList({ finder, start, end })
      .then((res) => {
        if (!Headers) {
          SetHeaders(res.data.nameFields);
          let table = res.data.items;
          table && initFilters(table);
        }
        SetOrdersList([...res.data.items]);
        SetIsLoading(true);
      })
      .catch((e) => {
        SetError(e);
        SetIsLoading(false);
      });
  }, [finder, date]);

  const onClickSort = (header: string, isDescending: boolean) => {
    OrdersList &&
      API.orderSortTableOrderCreate(OrdersList, {
        header,
        isDescending,
      }).then((res) => {
        SetOrdersList(res.data);
      });
  };

  const initFilters = (table: any) => {
    filters.items = table;
    filters.priceSelling = {};

    filters.priceSelling!.min = Math.min(
      ...table.map((t: any) => t.priceSelling)
    );
    filters.priceSelling!.max = Math.max(
      ...table.map((t: any) => t.priceSelling)
    );

    filters.status = Array.from(new Set(table.map((t: any) => t.status)));

    filters.statusAll = Array.from(
      new Set(table.map((t: any) => t.status))
    ).map((e) => {
      return { subjectName: e, select: true };
    });
  };

  const onSaveFilters = (f: FilterOrder) => {
    OrdersList &&
      API.orderFilterTableCreate(f).then((res) => {
        setIsShowFilter(false);
        SetOrdersList([...res.data]);
      });
  };

  const onCancelFilters = () => {
    setIsShowFilter(false);
    let start: string | undefined =
      date && date.length > 1 ? date[0] : undefined;
    let end: string | undefined = date && date.length > 1 ? date[1] : null;
    API.orderGetAllItemsList({ finder, start, end })
      .then((res) => {
        if (!Headers) {
          SetHeaders(res.data.nameFields);
          let table = res.data.items;
          table && initFilters(table);
        }
        SetOrdersList([...res.data.items]);
        SetIsLoading(true);
      })
      .catch((e) => {
        SetError(e);
        SetIsLoading(false);
      });
  };

  const filterOrders = useMemo(() => {
    if (isNew) {
      return OrdersList.filter((r) => r.newOrder);
    } else {
      return OrdersList;
    }
  }, [isNew, OrdersList]);

  if (Error) {
    throw Error;
  }
  if (!IsLoading) {
    return <Loading color="dark" />;
  }

  return (
    <div id={id} className={ordersCN()}>
      {isShowFilter ? (
        <SideFilterPanel
          isShow={isShowFilter}
          setIsShow={setIsShowFilter}
          onSave={() => onSaveFilters(filters)}
          onCancel={onCancelFilters}
        >
          <OrdersFilter filters={filters} onChange={setFilters} />
        </SideFilterPanel>
      ) : null}
      <div className={ordersCN("topPanel")}>
        {/* <RadioCalendar /> */}
        <Calendar ChangeDate={setDate} />
      </div>
      <div className={ordersCN("title", ["h1"])}>Заказы</div>
      <div className={ordersCN("buttons")}>
        <Button
          text-image-left
          text="Фильтры"
          onClick={() => setIsShowFilter(!isShowFilter)}
          icon={<Filter />}
        />
        {/* <ExportButton></ExportButton> */}
        <CheckItem text="Только новые" onActive={setIsNew} />
        <InputText
          placeholder="Поиск по заказам"
          OnChange={setFinder}
          icon={<Search />}
          icon-left
        />
      </div>
      <Table
        isSortable
        onClickSort={onClickSort}
        dataGenerate={filterOrders}
        headersGenerate={Headers}
        onClickRow={onClickRow}
      />
    </div>
  );
};
