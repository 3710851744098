import React, { Component, FC, useEffect, useState } from 'react';
import './captcha.scss';
import { bemCN } from '../../configs/bem-classname';
const captcha = require('react-simple-captcha');

const captchaCn = bemCN('captcha');

interface CaptchaProps {
  successCaptcha: () => void;
}

export const CaptchaTest: FC<CaptchaProps> = ({ successCaptcha }) => {
  const [isError, setIsError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isShowMessage, setIsShowMessage] = useState(false);
  const [messageText, setMessageText] = useState('');

  useEffect(() => {
    captcha.loadCaptchaEnginge(6);
  }, [1]);

  const doSubmit = () => {
    let user_captcha = (document?.getElementById('user_captcha_input') as HTMLInputElement).value;
    if (captcha.validateCaptcha(user_captcha) === true) {
      successCaptcha();
      setMessageText('Ваше сообщение успешно отправлено.');
      setIsError(false);
      setIsShowMessage(true);
      setIsDisabled(false);
      captcha.loadCaptchaEnginge(6);
      (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
    } else {
      setMessageText('Значение введено неверно. Повторите попытку.');
      setIsError(true);
      setIsShowMessage(true);
      (document.getElementById('user_captcha_input') as HTMLInputElement).value = '';
    }
  };

  return (
    <div id="captchaId" className={captchaCn()}>
      <captcha.LoadCanvasTemplate />
      {isShowMessage && (
        <div className={captchaCn('error', { isRed: isError, isGreen: !isError })}>
          {messageText}
        </div>
      )}
      <div className={captchaCn('f-input')}>
        <label htmlFor="name">Введите значение с изображения</label>
        <input
          id="user_captcha_input"
          name="user_captcha_input"
          type="text"
          placeholder="Введите значение с изображения"
        />
      </div>

      {isDisabled ? (
        <button onClick={() => doSubmit()}>Отправить</button>
      ) : (
        <button disabled onClick={() => doSubmit()}>
          Отправить
        </button>
      )}
    </div>
  );
};
