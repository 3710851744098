import { useEffect, useState } from "react";
import "./purchases-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as Clip } from "../../../../shared/images/svg/clip.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import Table from "../../../../components/table-component/table-component";
import { Modal } from "../../../../components/modal-component/modal-component";
import { DocumentsModal } from "../../../../components/modal-component/document-modal-component/document-modal-component";
import { ChechDataModal } from "../../../../components/modal-component/check-data-modal-component copy/check-data-modal-component copy";
import { API } from "../../../../consts/api";
import { UpdateObject } from "../../../../shared/helpers/update-object";
import Selected from "../../../../components/selected-component/selected-component";
import { SelectItem } from "../../../../core/view-models/select-item";
import { ReactComponent as Delete } from "../../../../shared/images/svg/delete.svg";
import { ReactComponent as TrashBin } from "../../../../shared/images/svg/trash-bin.svg";
import { ConfirmationModal } from "../../../../components/modal-component/confirmation-modal/confirmation-modal";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { TableDataSearchItem } from "../../../../core/view-models/table-search-item";
import HeaderCard from "../../../../components/header-card-component/header-card-component";
import InputTime from "../../../../ui/input-time/input-time";
import { PurchaseStatus } from "../../../../core/api/data-contracts";

const purchasesCardCN = bemCN("purchasesCard");

export const PurchasesCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isShowDocumentDialog, setIsShowDocumentDialog] = useState(false);
  const [isShowCheckDataDialog, setIsShowCheckDataDialog] = useState(false);
  const [expenseType, setExpenseType] = useState<any[]>([]);
  const [isAddingExpense, setIsAddingExpense] = useState(true); //Показывает, можно ли добавить еще один вид расходов
  const [Purchase, SetPurchase] = useState(
    location.state && location.state.purchase
      ? location.state.purchase
      : {
          id: 0,
          number: Number(Date.now().toString().slice(-9)),
          dateTime: new Date().toLocaleString("de-DE", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          }),
          totalPriceSelling: 0,
          totalCostPrice: 0,
          purchaseStatusId: 1,
          purchaseStatus: "",
          authorDocumentId: 1,
          authorDocument: "",
          providerId: 0,
          provider: "",
          storeId: 0,
          store: "",
          closed: false,
          ourOrganizationId: 0,
          ourOrganization: "",
          deliveryTypeId: 0,
          deliveryType: "",
          numberTransportDocument: "",
          dateComingStore: "",
          daysComingStore: "",
          numberAccountProvider: "",
          dateAccountProvider: "",
          paymentMethodId: 0,
          paymentMethod: "",
          vatId: 1,
          vat: "",
          vatValue: 0,
          comment: "",
          transportCompanyId: null,
          transportCompany: "",
          goods: [],
          expenses: [],
        }
  );
  const [IsNew, SetIsNew] = useState(
    location.state && location.state.purchase ? false : true
  );
  const [IsEdit, SetIsEdit] = useState(IsNew);
  const [SumResult, SetSumResult] = useState(
    Purchase.goods.reduce((sum: any, b: any) => sum + Number(b.priceVAT), 0)
  );
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [isShowModalUpdate, setIsShowModalClose] = useState(false);

  const [ContractorsList, SetContractorsList] = useState<any[]>([]);
  const [StocksList, SetStocksList] = useState<any[]>([]);
  const [DeliveryList, SetDeliveryList] = useState<any[]>([]);
  const [PaymentMethodList, SetPaymentMethodList] = useState<any[]>([]);
  const [VatValueList, SetVatValueList] = useState<any[]>([]);
  const [TransportCompanyList, SetTransportCompanyList] = useState<any[]>([]);
  const [Goods, SetGoods] = useState<any[]>([]);
  const [purchaseStatuses, setPurchaseStatuses] = useState<PurchaseStatus[]>();
  const [ourStocks, setOurStocks] = useState<any[]>([]);
  //Выбор строки таблицы товаров
  const [IsUpdate, SetIsUpdate] = useState<boolean>(false);
  const [errorDelete, setErrorDelete] = useState("");
  const [isShowErrorDelete, setIsShowErrorDelete] = useState(false);
  useEffect(() => {
    SetSumResult(
      Purchase.goods.reduce((sum: any, b: any) => sum + Number(b.priceVAT), 0)
    );
  }, [Purchase]);
  useEffect(() => {
    if (IsUpdate) {
      API.purchaseUpdateCostPriceCreate(Purchase)
        .then((res) => {
          SetPurchase(res.data);
        })
        .catch((e) => console.log(e));
      SetIsUpdate(false);
    }
  }, [IsUpdate]);

  useEffect(() => {
    if (Purchase.ourOrganizationId > 0 && ContractorsList.length > 0) {
      let stackId = ContractorsList.find(
        (c: any) => c.id === Purchase.ourOrganizationId
      ).storesId;

      let sts = StocksList?.filter((s: any) => stackId?.includes(s.id));
      setOurStocks(sts);
    }
  }, [ContractorsList, Purchase.ourOrganizationId, StocksList]);

  const onSelect = (index: number, object: any) => {
    let goods = Purchase.goods;
    if (index === -1) {
      let newG: any[] = Purchase.goods;
      newG.push(object);
      SetPurchase({ ...Purchase, goods: newG });
    } else {
      goods.map((e: any) => {
        if (e.goodId === index) {
          e.goodId = object.id;
          e.name = object.name;
        }
      });

      SetPurchase({ ...Purchase, goods: goods });
    }
  };

  //Изменение суммы расходов
  const updatePriceExpense = (id: number, value: string | number) => {
    if (value === "") {
      value = 0;
    }
    let expenses = Purchase.expenses;
    let updateExpense = expenses.find((e: any) => e.expenseId === id);
    updateExpense.price = Number(value);
    let newExpenses = expenses.map((e: any) => {
      if (e.expenseId !== id) return e;
      else return updateExpense;
    });

    SetPurchase({ ...Purchase, expenses: newExpenses });

    API.purchaseUpdateCostPriceCreate(Purchase).then((res) => {
      let newGoods = res.data.goods;
      SetPurchase({ ...Purchase, goods: newGoods });
    });
  };

  //Добавление расходов
  const addExpense = () => {
    let expenses = Purchase.expenses;
    let exs = expenseType.filter(
      (e: any) => !expenses.map((ex: any) => ex.expenseId).includes(e.id)
    );
    if (exs.length > 0) {
      let newId = Math.max(...exs.map((e: any) => e.id));
      expenses.push({
        expenseId: newId,
        name: expenseType.find((e: any) => e.id === newId).name,
        price: 0,
      });
      SetPurchase({ ...Purchase, expenses: expenses });
      exs.length === 1 && setIsAddingExpense(false);
    }
    SetIsUpdate(true);
  };

  //Удаление расходов
  const deleteExpense = (id: number) => {
    let expenses = Purchase.expenses;
    expenses = expenses.filter((e: any) => e.expenseId !== id);
    SetPurchase({ ...Purchase, expenses: expenses });

    API.purchaseUpdateCostPriceCreate({ ...Purchase, expenses: expenses }).then(
      (res) => {
        let newGoods = res.data.goods;
        SetPurchase({ ...Purchase, goods: newGoods, expenses: expenses });
      }
    );

    setIsAddingExpense(true);
    SetIsUpdate(true);
  };

  //Изменение названия расходов
  const updateNameExpense = (id: number, value: number) => {
    let expenses = Purchase.expenses;
    let updateExpense = expenses.find((e: any) => e.expenseId === id);
    updateExpense.expenseId = value;
    updateExpense.name = expenseType.find((e: any) => e.id === value).name;
    let newExpenses = expenses.map((e: any) => {
      if (e.expenseId !== id) return e;
      else return updateExpense;
    });
    SetPurchase({ ...Purchase, expenses: newExpenses });
    SetIsUpdate(true);
  };

  //Назад
  const OnBack = () => {
    navigate("/procurement/purchase/");
  };

  //Удаление закупки
  const onDelete = () => {
    API.purchaseDelete(Purchase.id)
      .then(() => {
        navigate("/procurement/purchase/");
      })
      .catch((e) => {
        setErrorDelete(e.response.data);
        setIsShowModalDelete(false);
        setIsShowErrorDelete(true);
      });
  };
  //Обновление (сохранение изменений) закупки
  const onUpdate = () => {
    API.purchaseUpdateUpdate(Purchase).then((res) => {
      SetIsEdit(false);
      setIsShowModalClose(false);
    });
  };

  const onClose = () => {
    API.purchaseCloseUpdate({ ...Purchase, closed: true }).then(() => {
      SetIsEdit(false);
      SetPurchase({ ...Purchase, closed: true });
      setIsShowModalClose(false);
    });
  };

  //Добавление (сохранение в базу) закупки
  const onAdd = () => {
    API.purchaseAddCreate(Purchase).then((res) => {
      SetPurchase(res.data);
      SetIsEdit(false);
      SetIsNew(false);
    });
  };
  const onAddRow = (object: any) => {
    let newG: any[] = Purchase.goods;
    object.costPriceOneVAT = 0;
    object.priceOneVAT = 0;
    object.priceVAT = 0;
    newG.push(object);
    SetPurchase({ ...Purchase, goods: newG });
    SetIsUpdate(true);
  };

  //Изменение строки товаров
  const onUpdateRow = (index: number, object: any) => {
    let newO = Purchase.goods;
    newO[index] = object;
    SetPurchase({ ...Purchase, goods: newO });
    SetIsUpdate(true);
  };

  //Удаление строки товаров
  const onDelRow = (index: number) => {
    let newO = Purchase.goods;
    newO.splice(index, 1);
    SetPurchase({ ...Purchase, goods: newO });
    SetIsUpdate(true);
  };

  //Загрузка доп. информации
  useEffect(() => {
    API.goodGetAllItemsList().then((res) => {
      let goods = res.data.items;
      goods.map((g: any) => {
        g.goodId = g.id;
      });
      SetGoods([...goods]);
    });
  }, []);
  useEffect(() => {
    API.transportCompanyGetAllItemsList().then((res) => {
      SetTransportCompanyList([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    API.purchaseStatusGetAllItemsList().then((res) => {
      setPurchaseStatuses([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    API.transportCompanyGetAllItemsList().then((res) => {
      SetTransportCompanyList([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    API.expenseGetAllItemsList().then((res) => {
      setExpenseType(res.data.items);
    });
  }, []);
  useEffect(() => {
    API.deliveryTypeGetAllItemsList().then((res) => {
      SetDeliveryList([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    API.vatGetAllItemsList().then((res) => {
      SetVatValueList([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    API.paymentMethodGetAllItemsList().then((res) => {
      SetPaymentMethodList([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    API.contractorGetAllItemsList().then((res) => {
      SetContractorsList([...res.data.items]);
    });
  }, []);
  useEffect(() => {
    API.storeGetAllItemsList().then((res) => {
      SetStocksList([...res.data.items]);
    });
  }, []);

  return (
    <div className={purchasesCardCN()}>
      <HeaderCard
        toBack={() => {
          if (IsEdit && !IsNew) {
            API.purchaseDetail(Purchase.id).then((res) => {
              SetPurchase(res.data);
              SetIsEdit(false);
            });
            SetIsEdit(false);
          } else {
            navigate("/procurement/purchase");
          }
        }}
        isEdit={IsEdit}
        isNew={IsNew}
        titleText="Оформление закупки"
        setIsEdit={SetIsEdit}
        number={Purchase.number}
        date={Purchase.dateTime}
        statuses={purchaseStatuses?purchaseStatuses:[]}
        currentStatusId={Purchase.purchaseStatusId}
        currentStatus={Purchase.purchaseStatus}
        currentWorker={Purchase.authorDocument}
        updateNubmber={(v: number) => {
          SetPurchase({ ...Purchase, number: v });
        }}
        updateDate={(v: string) => {
          SetPurchase({ ...Purchase, dateTime: v });
        }}
        updateStatus={(v: number) => {
          SetPurchase({
            ...Purchase,
            purchaseStatusId: v,
            purchaseStatus: purchaseStatuses&&purchaseStatuses.find((s: any) => s.id === v)?.name,
          });
        }}
        clickHistory={() => {}}
      />
      <div className={purchasesCardCN("info")}>
        <div className={purchasesCardCN("blue")}>
          {Purchase && !IsEdit && !IsNew ? (
            <InputText
              header="Поставщик:"
              text={Purchase ? Purchase.provider : ""}
              isActive
              isRequired
            />
          ) : (
            <div className={purchasesCardCN("popap")}>
              <p className="h4">
                <span className={purchasesCardCN("required")}>* </span>
                Поставщик:
              </p>
              <div>
                <Selected
                  active={Purchase ? Purchase.providerId : -1}
                  items={ContractorsList.map((item) => {
                    return new SelectItem(item.id, item.name);
                  })}
                  OnChange={(value) => {
                    const select = ContractorsList.find(
                      (item) => item.id === value
                    );
                    SetPurchase(
                      UpdateObject(Purchase, "providerId", select.id)
                    );
                    SetPurchase(
                      UpdateObject(
                        Purchase,
                        "provider",
                        ContractorsList.find(({ id }) => id === select.id).name
                      )
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {Purchase && !IsEdit && !IsNew ? (
            <InputText
              header="Наша организация:"
              text={
                Purchase && Purchase.ourOrganization
                  ? Purchase.ourOrganization
                  : ""
              }
              isActive
              isRequired
            />
          ) : (
            <div className={purchasesCardCN("popap")}>
              <p className="h4">
                <span className={purchasesCardCN("required")}>* </span>Наша
                организация:
              </p>
              <div>
                <Selected
                  active={Purchase ? Purchase.ourOrganizationId : -1}
                  items={ContractorsList.map((item) => {
                    return new SelectItem(item.id, item.name);
                  })}
                  OnChange={(value) => {
                    const select = ContractorsList.find(
                      (item) => item.id === value
                    );

                    SetPurchase({
                      ...Purchase,
                      ourOrganizationId: select.id,
                      ourOrganization: ContractorsList.find(
                        ({ id }) => id === select.id
                      ).name,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {Purchase && !IsEdit && !IsNew ? (
            <InputText
              header="Склад:"
              text={Purchase ? Purchase.store : ""}
              isActive
              isRequired
            />
          ) : (
            <div className={purchasesCardCN("popap")}>
              <p className="h4">
                <span className={purchasesCardCN("required")}>* </span>Склад:
              </p>
              <div>
                <Selected
                  active={Purchase ? Purchase.storeId : -1}
                  items={ourStocks.map((item) => {
                    return new SelectItem(item.id, item.name);
                  })}
                  OnChange={(value) => {
                    const select = StocksList.find((item) => item.id === value);
                    SetPurchase(UpdateObject(Purchase, "storeId", select.id));
                    SetPurchase(
                      UpdateObject(
                        Purchase,
                        "store",
                        StocksList.find(({ id }) => id === select.id).name
                      )
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>

        <div>
          {Purchase && !IsEdit && !IsNew ? (
            <InputText
              header="Условие доставки:"
              text={
                Purchase && Purchase.deliveryType ? Purchase.deliveryType : ""
              }
              isActive
              isRequired
            />
          ) : (
            <div className={purchasesCardCN("popap")}>
              <p className="h4">
                <span className={purchasesCardCN("required")}>* </span>Условие
                доставки:
              </p>
              <div>
                <Selected
                  active={Purchase ? Purchase.deliveryTypeId : -1}
                  items={DeliveryList.map((item) => {
                    return new SelectItem(item.id, item.name);
                  })}
                  OnChange={(value) => {
                    const select = DeliveryList.find(
                      (item) => item.id === value
                    );
                    SetPurchase(
                      UpdateObject(Purchase, "deliveryTypeId", select.id)
                    );
                    SetPurchase(
                      UpdateObject(
                        Purchase,
                        "deliveryType",
                        DeliveryList.find(({ id }) => id === select.id).name
                      )
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          <InputText
            header="№ транспортной накладной:"
            text={
              Purchase && Purchase.numberTransportDocument
                ? Purchase.numberTransportDocument
                : ""
            }
            isActive={IsNew === false ? !IsEdit : !IsNew}
            isRequired
            OnChange={(v) => {
              SetPurchase(UpdateObject(Purchase, "numberTransportDocument", v));
            }}
          />
        </div>
        <div>
          <InputTime
            border-all
            isActive={IsNew === false ? !IsEdit : !IsNew}
            header="Дата прихода на склад:"
            date={
              Purchase && Purchase.dateComingStore
                ? Purchase.dateComingStore
                : ""
            }
            onChange={(v) => {
              SetPurchase(UpdateObject(Purchase, "dateComingStore", v));
            }}
          />
        </div>
        <div>
          <InputText
            header="№ счета поставщика:"
            isRequired
            text={
              Purchase && Purchase.numberAccountProvider
                ? Purchase.numberAccountProvider
                : ""
            }
            isActive={IsNew === false ? !IsEdit : !IsNew}
            OnChange={(v) => {
              SetPurchase(UpdateObject(Purchase, "numberAccountProvider", v));
            }}
          />
        </div>
        <div>
          <InputTime
            border-all
            isActive={IsNew === false ? !IsEdit : !IsNew}
            header="Дата счета:"
            date={
              Purchase && Purchase.dateAccountProvider
                ? Purchase.dateAccountProvider
                : ""
            }
            onChange={(v) => {
              SetPurchase(UpdateObject(Purchase, "dateAccountProvider", v));
            }}
          />
        </div>
        <div>
          {Purchase && !IsEdit && !IsNew ? (
            <InputText
              header="Способ оплаты:"
              text={
                Purchase && Purchase.paymentMethod ? Purchase.paymentMethod : ""
              }
              isActive
              isRequired
            />
          ) : (
            <div className={purchasesCardCN("popap")}>
              <p className="h4">
                <span className={purchasesCardCN("required")}>* </span>Способ
                оплаты:
              </p>
              <div>
                <Selected
                  active={Purchase ? Purchase.paymentMethodId : -1}
                  items={PaymentMethodList.map((item) => {
                    return new SelectItem(item.id, item.name);
                  })}
                  OnChange={(value) => {
                    const select = PaymentMethodList.find(
                      (item) => item.id === value
                    );
                    SetPurchase(
                      UpdateObject(Purchase, "paymentMethodId", select.id)
                    );
                    SetPurchase(
                      UpdateObject(
                        Purchase,
                        "paymentMethod",
                        PaymentMethodList.find(({ id }) => id === select.id)
                          .name
                      )
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div>
          {Purchase && !IsEdit && !IsNew ? (
            <InputText
              header="НДС:"
              text={Purchase && Purchase.vat ? Purchase.vat : ""}
              isActive
            />
          ) : (
            <div className={purchasesCardCN("popap")}>
              <p className="h4">НДС:</p>
              <div>
                <Selected
                  active={Purchase ? Purchase.vatId : -1}
                  items={VatValueList.map((item) => {
                    return new SelectItem(item.id, item.name);
                  })}
                  OnChange={(value) => {
                    const select = VatValueList.find(
                      (item) => item.id === value
                    );
                    SetPurchase(UpdateObject(Purchase, "vatId", select.id));
                    SetPurchase(UpdateObject(Purchase, "vat", select.name));
                    SetPurchase(
                      UpdateObject(Purchase, "vatValue", select.value)
                    );
                    SetIsUpdate(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={purchasesCardCN("comment")}>
          <InputText
            header="Комментарий:"
            text={Purchase && Purchase.comment ? Purchase.comment : ""}
            isActive={IsNew === false ? !IsEdit : !IsNew}
            placeholder="Напишите комментарий"
            OnChange={(v) => {
              SetPurchase(UpdateObject(Purchase, "comment", v));
            }}
          />
        </div>
        <div>
          {Purchase && !IsEdit && !IsNew ? (
            <InputText
              isRequired
              header="Транспортная компания:"
              text={
                Purchase && Purchase.transportCompany
                  ? Purchase.transportCompany
                  : ""
              }
              isActive
            />
          ) : (
            <div className={purchasesCardCN("popap")}>
              <p className="h4">
                <span className={purchasesCardCN("required")}>* </span>
                Транспортная компания:
              </p>
              <div>
                <Selected
                  active={Purchase ? Purchase.transportCompanyId : -1}
                  items={TransportCompanyList.map((item) => {
                    return new SelectItem(item.id, item.name);
                  })}
                  OnChange={(value) => {
                    const select = TransportCompanyList.find(
                      (item) => item.id === value
                    );
                    SetPurchase(
                      UpdateObject(Purchase, "transportCompanyId", select.id)
                    );
                    SetPurchase(
                      UpdateObject(
                        Purchase,
                        "transportCompany",
                        TransportCompanyList.find(({ id }) => id === select.id)
                          .name
                      )
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={purchasesCardCN("title", ["h3"])}>Расходы</div>
      {Purchase && Purchase.expenses && Purchase.expenses.length > 0
        ? Purchase.expenses.map((item: any) => {
            return (
              <div key={item.expenseId}>
                <div className={purchasesCardCN("info", ["expen"])}>
                  <div>
                    <InputText
                      header="Сумма:"
                      text={item ? item.price : ""}
                      isActive={!IsEdit && !IsNew}
                      isDigitOnly
                      OnChange={(v) => updatePriceExpense(item.expenseId, v)}
                    />
                  </div>
                  <div>
                    {(Purchase && IsEdit) || IsNew ? (
                      <>
                        {" "}
                        <div className={purchasesCardCN("popap")}>
                          <p className="h4">Расходы:</p>
                          <Selected
                            OnChange={(v) => {
                              updateNameExpense(item.expenseId, v);
                            }}
                            active={item.expenseId}
                            items={expenseType
                              .filter(
                                (expense: any) =>
                                  expense.id === item.expenseId ||
                                  !(
                                    Purchase.expenses.filter(
                                      (e: any) => e.expenseId === expense.id
                                    ).length > 0
                                  )
                              )
                              .map((exp: any) => {
                                return new SelectItem(exp.id, exp.name);
                              })}
                          />
                        </div>
                        <button
                          className="del"
                          onClick={() => deleteExpense(item.expenseId)}
                        >
                          <Delete />
                        </button>
                      </>
                    ) : (
                      <InputText
                        header="Расходы:"
                        text={item ? item.name : ""}
                        isActive={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            );
          })
        : null}
      {IsEdit || (IsNew && isAddingExpense) ? (
        <div className={purchasesCardCN("add-expense-btn")}>
          <Plus />
          <Button white text="Добавить расходы" onClick={() => addExpense()} />
        </div>
      ) : null}
      <div></div>
      <div className={purchasesCardCN("table_head")}>
        <div>
          <span className={purchasesCardCN("table_head_active", ["h3"])}>
            Наименования
          </span>
          <span className={purchasesCardCN("table_head_count", ["h3"])}>
            {Purchase && Purchase.goods.length > 0 ? Purchase.goods.length : 0}
          </span>
        </div>
        <div className={purchasesCardCN("table_head_buttons")}>
          {/* <Button id="add" text="+" circle />
          <Button
            text="Прикрепить"
            onClick={() => {
              setIsShowDocumentDialog(true);
            }}
            icon={<Clip />}
            text-image-left
          /> */}
          <p className="h2">Итого: {SumResult}</p>
        </div>
      </div>
      <Table
        isNames
        isEditable={IsEdit || IsNew}
        headersGenerate={location.state.headers}
        dataGenerate={Purchase.goods}
        excludeIndexes={[1, 3, 5]}
        isAdding={IsEdit || IsNew}
        onAddRow={onAddRow}
        onDelRow={onDelRow}
        onUpdateRow={onUpdateRow}
        searchData={[new TableDataSearchItem(0, "name", Goods)]}
        nameSelectSearch="goodId"
        onSelect={onSelect}
      />
      {/* <div className={purchasesCardCN("buttons")}>
        <Button
          text="В оплату"
          onClick={() => {
            setIsShowCheckDataDialog(true);
          }}
          blue
        />
        <Button text={"Оплачен"} blue disabled />
        <Button text={"Отгружен"} blue disabled />
        <Button text={"Создать приемку"} blue disabled />
      </div> */}
      <div className={purchasesCardCN("buttons")}>
        <Button text="Закрыть" white-outline onClick={OnBack} />
        {IsEdit || IsNew ? (
          <Button onClick={IsNew ? onAdd : onUpdate} text={"Сохранить"} blue />
        ) : (
          Purchase.closed == false && (
            <Button
              onClick={() => SetIsEdit(true)}
              text={"Редактировать"}
              blue
            />
          )
        )}
        {IsEdit && Purchase.id != 0 && (
          <Button
            text="Завершить закупку"
            blue
            onClick={() => setIsShowModalClose(true)}
          />
        )}
        {
          <Button
            image-red
            icon={<TrashBin />}
            onClick={() => {
              setIsShowModalDelete(true);
            }}
          />
        }
      </div>
      <Modal
        title="Прикрепленные документы"
        isShowDialog={isShowDocumentDialog}
        setIsShowDialog={setIsShowDocumentDialog}
      >
        <DocumentsModal />
      </Modal>
      <Modal
        title="Проверьте данные"
        isShowDialog={isShowCheckDataDialog}
        setIsShowDialog={setIsShowCheckDataDialog}
      >
        <ChechDataModal setIsShow={setIsShowCheckDataDialog} />
      </Modal>
      <ConfirmationModal
        onClickAgree={onDelete}
        isShowDialog={isShowModalDelete}
        setIsShowDialog={setIsShowModalDelete}
        text={
          'Вы уверены, что хотите удалить закупку №"' + Purchase?.number + '"?'
        }
      />
      <ConfirmationModal
        onClickAgree={onClose}
        isShowDialog={isShowModalUpdate}
        setIsShowDialog={setIsShowModalClose}
        text={
          "Вы уверены, что хотите завершить закупку № " +
          Purchase?.number +
          " от " +
          Purchase?.dateTime +
          "? После завершения закупки изменения будут недоступны."
        }
      />
      <ConfirmationModal
        isOK
        isShowDialog={isShowErrorDelete}
        setIsShowDialog={setIsShowErrorDelete}
        text={errorDelete && errorDelete}
      />
    </div>
  );
};
