import { useEffect, useState } from "react";
import { bemCN } from "../../configs/bem-classname";
import "./check-item.scss";
import { ReactComponent as Mark } from "./../../shared/images/svg/check-mark.svg";
import { LabelListProps } from "recharts";

const checkCN = bemCN("check");

type CheckItemProps = {
  id?: string;
  round?: boolean;
  Active?: boolean;
  onActive?: (isActive: boolean) => void;
  text?: string;
  children?:any;
};

export const CheckItem = (props: CheckItemProps) => {
  let { id, round, text,children, onActive, Active = false } = props;
  const itemClass = round ? "round" : "";
  const [active, SetActive] = useState(Active);
  return (
    <div
      onClick={() => {
        SetActive(!active);
        onActive && onActive(!active);
      }}
      className={checkCN()}
    >
      <div
        className={
          active
            ? checkCN("active", [itemClass])
            : checkCN("item", [itemClass])
        }
      >
        {active && <Mark />}
      </div>
        <div>{children?children: <p className="h4">{text}</p>}</div>
    </div>
  );
};
