import { bemCN } from "../../../../configs/bem-classname";
import { Button } from "../../../../ui/button/button";
import "./shipments-component.scss";
import { ReactComponent as PlusCircle } from "../../../../shared/images/svg/circle-plus.svg";
import { ReactComponent as Filter } from "../../../../shared/images/svg/filter.svg";

import { FC, useState } from "react";
import ExportButton from "../../../../components/export-component/export-button/export-button";
import { InputText } from "../../../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";
import { headersProduct, products } from "../../../../consts/values/products";
import Table from "../../../../components/table-component/table-component";
import RadioCalendar from "../../../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../../../components/calendar-component/calendar-component";
import { useNavigate } from "react-router-dom";
import { SideFilterPanel } from "../../../filter-module";
import { ShipmentFilter } from "../shipment-filter-component/shipment-filter-component";

type ShipmentProps = {
  id?: string;
};

export const Shipments: FC<ShipmentProps> = ({ id }) => {
  const shipmentsCN = bemCN("shipments");
  const navigate = useNavigate();
  const onClickRow = (id: number) => {
    navigate("/sales/shipment/card/" + id);
  };
  const [isShowFilter, setIsShowFilter] = useState(false);
  return (
    <div id={id} className={shipmentsCN()}>
      {isShowFilter ? (
        <SideFilterPanel isShow={isShowFilter} setIsShow={setIsShowFilter}>
          <ShipmentFilter />
        </SideFilterPanel>
      ) : null}
      <div className={shipmentsCN("topPanel")}>
        {/* <RadioCalendar /> */}
        <Calendar />
      </div>
      <div className={shipmentsCN("title", ["h1"])}>Отгрузки</div>
      <div className={shipmentsCN("buttons")}>
        <Button
          text-image-left
          text="Фильтры"
          onClick={() => setIsShowFilter(!isShowFilter)}
          icon={<Filter />}
        />
        <Button
          text-image-left
          text="Добавить отгрузку "
          icon={<PlusCircle />}
        />
        {/* <ExportButton></ExportButton> */}
        <InputText
          placeholder="Поиск по отгрузкам"
          icon={<Search />}
          icon-left
        />
      </div>
      <Table data={products} headers={headersProduct} onClickRow={onClickRow} />
    </div>
  );
};
