import { Product } from "../../core/entities/product";

export const products = [
  new Product(0, "Кофта красная с длинным рукавом в горошек с v-образным вырезом хлопок", undefined, 2300, "Одежра", "GJ Store", "GJ", 4.5),
  new Product(1, "Шорты"),
  new Product(2, "Шляпа", undefined, 900),
  new Product(3, "Кеды", undefined, 4800),
  new Product(4, "Тетрадь",undefined, 56),
  new Product(5, "Игрушка мягкая лев Алекс Мадагаскар наполнение натуральный мех глаза пластиковые", undefined, 50),
  new Product(6, "Набор ручек"),
  new Product(7, "Лента", undefined, 24),
  new Product(8, "Игрушка для собак", undefined, 789),
];

export const headersProduct=[
    "Названия",
    "Стоимость",
    "Категория",
    "Продавец",
    "Бренд",
    "Рейтинг",
]