import { useMemo, useState } from "react";
import "./table-search-item.scss";
import { bemCN } from "../../../configs/bem-classname";
import { TableDataSearchItem } from "../../../core/view-models/table-search-item";
import Popup from "../../../ui/popup/popup";
import { FilterGoodsContains } from "../../../shared/helpers/filter-goods-contains";

const tableItemCN = bemCN("table-item");

export const availableTableItems = ["select"] as const;

type TableEditableItemProps = {
  [typeItem in (typeof availableTableItems)[number]]?: boolean;
} & {
  id?: number;
  isTableAllData?: boolean;
  data?: TableDataSearchItem;
  exceptionsData?: any[]|null;
  isActive: boolean;
  OnSelect?: (index: number, oblect: any) => void;
};
export const TableSearchItem = (props: TableEditableItemProps) => {
  let { id, data, isActive, exceptionsData, isTableAllData, OnSelect } = props;
  const [valueInput, setValueInput] = useState(() => {
    return id && data ? data.data.find((d) => d.goodId == id).name : "";
  });

  const [isShow, setIsShow] = useState(false);
  const onClick = () => {
    setIsShow(true);
  };
  const onSelect = (object: any) => {
    setValueInput(object.name);
    OnSelect && OnSelect(id ? id : -1, object);
  };
  const filterData = useMemo(() => {
    return (
      data &&
      data.data
        .filter((f) => {
          return !FilterGoodsContains(f, exceptionsData) || f.goodId === id;
        })
        .filter(
          (d) =>
            d.name.toLowerCase().includes(valueInput.toLowerCase()) ||
            d.codeVendor.toLowerCase().includes(valueInput.toLowerCase())
        )
    );
  }, [valueInput, data]);
  return (
    <td
      id={id?.toString()}
      title={data?.toString()}
      className={tableItemCN("", ["h5"])}
    >
      <input
        onClick={onClick}
        className={tableItemCN("input")}
        value={valueInput}
        type="text"
        onChange={(e) => {
          setValueInput(e.target.value);
        }}
        readOnly={!isActive}
      />
      {isShow && (
        <Popup isShow={isShow} setIsShow={setIsShow}>
          {isTableAllData
            ? filterData &&
              filterData.length > 0 && (
                <table className={tableItemCN("table_search")}>
                  <thead>
                    <tr>
                      <th>Название</th>
                      <th>Артикул</th>
                      <th>Остаток</th>
                      <th>Себестоимость</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterData.map((g) => {
                      return (
                        <tr
                          key={g.codeVendor}
                          onClick={() => {
                            setIsShow(false);
                            onSelect(g);
                          }}
                          className="h4"
                        >
                          <td>{g.name}</td>
                          <td>{g.codeVendor}</td>
                          <td>{g.count}</td>
                          <td>{g.costPriceOneVAT}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )
            : filterData &&
              filterData.map((g) => {
                return (
                  <div
                    key={g.codeVendor}
                    onClick={() => {
                      setIsShow(false);
                      onSelect(g);
                    }}
                    className="h4"
                  >
                    <div>{g.name}</div>
                    <div>{g.codeVendor}</div>
                  </div>
                );
              })}
        </Popup>
      )}
    </td>
  );
};
