import { bemCN } from "../../configs/bem-classname";
import { MovingCard } from "../../modules/movings-module";
import "./moving-card-page.scss"
const rootCN = bemCN("movingsCard");

export const MovingsCardPage = () => {
  return (
    <div className={rootCN()}>
      <MovingCard />
    </div>
  );
};
