import { links } from './links'; 
export const navLinks = [
    {
        title:"Главная",
        path:links.crmMain
    },
    {
        title:"Закупки",
        children:[
            
            {
                title:"Закупки",
                path:links.crmProcurement + links.crmPurchase,
            },
            {
                title: "Приемки",
                path: links.crmProcurement + links.crmAcceptance
            },
            {
                title:"Перемещение",
                path:links.crmProcurement + links.crmMovings,
            },
          
        ]
    },
    {
        title:"Заказы",
        path:links.crmSales + links.crmOrders,
        // children:[
        //     {
        //         title:"Заказы",
        //         path:links.crmSales + links.crmOrders,
        //     },
        //     {
        //         title:"Сборки заказов",
        //         path:links.crmSales + links.crmAssembly,
        //     },
        //     {
        //         title:"Отгрузки",
        //         path:links.crmSales + links.crmShipment,
        //     },
          
        // ]
    },
    {
        title:"Справочники",
        children:[
           
            {
                title:"Товары",
                path:links.crmDirectories + links.crmGoods,
            },
            {
                title:"Контрагенты",
                path:links.crmDirectories + links.crmCounterparties,
            },
            {
                title:"Склады",
                path:links.crmDirectories + links.crmStocks,
            },
            // {
            //     title:"Сотрудники",
            //     path:links.crmDirectories + links.crmEmployees,
            // }
        ]
    },
    // {
    //     title:"Задачи",
    //     path:links.crmTasks
    // }

]