import { useEffect, useState } from "react";
import "./employees-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as ArrowBack } from "../../../../shared/images/svg/arrow-back.svg";
import { ReactComponent as TrashBin } from "../../../../shared/images/svg/trash-bin.svg";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import Selected from "../../../../components/selected-component/selected-component";
import { API } from "../../../../consts/api";
import Expander from "../../../../ui/expander/expander";
import { ConfirmationModal } from "../../../../components/modal-component/confirmation-modal/confirmation-modal";
import { SelectItem } from "../../../../core/view-models/select-item";
import { UpdateObject } from "../../../../shared/helpers/update-object";

const employeesCardCN = bemCN("employeesCard");

export const EmployeesCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [employeer, SetEmployees] = useState(
    location.state && location.state.employeer
      ? location.state.employeer
      : {
          id: 0,
          dateStart: "",
          name: "",
          surname: "",
          patronymic: "",
          fio: "",
          position: "",
          positionId: [],
          phone: "",
          role: "",
          roleId: -1,
        }
  );

  const [isShowModalDeleteEmloyeer, setIsShowModalDeleteEmloyeer] = useState(false);

  const [Positions, setPositions] = useState<any[]>([]);
  const [Roles, setRoles] = useState<any[]>([]);

  const [IsNew, SetIsNew] = useState(
    location.state && location.state.employeer ? false : true
  );
  const [IsEdit, SetIsEdit] = useState(IsNew);

  const updateEmployeer = () => {
    IsNew
      ? API.workerAddCreate(employeer).then(() => {
          SetIsEdit(false);
          SetIsNew(false);
          SetEmployees({
            ...employeer,
            fio:
              employeer.surname +
              " " +
              employeer.name +
              " " +
              employeer.patronymic,
          });
        })
      : API.workerUpdateUpdate(employeer).then(() => {
          SetIsEdit(false);
          SetIsNew(false);
          SetEmployees({
            ...employeer,
            fio:
              employeer.surname +
              " " +
              employeer.name +
              " " +
              employeer.patronymic,
          });
        });
  };

  const addPosition = () => {
    let posId = employeer.positionId;
    posId.push(1);
    SetEmployees({
      ...employeer,
      positionId: posId,
    });
  };

  const deletePosition = (id: number) => {
    let posId = employeer.positionId.filter((item: number) => item !== id);
    SetEmployees({
      ...employeer,
      positionId: posId,
    });
  };

  const updatePosition = (idPos: number, indexInput: number) => {
    let posIds = employeer.positionId;
    posIds[indexInput] = idPos;
    SetEmployees({
      ...employeer,
      positionId: posIds,
    });
  };

  const deleteEmployeer = () => {
    setIsShowModalDeleteEmloyeer(false);
    API.workerDelete(employeer.id).then(() =>
      navigate("/directories/employees/")
    );
  };

  useEffect(() => {
    API.positionGetAllItemsList().then((res) => {
      setPositions([...res.data.items]);
    });
    API.roleGetAllItemsList().then((res) => {
      setRoles([...res.data.items]);
    });
  }, []);

  return (
    <div className={employeesCardCN()}>
      <div className={employeesCardCN("header")}>
        <div>
          <button
            onClick={() => {
              employeer && IsEdit
                ? SetIsEdit(false)
                : navigate("/directories/employees/");
            }}
          >
            <ArrowBack />
          </button>
          <h2>{!IsNew ? employeer?.fio : "Добавление сотрудника"}</h2>
        </div>
        <div>
          <span className="h2">Петров В.В.</span>
        </div>
      </div>
      <div className={employeesCardCN("info")}>
        <div>
          <InputText
            OnChange={(v) => {
              SetEmployees(UpdateObject(employeer, "surname", v));
            }}
            header="Фамилия:"
            text={employeer ? employeer?.surname : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              SetEmployees(UpdateObject(employeer, "name", v));
            }}
            header="Имя:"
            text={employeer ? employeer.name : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              SetEmployees(UpdateObject(employeer, "patronymic", v));
            }}
            header="Отчество:"
            text={employeer ? employeer.patronymic : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              SetEmployees(UpdateObject(employeer, "birthday", v));
            }}
            header="Дата рождения:"
            text={employeer ? employeer.birthday : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          {employeer && !IsEdit ? (
            <InputText
            isRequired
              header="Права:"
              text={employeer.role ? employeer.role : ""}
              isActive
            />
          ) : (
            <div className={employeesCardCN("popap")}>
              <p className="h4"><span className={employeesCardCN("required")}>* </span>Права:</p>
              <Selected
                OnChange={(v) => {
                  SetEmployees(UpdateObject(employeer, "roleId", v));
                  SetEmployees(
                    UpdateObject(
                      employeer,
                      "role",
                      Roles.find((r) => r.id === v).name
                    )
                  );
                }}
                id="role_input"
                active={employeer?.role ? employeer?.roleId : 0}
                items={Roles.map((item) => {
                  return new SelectItem(item.id, item.name);
                })}
              />
            </div>
          )}
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              SetEmployees(UpdateObject(employeer, "phone", v));
            }}
            header="Контактные данные:"
            text={employeer ? employeer.phone : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              SetEmployees(UpdateObject(employeer, "dateStart", v));
            }}
            header="Дата начала работы:"
            text={employeer ? employeer.dateStart : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              SetEmployees(UpdateObject(employeer, "login", v));
            }}
            header="Логин:"
            text={employeer ? employeer.login : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            OnChange={(v) => {
              SetEmployees(UpdateObject(employeer, "password", v));
            }}
            header="Пароль:"
            text={employeer ? employeer.password : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>
      <div className={employeesCardCN("roles")}>
        <Expander title={"Должности"}>
          {Positions.length !== 0 &&
            (employeer?.positionId as any[]).map((posId: number, index) => {
              return (
                <div key={index} className={employeesCardCN("role-item")}>
                  {employeer && !IsEdit ? (
                    <InputText
                      text={Positions.find((r) => r.id === posId)?.name}
                      isActive
                    />
                  ) : (
                    <>
                      <Selected
                        OnChange={(id: number) => updatePosition(id, index)}
                        active={posId}
                        items={Positions.map((item) => {
                          return new SelectItem(item.id, item.name);
                        })}
                      />
                      <Button
                        image-white
                        icon={<TrashBin />}
                        onClick={() => deletePosition(posId)}
                      />
                    </>
                  )}
                </div>
              );
            })}
          {IsEdit ? (
            <div className={employeesCardCN("add-position-btn")}>
              <Plus />
              <Button
                white
                text="Добавить должность"
                onClick={() => addPosition()}
              />
            </div>
          ) : null}
        </Expander>
      </div>

      <div className={employeesCardCN("buttons")}>
        <Button
          onClick={() => {
            employeer && IsEdit
              ? SetIsEdit(false)
              : navigate("/directories/employees");
          }}
          text="Закрыть"
          white-outline
        />
        {IsEdit ? (
          <Button
            onClick={() => {
              SetIsEdit(false);
              updateEmployeer();
            }}
            text={"Сохранить"}
            blue
          />
        ) : (
          <Button onClick={() => SetIsEdit(true)} text={"Редактировать"} blue />
        )}
        {employeer ? (
          IsEdit && (
            <Button
              image-red
              icon={<TrashBin />}
              onClick={() => {
                setIsShowModalDeleteEmloyeer(true);
              }}
            />
          )
        ) : (
          <Button image-red icon={<TrashBin />} />
        )}
      </div>
      <ConfirmationModal
        onClickAgree={deleteEmployeer}
        isShowDialog={isShowModalDeleteEmloyeer}
        setIsShowDialog={setIsShowModalDeleteEmloyeer}
        text={
          "Вы уверены, что хотите удалить сотрудника по фамилии " +
          employeer?.surname +
          "?"
        }
      />
    </div>
  );
};
