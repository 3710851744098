import React, { FC, useState } from "react";
import "./review-slide-component.scss";
import { bemCN } from "../../../configs/bem-classname";
import { ReactComponent as StarFill } from "../../../shared/images/svg/review-star-fill.svg";
import { ReactComponent as Star } from "../../../shared/images/svg/review-star.svg";
import { Modal } from "../../../components/modal-component/modal-component";

export interface ReviewSlideProps {
  id: number;
  mark: number;
  text: string;
  author: string;
}
const pageCn = bemCN("reviewSlide");

const ReviewSlide: FC<ReviewSlideProps> = ({ mark, text, author }) => {
  const markStars = () => {
    const result = [];
    const max = mark > 5 ? 5 : mark;
    let count = 5;

    for (let i = 1; i <= max; i++) {
      result.push(<StarFill />);

      count -= 1;
    }

    for (let i = 0; i < count; i++) {
      result.push(<Star />);
    }

    return result;
  };
  const [isShowModal, setIsShowModal] = useState(false);
  const clickMore = () => {
    setIsShowModal(!isShowModal);
  };

  return (
    <div className={pageCn()}>
      <div className={pageCn("mark")}>{markStars()}</div>
      <div className={pageCn("description")}>
        <div className={pageCn("text")}>{text}</div>
        <div onClick={() => clickMore()} className={pageCn("more", ["blue"])}>
          Читать дальше
        </div>
      </div>
      <div className={pageCn("author")}>{author}</div>
      <Modal
        title=""
        isShowDialog={isShowModal}
        setIsShowDialog={setIsShowModal}
      >
        <div className={pageCn("mark",["head"])}>{markStars()}</div>
        <div className={pageCn("description")}>
          <div className={pageCn("text")}>{text}</div>
        </div>
        <div className={pageCn("author")}>{author}</div>
      </Modal>
    </div>
  );
};

export default ReviewSlide;
