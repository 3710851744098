export const links = {
  xstat: "https://xstat.ru",
  home: "/analytics",
  main: "/main",
  analyitcs: "/analytics",
  analyticsProduct: "products",
  analyitcsCategory: "/categories",
  analyitcsSeller: "/seller",
  analyticsBrand: "/brands",
  analyticsRating: "/rating",
  subcategory: "/subcategory",
  login: "/login",
  register: "/register",
  profile: "/profile",
  profileInfo: "/info",
  profileRate: "/rate",
  profileStore: "/store",
  profileKey: "/key",
  product: "/product",
  favorite: "/favorite",
  seller: "/seller",
  passwordRecovery: '/passworRecovery',
  crmSales: "/sales",
  crmPurchase: "/purchase", //Закупки
  crmGoods: "/goods", //Товары
  crmCounterparties: "/counterparties", //Контрагенты
  crmTasks: "/tasks",
  crmMain: "/",
  crmProcurement: "/procurement",
  crmOrders: "/orders",
  crmShipment: "/shipment",
  crmDirectories: "/directories",
  crmAcceptance: "/acceptance", //Приемки
  crmAssembly: "/assembly", //Сборка
  crmMovings: "/movings", //Перемещения
  crmMovements: "/movements",
  crmStocks: "/stocks", //Склады
  crmEmployees: "/employees", //Сотрудники

  politics: "/politics",
  offer: "/offer",
  help: "/help",
  autobider: "/autobider",
  blog: "/blog",
};
