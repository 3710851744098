import { TableDataItem } from "./table-data-item";

export class TableDataRow {
  id: number;
  data: TableDataItem[];

  constructor(id: number, data: TableDataItem[]) {
    this.id=id;
    this.data = data;
  }
}
