export class Subcategory {
    parentID?: number;
    parentName?: string;
  
    subjectID?: number;
    subjectName?: string;
  
    constructor (subjectID: number,subjectName: string) {
      this.subjectID = subjectID;
      this.subjectName = subjectName;
    }
  }
  