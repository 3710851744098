import "./card-chart.scss";
import { bemCN } from "../../configs/bem-classname";
import { memo } from "react";

import { BarChartDataItem } from "../../core/view-models/bar-chart-data-item";
import { Area, AreaChart } from "recharts";

export const availableColor = ["blue", "green", "orange", "red"] as const;

type CardChartProps = {
  [color in (typeof availableColor)[number]]?: boolean;
} & {
  id?: string;
  header?: string;
  value?: string;
  unit?: string;
  data: BarChartDataItem[];
  isLeft?: boolean;
};

const colors = new Map<string, string[]>([
  [
    "green",
    [
      "rgba(37, 186, 123, 1)",
      "rgba(37, 186, 123, 0.5)",
      "rgba(37, 186, 123, 0)",
    ],
  ],
  [
    "blue",
    [
      "rgba(29, 117, 189, 1)",
      "rgba(29, 117, 189, 0.5)",
      "rgba(29, 117, 189, 0)",
    ],
  ],
  [
    "orange",
    [
      "rgba(255, 129, 59, 1)",
      "rgba(255, 129, 59, 0.5)",
      "rgba(255, 129, 59, 0)",
    ],
  ],
  [
    "red",
    ["rgba(239, 11, 11, 1)", "rgba(239, 11, 11, 0.5)", "rgba(239, 11, 11, 0)"],
  ],
]);

const CardChart = memo((props: CardChartProps) => {
  let { header, value, unit, data, isLeft } = props;
  const colorType = availableColor.find((i) => props[i]) ?? "";
  let colorGradient = colorType ? colors.get(colorType) : colors.get("blue");
  let idGradient = colorType ? colorType : "blue";

  const cardChartCN = bemCN("cardChart");

  return (
    <div className={cardChartCN()}>
      {isLeft && (
        <div className={cardChartCN("info")}>
          <div className={cardChartCN("type", ["h3"])}>{header}</div>
          <div className={cardChartCN("value", ["h3"])}>
            {value ? value + " " + unit + "." : "-"}
          </div>
        </div>
      )}
      <div className={cardChartCN("chart")}>
        <AreaChart width={350} height={109} data={data}>
          <defs>
            <linearGradient id={idGradient} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colorGradient && colorGradient[1]} />
              <stop
                offset="95%"
                stopColor={colorGradient && colorGradient[2]}
              />
            </linearGradient>
          </defs>
          <Area
            type="basis"
            dataKey="value"
            stroke={colorGradient && colorGradient[0]}
            fill={`url(#${idGradient})`}
          />
        </AreaChart>
      </div>
      {!isLeft && (
        <div className={cardChartCN("info")}>
          <div className={cardChartCN("type", ["h3"])}>{header}</div>
          <div className={cardChartCN("value", ["h3"])}>
            {value ? value + " " + unit + "." : "-"}
          </div>
        </div>
      )}
    </div>
  );
});

export default CardChart;
