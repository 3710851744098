import { bemCN } from "../../configs/bem-classname";
import { Purchases } from "../../modules/purchase-module";



import "./purchases-page.scss";

const purchaseCN = bemCN("purchase");

export const PurchasesPage = () => {
  return (
    <div className={purchaseCN()}>
      <Purchases/>
    </div>
  );
};
    