import React, { useEffect, useState } from "react";
import "./register-form.scss";
import {
  Form,
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { bemCN } from "../../configs/bem-classname";
import allCities from "../../consts/cities.json";
import Loading from "../loading-component/loading-component";
import { InputText } from "../../ui/input-text/input-text";
import { countries } from "../../consts/county";
import { SelectItem } from "../../core/view-models/select-item";
import { CheckItem } from "../../ui/check-item/check-item";
import { Modal } from "../modal-component/modal-component";
import { links } from "../../consts/links";
import { useAuth } from "../../shared/hooks/useAuth";
import { Input } from "../../ui/input/input";
import {
  dateHasError,
  emailHasError,
  nameHasError,
  passwordHasError,
  phoneHasError,
} from "../../shared/helpers/validation";
import InputFieldPhone from "../../ui/input-field-phone/InputFieldPhone";
import RegisterVerificateEmailModal from "../../register-verificate-email-modal/register-verificate-email-modal";
import { Selected } from "../selected/selected";
import { API } from "../../consts/api";
import { sendMetrics } from "../../shared/helpers/metrics";
type NewUser = {
  email: string;
  passwordHash: string;
  phoneNumber: string;
  name: string;
  gender: number;
  birthday: string;
  city: string;
  country: string;
  jobTitle: number;
};

const registerFormCn = bemCN("register-form");

const RegisterForm = () => {
  const { onRegistration } = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [registrationErrorText, setRegistrationErrorText] =
    useState<string>("");
  const { onLogin, isAuth } = useAuth();
  const [isConfirmedOpenModal, setIsConfirmedOpenModal] =
    useState<boolean>(false);
  const [confirmMessage, setConfirmMessage] = useState<string>("");
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const [confirmInterval, setConfirmInterval] = useState<NodeJS.Timeout | null>(
    null
  );
  const [newUser, setNewUser] = useState<NewUser>({
    email: location.state && location.state.email ? location.state.email : "",
    passwordHash: "",
    phoneNumber:
      location.state && location.state.phone ? location.state.phone : "",
    name: "",
    gender: 0,
    birthday: "",
    city: "Москва",
    country: "Россия",
    jobTitle: 1,
  });
  const [isAgree, setIsAgree] = useState(false);
  const [roles, setRoles] = useState<any[]>();

  const allCities2 = allCities.map((item: any) => {
    return {
      id: item.id,
      countryId: item.countryId,
      name: item.name,
    };
  });
  const [country, setCountry] = useState({ id: 7, name: "Россия" });
  const [city, setCity] = useState({ id: 524901, name: "Москва", idCounty: 7 });

  const [cities, setCities] = useState<any[]>(allCities2);
  const fieldInputCn = "field-input";
  useEffect(() => {
    setIsLoad(true);
    let c = allCities2.filter((item: any) => item.countryId == country.id);
    setCities(c);
    setIsLoad(false);
  }, [country]);

  const checkToken = () => {
    if (isAuth==true) {
      setConfirmed(true);
      setConfirmMessage("Вы успешно зарегистрировались!");
      setIsConfirmedOpenModal(true);
    }
  };
  useEffect(() => {
    // authenticationService.getListTitles().then((res: any) => {
    //   setRoles(
    //     res.map((item: any) => {
    //       return { id: item.id, name: item.title };
    //     }),
    //   );
    // });
    // checkToken();
    // return () => {
    //   if (confirmInterval) {
    //     clearInterval(confirmInterval);
    //   }
    // };
  }, []);

  useEffect(() => {
    if (confirmed) {
      const email = localStorage.getItem("email");
      const password = localStorage.getItem("passwordHash");

      const registrationFormInfo = { email, password };
      onLogin!(registrationFormInfo?.email!, registrationFormInfo?.password!) //todo: safe password decoding
        .then(() => {
          setConfirmMessage("Вы успешно зарегистрировались!");
          localStorage.clear();
          setIsConfirmedOpenModal(true);
        })
        .catch((e) => {
          console.log("registrationFormError 2", e);
          setConfirmMessage(`Ошибка авторизации: ${e.message}`);

          setIsConfirmedOpenModal(true);
        });
    }

    setSearchParams({});
  }, [confirmed]);
  useEffect(() => {
    const key = searchParams.get("key");
    const email = searchParams.get("email");
    if (key && email) {
      API.authConfirmEmailList({ email, key })
        .then(() => {
          setConfirmed(true);
        })
        .catch((e) => {
          console.log("registrationFormError", e);
          setConfirmMessage(e.message);
          return false;
        });
    }
  }, [searchParams]);

  const handleConfirmedClose = () => {
    if (confirmed) {
      navigate("/");
    } else {
      //setIsConfirmedOpenModal(false); //todo: что делать, если подтвердить не получилось?
    }
  };

  const registation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoad(true);
    if (
      onRegistration &&
      newUser.email.trim() !== "" &&
      !emailHasError(newUser.email) &&
      newUser.passwordHash.trim() !== "" &&
      !passwordHasError(newUser.passwordHash) &&
      newUser.birthday.trim() !== "" &&
      !dateHasError(newUser.birthday) &&
      newUser.phoneNumber.trim() !== "" &&
      !phoneHasError(newUser.phoneNumber)
    ) {
      try {
        const res = await onRegistration(
          newUser.email,
          newUser.passwordHash,
          newUser.phoneNumber,
          newUser.name,
          newUser.gender,
          newUser.birthday,
          newUser.city,
          newUser.country,
          newUser.jobTitle
        );

        if (!isAgree) {
          setIsLoad(false);
          setRegistrationErrorText(
            "Согласитесь с правилами обработки персональных данных"
          );
        }
        if (res) {
          setRegistrationErrorText("");
          setIsLoad(false);
          setIsOpenModal(true);
          localStorage.setItem("email", newUser.email);
          localStorage.setItem("passwordHash", newUser.passwordHash);
          sendMetrics("reachGoal", "registrationButtonClick");

          const interval = setInterval(() => {
            checkToken();
          }, 5000);
          setConfirmInterval(interval);
        } else {
          setIsLoad(false);

          setRegistrationErrorText("Что то пошло не так, попробуйте ещё раз");
          setNewUser({ ...newUser, passwordHash: "" });
        }
      } catch (e: any) {
        const errorText = e?.extra?.text;
        if (e?.status && e?.status === 422 && errorText) {
          setIsLoad(false);
          if (errorText === "Email already exists") {
            setRegistrationErrorText(
              "Введенный адрес электронной почты уже зарегистирован в системе"
            );
          } else {
            setRegistrationErrorText(errorText); //'Некорректный адрес электронной почты'
          }
        }
      }
    } else {
      setIsLoad(false);
      setRegistrationErrorText(`Заполните поля`);
    }
  };

  if (isLoad) {
    return <Loading color="dark" />;
  }

  return (
    <div className={registerFormCn()}>
      <div className={registerFormCn("title")}>Регистрация</div>
      <Form onSubmit={registation}>
        <div className={registerFormCn("form-inputs")}>
          <div>
            <Input
              onChange={(v: string) => {
                setNewUser({ ...newUser, name: v });
              }}
              validators={[nameHasError]}
              label="Имя"
            />
            <Input
              onChange={(v: string) => {
                setNewUser({ ...newUser, email: v });
              }}
              validators={[emailHasError]}
              label="Почта"
              value={newUser.email}
            />
          </div>
          <div>
            {cities && countries && (
              <Selected
                onChange={(id: number) => {
                  let c = countries.find((item: any) => item.id == id);
                  if (c) {
                    setCountry({
                      id: id,
                      name: c.name,
                    });
                    setNewUser({ ...newUser, country: c.name });
                  }
                }}
                items={countries.map(
                  (item: any) => new SelectItem(item.id, item.name)
                )}
                isRadio={true}
                isCheck={false}
                activeId={country.id}
              />
            )}
            <Input
              onChange={(v: string) => {
                setNewUser({ ...newUser, passwordHash: v });
              }}
              validators={[passwordHasError]}
              label="Пароль"
              isPassword
            />
          </div>
          <div>
            {cities && countries && (
              <Selected
                onChange={(id: number) => {
                  setCity(cities.find((item: any) => item.id == id));
                  setNewUser({
                    ...newUser,
                    city: cities.find((item: any) => item.id == id).name,
                  });
                }}
                items={cities.map(
                  (item: any) => new SelectItem(item.id, item.name)
                )}
                isRadio={true}
                isCheck={false}
                activeId={city.id}
              />
            )}
            <InputFieldPhone
              className={registerFormCn("field")}
              classNameInput={registerFormCn(fieldInputCn)}
              onChange={(v: any) => {
                setNewUser({ ...newUser, phoneNumber: v.target.value });
              }}
              name="phone"
              label="Телефон"
              type="tel"
              validators={[phoneHasError]}
              value={newUser.phoneNumber}
            />
          </div>
          <div>
            <Input
              onChange={(v: string) => {
                setNewUser({ ...newUser, birthday: v });
              }}
              validators={[dateHasError]}
              label="Дата рождения"
              isDate={true}
            />
            {roles && (
              <Selected
                onChange={(id: number) => {
                  setNewUser({ ...newUser, jobTitle: id });
                }}
                items={roles?.map(
                  (item: any) => new SelectItem(item.id, item.name)
                )}
                isRadio={true}
                isCheck={false}
                activeId={newUser.jobTitle}
              />
            )}
          </div>
          <div>
            <div className={registerFormCn("radio-group")}>
              <div>
                <input
                  onChange={(v: any) => setNewUser({ ...newUser, gender: 1 })}
                  type="radio"
                  id="man"
                  name="gender"
                  value="1"
                />
                <label htmlFor="man">Мужской</label>
              </div>
              <div>
                <input
                  onChange={(v: any) => setNewUser({ ...newUser, gender: 2 })}
                  type="radio"
                  id="woman"
                  name="gender"
                  value="0"
                />
                <label htmlFor="woman">Женский</label>
              </div>
            </div>
            <div className={registerFormCn("application")}>
              <CheckItem onActive={() => setIsAgree(!isAgree)} Active={isAgree}>
                Я согласен с правилами{" "}
                <a
                  href={links.politics}
                  target="_blank "
                  className={registerFormCn("green")}
                >
                  обработки персональных данных
                </a>
              </CheckItem>
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="button-auth"
          disabled={
            !!emailHasError(newUser.email) ||
            !!passwordHasError(newUser.passwordHash) ||
            !isAgree ||
            !!dateHasError(newUser.birthday) ||
            !!phoneHasError(newUser.phoneNumber) ||
            !!nameHasError(newUser.name)
          }
        >
          Далее
        </button>
      </Form>
      {registrationErrorText && (
        <div className={registerFormCn("common-reg-error")}>
          {registrationErrorText}
        </div>
      )}
      <div className={registerFormCn("bottom")}>
        <span>Уже есть аккаунт?</span>&nbsp;
        <Link className={registerFormCn("link")} to={links?.login}>
          Войти
        </Link>
      </div>
      {isOpenModal && (
        <RegisterVerificateEmailModal
          email={newUser.email}
          isShow={isOpenModal}
          setIsShow={setIsOpenModal}
          changeData={() => setIsOpenModal(false)}
        />
      )}
      {isConfirmedOpenModal == true && (
        <Modal
          title={""}
          isShowDialog={isConfirmedOpenModal}
          setIsShowDialog={setIsConfirmedOpenModal}
        >
          <div className={registerFormCn("confirmed-modal")}>
            <div className={"confirm-icon"}></div>
            <div className={registerFormCn("confirm-message")}>
              {confirmMessage}
            </div>
            <button
              type="button"
              className={"button-auth" + (confirmed ? " confirmed" : "")}
              onClick={handleConfirmedClose}
            >
              {confirmed ? "Продолжить" : "ОК"}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default RegisterForm;
