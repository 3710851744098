import { bemCN } from "../../configs/bem-classname";

import { EmployeesCard } from "../../modules/employees-module";

const employeersCardCN = bemCN("employeesCard");

export const EmployeesCardPage = () => {
  return (
    <div className={employeersCardCN()}>
      <EmployeesCard/>
    </div>
  );
};