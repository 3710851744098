import { bemCN } from "../../../../configs/bem-classname";
import "./task-item-menu.scss";
import { useEffect, useState } from "react";
import { Button } from "../../../../ui/button/button";
import Popup from "../../../../ui/popup/popup";
import { ReactComponent as Profile } from "../../../../shared/images/svg/profile-grey.svg";
import { ReactComponent as Clip } from "../../../../shared/images/svg/clip.svg";
import { ReactComponent as Duplicate } from "../../../../shared/images/svg/duplicate.svg";
import { ReactComponent as Activity } from "../../../../shared/images/svg/activity.svg";
import { ReactComponent as Trash } from "../../../../shared/images/svg/trash-bin.svg";
const taskMenuCN = bemCN("task_menu");
type ButtonNewGroupProps = {
  IsShowMenu: boolean;
  SetIsShowMenu: (isShow: boolean) => void;
  OnDuplicate?: () => void;
  OnDelete?: () => void;
};

export const TaskItemMenu = (props: ButtonNewGroupProps) => {
  let { IsShowMenu, OnDuplicate, SetIsShowMenu, OnDelete } = props;

  return (
    <Popup isShow={IsShowMenu} setIsShow={SetIsShowMenu}>
      <div className={taskMenuCN()}>
        <div className={taskMenuCN("info")}>
          <div>
            <Profile />
            <p>Добавил(а) Name</p>
          </div>
          <p>29 авг · 10:55</p>
        </div>
        <div className={taskMenuCN("buttons")}>
          <Button icon={<Clip />} text="Прикрепить документ" text-image-left />
          <Button
            icon={<Duplicate />}
            onClick={OnDuplicate}
            text="Дублировать задачу"
            text-image-left
          />
          <Button
            icon={<Activity />}
            text="Активность по задаче"
            text-image-left
          />
          <Button
            icon={<Trash />}
            text="Удалить задачу"
            onClick={OnDelete}
            text-image-left
          />
        </div>
      </div>
    </Popup>
  );
};
