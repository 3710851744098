import { VariablesLocalStorage } from "../../consts/variables-localstorage";

export const LocalStorageListenner = (key: VariablesLocalStorage, colback:(value:any)=>void) : void => {
  window.onstorage = (event) => {
    if (event.key != key) return;
    const item = window.localStorage.getItem(key);
    localStorage.clear();
    colback(item ? JSON.parse(item) : false);
  };
};
