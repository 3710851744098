import "./main-tariff-component.scss";
import MainTariffItem from "../main-tariff-item-component/main-tariff-item-component";
import { useLayoutEffect, useState } from "react";
import { bemCN } from "../../../configs/bem-classname";
import { Tariff } from "../../../core/entities/tariff";

const MainTariff = () => {
  const mainTariffPageCn = bemCN("mainTariff");
  const [tariffs, setTariffs] = useState<Tariff[]>([
    new Tariff(1, "14 дней", 0, 0, 4, true, [
      "Полный доступ ко всем функциям",
      "Учет расходов и доходов",
      "Управление ассортиментом",
      "Аналитика своего магазина",
    ]),
    new Tariff(2, "Ежемесячно", 300000, 1, 4, true, [
      "Полный доступ ко всем функциям",
      "Учет расходов и доходов",
      "Управление ассортиментом",
      "Аналитика своего магазина",
      "Персональная поддержка",
    ]),
    new Tariff(3, "3 месяца", 810000, 3, 4, true, [
      "Полный доступ ко всем функциям",
      "Учет расходов и доходов",
      "Управление ассортиментом",
      "Аналитика своего магазина",
      "Персональная поддержка",
      "Экономия 10%",
    ]),
    new Tariff(4, "6 месяца", 1530000, 6, 4, true, [
      "Полный доступ ко всем функциям",
      "Учет расходов и доходов",
      "Управление ассортиментом",
      "Аналитика своего магазина",
      "Персональная поддержка",
      "Экономия 15%",
    ]),
    new Tariff(5, "12 месяца", 2880000, 12, 4, true, [
      "Полный доступ ко всем функциям",
      "Учет расходов и доходов",
      "Управление ассортиментом",
      "Аналитика своего магазина",
      "Персональная поддержка",
      "Экономия 20%",
    ]),
  ]);

  useLayoutEffect(() => {}, [1]);
  return (
    <div className={mainTariffPageCn()}>
      <h2 className={mainTariffPageCn("title")}>
        <span className={mainTariffPageCn("title_green")}>ВЫБЕРИТЕ</span> ТАРИФ
      </h2>
      <ul className={mainTariffPageCn("list")}>
        {tariffs &&
          tariffs.map((item: Tariff) => (
            <MainTariffItem
              key={item.id}
              tariff={item}
              isPopular={item.durationMonths == 6}
            />
          ))}
      </ul>
    </div>
  );
};

export default MainTariff;
