import { bemCN } from "../../configs/bem-classname";
import { Button } from "../../ui/button/button";
import "./ui-page.scss";

import { ReactComponent as PlusCircle } from "../../shared/images/svg/circle-plus.svg";
import { ReactComponent as Folder } from "../../shared/images/svg/folder.svg";
import { ReactComponent as ArrowBottomTriangle } from "../../shared/images/svg/arrow-bottom-triangle.svg";
import { ReactComponent as Filter } from "../../shared/images/svg/filter.svg";
import { ReactComponent as Barcode } from "../../shared/images/svg/barcode.svg";
import { ReactComponent as TrashBin } from "../../shared/images/svg/trash-bin.svg";
import { ReactComponent as History } from "../../shared/images/svg/active-history.svg";
import { InputText } from "../../ui/input-text/input-text";
import { ReactComponent as Search } from "../../shared/images/svg/search-small.svg";
import ImageCard from "../../ui/image-card/image-card";
import { SelectItem } from "../../ui/select-item/select-item";
import wildberries from "../../shared/images/png/wildberries.png";
import { CustomBarChart as BarChart } from "../../ui/bar-chart/bar-chart";
import { data } from "../../consts/values/bar-chart-data";
import CardChart from "../../ui/card-chart/card-chart";
import ExportButton from "../../components/export-component/export-button/export-button";
import NoticeCard from "../../components/notice-card-component/notice-card-component";
import { links } from "../../consts/links";
import RadioCalendar from "../../components/radio-calendar-component/radio-calendar-component";
import Calendar from "../../components/calendar-component/calendar-component";
import Table from "../../components/table-component/table-component";
import { headersProduct, products } from "../../consts/values/products";
import { headersSaler, salers } from "../../consts/values/salers";
import { CheckGroup } from "../../components/check-group-component/check-group-component";
import { categoriesProduct } from "../../consts/values/categories";
import { noticeItems } from "../../consts/values/notice-items";
import React from "react";
import { CheckItem } from "../../ui/check-item/check-item";
import { ButtonNewGroupItem, TaskGroupItem } from "../../modules/task-module";
import { groupTasks } from "../../consts/values/group-tasks";
import { Api } from "../../core/api/Api";

const uiCN = bemCN("ui");

export const Ui = () => {
  return (
    <div className={uiCN()}>
       <div>
        <ButtonNewGroupItem/>
      </div>
      <div>
        <CheckItem round/>
      </div>
      <div>
        <p>{process.env.REACT_APP_API_BASE_UR}</p>
        <Calendar />
      </div>
      <div>
        {/* <ExportButton></ExportButton> */}
      </div>
      <div>
        <Button
          white
          text="Применить"
          onClick={() => {
            alert("Тык!");
          }}
        />
      </div>
      <div>
        <Button
          blue
          text="Отмена"
          onClick={() => {
            alert("Отмена!");
          }}
        />
      </div>
      <div>
        <Button text-image-left text="Добавить товар" icon={<PlusCircle />} />
      </div>
      <div>
        <Button text-image-left text="Стикер ozon" icon={<Barcode />} />
      </div>
      <div>
        <Button text-image-left text="Фильтры" icon={<Filter />} />
      </div>
      <div>
        <Button image-red icon={<TrashBin />} />
      </div>
      <div>
        <Button image-white icon={<History />} />
      </div>
      <div>
        <Button id="violet" light-violet text="Добавить папку" />
      </div>
      <div>
        <InputText placeholder="Напишите комментарий" header="Комментарий" />
      </div>
      <div>
        <InputText
          placeholder="Напишите комментарий"
          icon={<Search />}
          icon-left
        />
      </div>
      <div>
        <InputText header="Комментарий" />
      </div>
      <div>
        <ImageCard />
      </div>
      <div>
        <BarChart green labelY="Заказы" data={data} />
      </div>
      <div>
        <BarChart labelY="Продажи" data={data} />
      </div>
      <div>
        <CardChart data={data} header="Выручка" unit="руб" value="540" />
        <CardChart green data={data} header="Выручка" unit="руб" value="540" />
        <CardChart orange data={data} header="Выручка" unit="руб" value="540" />
      </div>
      <div>
        <SelectItem text="Wildberries" image={wildberries} />
        <SelectItem text="Wildberries" image={wildberries} green />
      </div>
      <div>
        <NoticeCard title="Задачи" link={links.crmMain} items={noticeItems} />
      </div>
      <div>
        <RadioCalendar />
      </div>
      <div>
        <Table data={products} headers={headersProduct} />
      </div>
      <div>
        <Table headers={headersSaler} data={salers} />
      </div>
      <div>
        <CheckGroup items={categoriesProduct.map((item) => item.name)} />
        <Button
          text-double-image
          text="Одежда"
          icon={<Folder />}
          icon2={<ArrowBottomTriangle />}
        />
      </div>
    </div>
  );
};
