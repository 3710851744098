import { links } from "./links";

import { ReactComponent as AnalisisIcon } from "../shared/images/svg/analytics.svg";
import { ReactComponent as MegaPhoneIcon } from "../shared/images/svg/megaphone.svg";
import { ReactComponent as GoodsIcon } from "../shared/images/svg/goods.svg";
import { ReactComponent as ProfileIcon } from "../shared/images/svg/profile-sidebar.svg";
import { ReactComponent as SettingIcon } from "../shared/images/svg/setting.svg";
import { ReactComponent as HelpIcon } from "../shared/images/svg/help.svg";
import { ReactComponent as LogoutIcon } from "../shared/images/svg/logout.svg";
import { link } from "fs";

export const menu = [
  {
    id: 0,
    icon: <AnalisisIcon></AnalisisIcon>,
    title: "Аналитика",
    links: [
      {
        name: "Товары",
        path: links.xstat + links.analyitcs,
      },
      {
        name: "Категории",
        path: links.xstat + links.analyitcs + links.analyitcsCategory,
      },
      {
        name: "Продавцы",
        path: links.xstat + links.analyitcs + links.analyitcsSeller,
      },
      {
        name: "Бренды",
        path: links.xstat + links.analyitcs + links.analyticsBrand,
      },
      {
        name: "Рейтинги",
        path:  links.xstat + links.analyitcs + links.analyticsRating,
      },
    ],
  },
  {
    id: 1,
    icon: <GoodsIcon></GoodsIcon>,
    title: "CRM",
    links: [
      {
        name: "Главная",
        path: links.crmMain,
      },
      {
        name: "Закупки",
        path: links.crmProcurement + links.crmPurchase,
      },
      {
        name: "Продажи",
        path: links.crmSales + links.crmOrders,
      },
      {
        name: "Справочники",
        path: links.crmDirectories + links.crmGoods,
      },
      {
        name: "Задачи",
        path: links.crmTasks,
      },
    ],
  },
  {
    id: 2,
    icon: <MegaPhoneIcon></MegaPhoneIcon>,
    title: "Реклама",
    links: [
      {
        name: "Мониторинг",
        path: "#",
      },
      {
        name: "Управление рекламой",
        path: "#",
      },
      {
        name: "Расширение запросов",
        path: "#",
      },
    ],
  },
  {
    id: 3,
    icon: <ProfileIcon></ProfileIcon>,
    title: "Профиль",
    links: [
      {
        name: "Мой профиль",
        path: links.xstat + links?.profile  + links?.profileInfo,
      },
      {
        name: "Тариф и оплата",
        path: links.xstat + links?.profile + links?.profileRate,
      },
      {
        name: "История запросов",
        path: links.xstat + links?.profile + links?.profileStore,
      },
      {
        name: "Ключи",
        path: links.xstat + links?.profile + links?.profileKey,
      },
    ],
  },
  {
    id: 4,
    icon: <SettingIcon></SettingIcon>,
    title: "Настройки",
    path: "#",
  },
  {
    id: 5,
    icon: <HelpIcon></HelpIcon>,
    title: "Помощь",
    path: "#",
  },
  {
    id: 6,
    icon: <LogoutIcon></LogoutIcon>,
    title: "Выход",
    path: "#",
  },
];
