import "./expander.scss";
import { bemCN } from "../../configs/bem-classname";
import { FC, ReactNode, useState } from "react";
import { ReactComponent as ArrowTop } from "../../shared/images/svg/arrow-top.svg";


type ExpanderProps = {
  id?: string;
  title:string;
  children: ReactNode;
};

const Expander: FC<ExpanderProps> = ({ id, title, children }) => {
  const expanderCN = bemCN("expander");
  const [isShow, setIsShow] = useState(false);

  const onClick = () =>{
    setIsShow(!isShow);

  }


  return (
    <div id={id}  className={expanderCN()}>
        <div onClick={onClick} className={expanderCN("title", ["h3", isShow ? "expander__title-active":""])}>{title} <ArrowTop/></div>
        <div className={expanderCN("wrapper", [isShow ? "expander__wrapper-active" :""])}>
        <div className={isShow?expanderCN("visible"):expanderCN("invisible")}>{children}</div>
      
        </div>
        
    </div>
  );
};

export default Expander;
