import './radio-group.scss';
import { FC, useState } from 'react';
import { ReactComponent as CheckMark } from '../../../shared/images/svg/check-mark.svg';
import { bemCN } from '../../../configs/bem-classname';

type RadioGroupProps = {
  items: any[];
  activeId: number;
  onChange(id: number): void;
};

export const RadioGroup: FC<RadioGroupProps> = ({ activeId, items, onChange }) => {
  const radioGroupCN = bemCN('radioGroup');
  const [checkedId, setChekedId] = useState(activeId);

  const onClickItem = (id: number) => {
    setChekedId(id);
    onChange(id);
  };

  return (
    <div className={radioGroupCN()}>
      {items &&
        items.map((item) => (
          <div
            onClick={() => onClickItem(item.id)}
            key={item.id}
            className={radioGroupCN('radioItem')}
          >
            <div>{item.value}</div>
            {checkedId == item.id ? <CheckMark /> : null}
          </div>
        ))}
    </div>
  );
};
