import { StatusTask } from "./status-task";

export class Task {
  id?:  number|undefined;
  taskParentId?: number;
  folderId?: number;
  name?: string;
  text?: string;
  start?: string|undefined;
  end?: string|undefined;
  taskStatusId?: number;
  workerId?: number;
  active?: boolean;


  constructor(
    id?: number|undefined,
    folderId?: number,
    name?: string,
    text?: string,
    start?: string|undefined,
    end?: string|undefined,
    taskStatusId?: number,
    workerId?: number,
    taskParentId?: number,
    active: boolean = true,
  ) {
    this.id = id;
    this.text = text;
    this.name = name;
    this.taskParentId = taskParentId;
    this.folderId = folderId;
    this.start = start;
    this.end = end;
    this.taskStatusId = taskStatusId;
    this.workerId = workerId;
    this.active = active;
  }
}
