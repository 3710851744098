import { TableDataItem } from "../../core/view-models/table-data-item";
import { TableDataRow } from "../../core/view-models/table-data-row";

export const MapToTableDataRow = (headers: any, data: any): TableDataRow => {

  let tableDataItems: TableDataItem[] = [];
  let dataKeys = Object.entries(data);
  let headKeys = Object.keys(headers).map((h)=>h.toLowerCase());
  
  let dataTable = dataKeys.map((d) => [d[0].toLowerCase(),d[1]]);

  headKeys.map((h) => {
    let val = "";
    dataTable.map((d)=>{  
      if(h===d[0]){
        val=String(d[1]);
      }
    });
    if (val!=="") {
      tableDataItems.push(new TableDataItem(h, val));
      
    }
    else{
      tableDataItems.push(new TableDataItem(h, " - "));
    }
  });
  return new TableDataRow(data.id,tableDataItems);
};
