import { bemCN } from "../../../../configs/bem-classname";
import "./task-modal-item.scss";
import { FC, useEffect, useState } from "react";
import { Button } from "../../../../ui/button/button";
import { ReactComponent as Cross } from "../../../../shared/images/svg/cross.svg";
import { ReactComponent as Additionaly } from "../../../../shared/images/svg/additionally_bold.svg";
import { ReactComponent as Top } from "../../../../shared/images/svg/arrow-top.svg";
import { ReactComponent as Bottom } from "../../../../shared/images/svg/arrow-bottom.svg";
import { ReactComponent as Person } from "../../../../shared/images/svg/profile-grey.svg";
import { ReactComponent as Calendar } from "../../../../shared/images/svg/calendar.svg";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { Task } from "../../../../core/api/data-contracts";
import { TaskGroup } from "../../../../core/entities/task-group";
import { CheckItem } from "../../../../ui/check-item/check-item";
import { InputText } from "../../../../ui/input-text/input-text";

type TaskModalProps = {
  task?: Task;
  group?: TaskGroup;
  isShowDialog: boolean;
  setIsShowDialog(isShow: boolean): void;
};

export const TasksModalItem: FC<TaskModalProps> = ({
  task,
  group,
  setIsShowDialog,
  isShowDialog,
}) => {
  const modalCN = bemCN("task_modal");
  let dialog: HTMLDialogElement | any;

  useEffect(() => {
    document.addEventListener("keydown", (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setIsShowDialog(false);
      }
    });

    dialog = document.getElementById("modalDialog");
    isShowDialog ? dialog?.showModal() : dialog?.close();
  }, [isShowDialog]);

  return isShowDialog ? (
    <dialog id="modalDialog" className={modalCN()}>
      <div className={modalCN("header")}>
        <div className="h3">{group?.name}</div>
        <div>
          <Button image-white icon={<Top />} />
          <Button image-white icon={<Bottom />} />
          <Button image-white icon={<Additionaly />} />
          <Button
            image-white
            icon={<Cross />}
            onClick={() => {
              setIsShowDialog(false);
            }}
          />
        </div>
      </div>
      <div className={modalCN("content")}>
        <div>
          <div className={modalCN("info")}>
            <div>
              <CheckItem
                Active={task && task.active != null && task.active}
                round
              />
              <InputText text={task?.name ? task.name : ""} />
            </div>
            <InputText text={task?.text ? task.text : ""} placeholder="Описание"/>
          </div>
        </div>
        <div className={modalCN("menu", ["h4"])}>
          <p>Папка</p>
          <div>{group?.name}</div>
          <p>Исполнитель</p>
          <div className={modalCN("classic")}>
            <Person />
            Иванов И.И.
          </div>
          <p>Срок выполнения</p>
          <div className={modalCN("classic")}>
            <Calendar />
            {task?.start}
          </div>
          <div className={modalCN("doc")}>
            Связанные документы
            <Plus />
          </div>
          <div className={modalCN("doc_list")}>Закупка №12456</div>
        </div>
      </div>
    </dialog>
  ) : null;
};
