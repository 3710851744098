import "./table-item.scss";
import { bemCN } from "../../../configs/bem-classname";
import { ReactComponent as CheckMark } from "../../../shared/images/svg/check-mark-round.svg";
import { ReactComponent as Cross } from "../../../shared/images/svg/cross.svg";
import { ReactComponent as DoubleArrow } from "../../../shared/images/svg/double-arrow.svg";
import { Button } from "../../../ui/button/button";

const tableItemCN = bemCN("table-item");

export const availableTableItems = ["header", "text"] as const;

type TableItemProps = {
  [typeItem in (typeof availableTableItems)[number]]?: boolean;
} & {
  id?: string;
  value?: string | number | null;
  link?: string;
  isSetting?: boolean;
  isNew?: boolean;
  color?: string;
  isSortable?:boolean;
  onClick?: () => void;
  onDelete?: () => void;
  onUpdate?: () => void;
};

export const TableItem = (props: TableItemProps) => {
  let {
    id,
    value,
    link,
    color,
    isSetting,
    isNew,
    isSortable,
    onClick,
    onDelete,
    onUpdate,
  } = props;
  const adding_class = availableTableItems.find((i) => props[i]) ?? "";
  let type = adding_class;
  let className = tableItemCN(adding_class, ["h5"]);
  return type === "header" ? (
    <th onClick={onClick} id={id} className={className}>
      <div>
        {value} {isSortable&&<DoubleArrow />}
      </div>
    </th>
  ) : (
    <td
      title={value?.toString()}
      style={{ color: color }}
      className={className}
      onClick={onClick}
    >
      {link !== undefined && value !== undefined ? (
        <a href={link}>{value}</a>
      ) : value !== undefined ? (
        value
      ) : (
        "-"
      )}
      {isSetting && (
        <div className={tableItemCN("adding")}>
          <div></div>
          {isNew ? (
            <Button icon={<CheckMark />} image-white onClick={onUpdate} />
          ) : (
            <Button icon={<Cross />} image-white onClick={onDelete} />
          )}
        </div>
      )}
    </td>
  );
};
