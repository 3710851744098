import { bemCN } from "../../configs/bem-classname";
import "./sidebar-component.scss";
import { ReactComponent as Logo } from "../../shared/images/svg/logo.svg";


import { menu } from "../../consts/sidebar-links";
import SideBarItem from "./sidebar-item-component/sidebar-item-component";
import { useState } from "react";
import Toggle from "../../ui/toggle/toggle";
const sidebarCn = bemCN("sidebar");

const SideBar = () => {
  const [activeId, setActiveId] = useState<number | null>(null);
  return (
    <div className={sidebarCn()}>
      <div className={sidebarCn("logo")}>
        <div><Logo /></div>
        <div className="h2">STAT</div>
      </div>
      {menu.map((item, i) => {
        return (
          <SideBarItem
            key={item.id}
            id={item.id}
            title={item.title}
            icon={item.icon}
            path={item?.path}
            links={item?.links}
            active={activeId === i}
            setActive={setActiveId}
          />
        );
      })}
      <Toggle/>
    </div>
  );
};

export default SideBar;
