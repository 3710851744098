import { memo, useEffect, useMemo, useState } from "react";
import "./order-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as ArrowBack } from "../../../../shared/images/svg/arrow-back.svg";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../../../../components/table-component/table-component";
import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import { API } from "../../../../consts/api";
import {
  OrderGoodModel,
  OrderTableModel,
  Supply,
} from "../../../../core/api/data-contracts";
import Loading from "../../../../components/loading-component/loading-component";
import Selected from "../../../../components/selected-component/selected-component";
import { SelectItem } from "../../../../core/view-models/select-item";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { ReactComponent as Delete } from "../../../../shared/images/svg/delete.svg";
import { SupplyComponent } from "../supply-component/supply-component";
import { GuidGenerator } from "../../../../shared/helpers/guid-generator";

const ordersCN = bemCN("orders-card");

export const OrderCard = memo(() => {
  const [order, setOrder] = useState<OrderTableModel>();
  const [headers, setHeaders] = useState<any>();
  const [IsLoading, SetIsLoading] = useState(false);
  const [Error, SetError] = useState<Error>();
  const [IsEdit, SetIsEdit] = useState(false);
  const [expenseType, setExpenseType] = useState<any[]>([]);
  const [isAddingExpense, setIsAddingExpense] = useState(true); //Показывает, можно ли добавить еще один вид расходов
  const { id } = useParams();

  const filterAvailableGoods = useMemo(() => {
    return (
      order?.goods &&
      order?.goods.filter((f) => {
        let res = true;
        order?.supplies?.map((s) => {
          s.boxes?.map((b) => {
            if (b.goods?.find((g) => g.goodId == f.goodId)) {
              res = false;
              return;
            }
          });
        });
        return res;
      })
    );
  }, [order]);
  const filterBlockGoods = useMemo(() => {
    return (
      order?.goods &&
      order?.goods
        ?.map((f, i) => i)
        .filter((f) => {
          let res = false;
          order?.supplies?.map((s) => {
            s.boxes?.map((b) => {
              if (
                b.goods?.find(
                  (g) => order?.goods && g.goodId == order?.goods[f]?.goodId
                )
              ) {
                res = true;
                return;
              }
            });
          });
          return res;
        })
    );
  }, [order]);
  //Получение типов расходов
  useEffect(() => {
    API.expenseGetAllItemsList().then((res) => {
      setExpenseType(res.data.items);
    });
  }, []);
  //Получение товара по id
  useEffect(() => {
    if (id) {
      API.orderDetail(Number(id))
        .then((res) => {
          setOrder(res.data);
          SetIsLoading(true);
        })
        .catch((e) => {
          SetError(e);
          SetIsLoading(false);
        });
    } else {
      SetIsLoading(true);
    }
  }, [id]);
  useEffect(() => {
    API.orderGetNameGoodFieldsList().then((res) => {
      setHeaders(res.data);
    });
  }, []);
  let navigate = useNavigate();

  //Изменение суммы расходов
  const updatePriceExpense = (id: number, value: number | string) => {
    if (value === "") {
      value = 0;
    }
    let expenses = order?.expenses;
    let updateExpense = expenses?.find((e: any) => e.expenseId === id);
    updateExpense!.price = Number(value);
    let newExpenses = expenses?.map((e: any) => {
      if (e.expenseId !== id) return e;
      else return updateExpense;
    });
    setOrder({ ...order, expenses: newExpenses });
  };

  //Удаление расходов
  const deleteExpense = (id: number) => {
    let expenses = order?.expenses;
    expenses = expenses?.filter((e: any) => e.expenseId !== id);
    setOrder({ ...order, expenses: expenses });
    setIsAddingExpense(true);
  };

  //Добавление сборочного задания
  const addGoodToBox = (idBox: string, orders: OrderGoodModel[]) => {
    let supplies = order?.supplies;
    supplies?.map((s) => {
      s.boxes?.map((b) => {
        if (idBox == b.id) {
          orders.map((o) => {
            b.goods?.push(o);
          });
          setOrder({ ...order, supplies: supplies });
          return;
        }
      });
    });
  };
  //Добавление Поставки
  const addSupply = (name: string) => {
    let supplies = order?.supplies;
    supplies?.push({
      id: Date.now().toString(),
      done: false,
      name: name,
      boxes: [],
    });
    setOrder({ ...order, supplies: supplies });
  };
  //Обновление Поставки
  const updateSupply = (supply: Supply) => {
    let supplies = order?.supplies;
    supplies?.map((s) => {
      if (s.name == supply.id) {
        s.boxes = supply.boxes;
      }
    });
    setOrder({ ...order, supplies: supplies });
  };
  //Удаление поставки
  const delSupply = (idSupply?: string | null) => {
    let supplies = order?.supplies?.filter((b) => b?.id != idSupply);
    setOrder({ ...order, supplies: supplies });
  };

  //Закрытие поставки
  const onCloseSupply = (supplyId?: string) => {
    order &&
      API.orderCloseSupplyUpdate(order, { supplyId: supplyId }).then((res) => {
        setOrder(res.data);
      });
  };

  //Закрытие поставки
  const onCancelGood = (index: number) => {
    let orderId = order?.goods![index].goodId!;
    API.orderCancelOrderPartialUpdate({ orderId }).then(() => {
      setOrder({ ...order, goods: order?.goods });
    });
  };

  //Добавление короба
  const addBox = (idSupply: string) => {
    let supplies = order?.supplies;
    supplies?.map((s) => {
      if (s.id == idSupply) {
        s.boxes?.push({
          id: GuidGenerator().toString(),
          goods: [],
        });
      }
    });
    setOrder({ ...order, supplies: supplies });
  };

  
  //Сохранение изменений заказа
  const onSaveOrder = () => {
    order&&API.orderUpdateUpdate(order).then((res) => {
      SetIsEdit(false);
    });
  };

  //Удаление короба
  const delBox = (idSupply: string, idsBoxes: string[]) => {
    let supplies = order?.supplies;
    supplies?.map((s) => {
      if (s.id == idSupply) {
        s.boxes = s.boxes?.filter((b) => b?.id && idsBoxes.includes(b.id));
      }
    });
    setOrder({ ...order, supplies: supplies });
  };
  //Удаление товара в коробе
  const onDelGoodBox = (idSupply: string, idBox: string, index: number) => {
    let supplies = order?.supplies;
    let goods = supplies
      ?.find((s) => s.id == idSupply)
      ?.boxes?.find((b) => (b.id = idBox))?.goods;
    let id = goods?.[index].goodId;
    id && goods?.splice(index, 1);
    id && setOrder({ ...order, supplies: supplies });
  };
  //Добавление расходов
  const addExpense = () => {
    let expenses = order?.expenses;
    let exs = expenseType.filter(
      (e: any) => !expenses?.map((ex: any) => ex.expenseId).includes(e.id)
    );
    if (exs.length > 0) {
      let newId = Math.max(...exs.map((e: any) => e.id));
      expenses?.push({
        expenseId: newId,
        name: expenseType.find((e: any) => e.id === newId).name,
        price: 0,
      });
      setOrder({ ...order, expenses: expenses });
      exs.length === 1 && setIsAddingExpense(false);
    }
  };
  //Изменение названия расходов
  const updateNameExpense = (id: number, value: number) => {
    let expenses = order?.expenses;
    let updateExpense = expenses?.find((e: any) => e.expenseId === id);
    updateExpense!.expenseId = value;
    updateExpense!.name = expenseType.find((e: any) => e.id === value).name;
    let newExpenses = expenses?.map((e: any) => {
      if (e.expenseId !== id) return e;
      else return updateExpense;
    });
    setOrder({ ...order, expenses: newExpenses });
  };

  if (Error) {
    throw Error;
  }
  if (!IsLoading) {
    return <Loading color="dark" />;
  }
  return (
    <div className={ordersCN()}>
      <div className={ordersCN("header")}>
        <div>
          <button onClick={() => navigate(-1)}>
            <ArrowBack />
          </button>
          <h2>Заказ №{order?.number}</h2>
          <h2 className={ordersCN("header_date")}>
            {order?.dateTimeStartString?.split(" ")[0]}
          </h2>
          <h2 className={ordersCN("header_date")}>
            {order?.dateTimeStartString?.split(" ")[1]}
          </h2>
        </div>
        <div>
          <span className={ordersCN("header_status", ["h4"])}>
            {order?.status}
          </span>
          <div className={ordersCN("header_author", ["h3"])}>Петров</div>
        </div>
      </div>
      <div className={ordersCN("info")}>
        <div>
        <InputText
          border-all
          isActive ={!IsEdit}
          header="Срок:"
          text={
             order?.dateTimeEndString?.split(" ")[0].toString()
          }
        />
        <InputText
          border-all
          isActive ={!IsEdit}
          text={
                order?.dateTimeEndString?.split(" ")[1].toString().slice(0, -3)
          }
        />
        </div>
        
        <InputText
          header="Тип:"
          text={order?.deliveryType ? order?.deliveryType : ""}
          isActive
        />
        <InputText
          header="Склад продавца:"
          text={order?.sellerStore ? order?.sellerStore : ""}
          isActive
        />
        <InputText
          header="Итоговая стоимость в руб.:"
          text={order?.priceSelling ? order?.priceSelling.toString() : ""}
          isActive
        />
        <InputText
          header="Покупатель:"
          text={order?.buyer ? order?.buyer : ""}
          isActive
        />
        <InputText
          header="Адрес покупателя:"
          text={order?.buyerAddress ? order?.buyerAddress : ""}
          isActive
        />
        <InputText
          header="Телефон покупателя:"
          text={order?.buyerPhone ? order?.buyerPhone : ""}
          isActive
        />
        <InputText
          header="Комментарий:"
          text={order?.comment ? order?.comment : ""}
          isActive = {!IsEdit}
        />
      </div>
      <h3 className="h3">Расходы</h3>
      {order && order.expenses && order.expenses.length > 0
        ? order.expenses.map((item: any) => {
            return (
              <div key={item.expenseId}>
                <div className="expen">
                  <div>
                    <InputText
                      header="Сумма:"
                      text={item ? item.price : ""}
                      isActive={!IsEdit}
                      isDigitOnly
                      OnChange={(v) => updatePriceExpense(item.expenseId, v)}
                    />
                  </div>
                  <div>
                    {order && !IsEdit ? (
                      <InputText
                        header="Расходы:"
                        text={item ? item.name : ""}
                        isActive={true}
                      />
                    ) : (
                      <>
                        {" "}
                        <div className={ordersCN("selected")}>
                          <p className="h4">Расходы:</p>
                          <Selected
                            OnChange={(v) => {
                              updateNameExpense(item.expenseId, v);
                            }}
                            active={item.expenseId}
                            items={expenseType
                              .filter(
                                (expense: any) =>
                                  expense.id === item.expenseId ||
                                  !(
                                    order!.expenses!.filter(
                                      (e: any) => e.expenseId === expense.id
                                    ).length > 0
                                  )
                              )
                              .map((exp: any) => {
                                return new SelectItem(exp.id, exp.name);
                              })}
                          />
                        </div>
                      </>
                    )}
                  </div>

                  <button onClick={() => deleteExpense(item.expenseId)}>
                    <Delete />
                  </button>
                </div>
              </div>
            );
          })
        : null}
      {IsEdit && isAddingExpense ? (
        <div className={ordersCN("add-btn")}>
          <Plus />
          <Button white text="Добавить расходы" onClick={() => addExpense()} />
        </div>
      ) : null}

      <div className={ordersCN("buttons")}>
        <Button
          text={"Закрыть"}
          white-outline
          onClick={() => {
            if (IsEdit) {
              order?.id &&
                API.orderDetail(order?.id).then((res) => {
                  setOrder(res.data);
                  SetIsEdit(false);
                });
              SetIsEdit(false);
            } else {
              navigate("/procurement/acceptance");
            }
          }}
        />
        {IsEdit ? (
          <Button onClick={onSaveOrder} text={"Сохранить"} blue />
        ) : (
          <Button text="Редактировать" blue onClick={() => SetIsEdit(true)} />
        )}
      </div>
      <h3 className="h3">Сборка и поставка</h3>

      <SupplyComponent
        IsEdit={true}
        AddSupply={addSupply}
        AllGoods={filterAvailableGoods}
        Supplies={order?.supplies}
        Headers={headers}
        AddGoodToBox={addGoodToBox}
        AddBox={addBox}
        DelBox={delBox}
        OnUpdateSupply={updateSupply}
        DelSupply={delSupply}
        OnCloseSupply={onCloseSupply}
        OnDelGoodBox={onDelGoodBox}
      />
      <h3 className="h3">Наименования</h3>

      <Table
        dataGenerate={order?.goods}
        headersGenerate={headers}
        blockIndexes={filterBlockGoods && filterBlockGoods}
        stickerName="Отмена"
        stickerButtonName="Отменить"
        onClickSticker={onCancelGood}
      />
    </div>
  );
});
