import { bemCN } from "../../configs/bem-classname";
import { categoriesProduct } from "../../consts/values/categories";
import { CategoriesFilter } from "../../modules/filter-module";
import { Goods } from "../../modules/goods-module";

import "./goods-page.scss";

const goodsCN = bemCN("goods-page");

export const GoodsPage = () => {
  return (
    <div className={goodsCN()}>
        <Goods />
      
    </div>
  );
};
    