import { ReactNode, memo } from "react";
import { bemCN } from "../../configs/bem-classname";
import { ReactComponent as CheckMark } from "../../shared/images/svg/check-mark.svg";
import "./select-item.scss";
import React from "react";

const selectCN = bemCN("select-item");

export const availableButtons = ["green"] as const;

type SelectItemProps = {
  [icon in (typeof availableButtons)[number]]?: boolean;
} & {
  id?: string;
  text?: string;
  image?: string;
  icon?: ReactNode;
  onChange?: (check:boolean) => void;
};

export const SelectItem = memo((props: SelectItemProps) => {
  let { id, text, image, icon, onChange } = props;
  const [isTrue, setIsTrue] = React.useState(false);
  const OnClick = () => {
    if (isTrue) {
      setIsTrue(false);
    } else {
      setIsTrue(true);
    }
    onChange && onChange(isTrue)
  };
  const adding_class = availableButtons.find((i) => props[i]) ?? "";
  return (
    <div className={selectCN()} onClick={OnClick}>
      <div className={selectCN("image")}>
        {image && <img src={image}></img>}
        {icon}
      </div>
      <span className="h4">{text}</span>
      {isTrue && <CheckMark className={selectCN("mark", adding_class)} />}
    </div>
  );
});
