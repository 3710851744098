import { bemCN } from "../../../../configs/bem-classname";
import { InputText } from "../../../../ui/input-text/input-text";
import { SearchCheckFilter } from "../../../filter-module";
import { FilterPurchase } from "../purchases-component/purchases-component";
import "./purchases-filter-component.scss";
import { FC, useEffect, useState } from "react";

type PurchasesFilterProps = {
  id?: string;
  filters?: FilterPurchase;
  onChange?: (model: FilterPurchase) => void;
};

export const PurchasesFilter: FC<PurchasesFilterProps> = ({
  id,
  filters,
  onChange,
}) => {
  const purchasesFilterCN = bemCN("purchasesFilter");

  const [model, setModel] = useState<FilterPurchase | undefined>(filters);
  useEffect(() => {
    model && onChange && onChange(model);
  }, [model]);
  return (
    <div id={id} className={purchasesFilterCN()}>
      <div className={purchasesFilterCN("cost")}>
        <p className="h4">Сумма:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.totalPriceSelling?.min
                  ? model.totalPriceSelling.min.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.totalPriceSelling!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.totalPriceSelling?.max
                  ? model.totalPriceSelling.max.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.totalPriceSelling!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <p className="h4">Статус:</p>
        <SearchCheckFilter
          text={model?.purchaseStatus?.join(",")}
          items={
            model && model.purchaseStatusAll ? model.purchaseStatusAll : []
          }
          onChange={(items) => {
            model!.purchaseStatus = [];
            model?.purchaseStatusAll?.map((m) => {
              let s = items.get(m.subjectName);
              m.status = s;
              if (s == true) {
                model!.purchaseStatus?.push(m.subjectName);
              }
            });
            setModel({ ...model });
          }}
        />
      </div>
      <div>
        <p className="h4">Поставщик:</p>
        <SearchCheckFilter
          text={model?.provider?.join(",")}
          items={model && model.providerAll ? model.providerAll : []}
          onChange={(items) => {
            model!.provider = [];
            model?.providerAll?.map((m) => {
              let s = items.get(m.subjectName);
              m.status = s;
              if (s == true) {
                model!.provider?.push(m.subjectName);
              }
            });
            setModel({ ...model });
          }}
        />
      </div>
      <div className={purchasesFilterCN("cost")}>
        <p className="h4">Доставка, дн:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.daysComingStore?.min
                  ? model.daysComingStore.min.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.daysComingStore!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.daysComingStore?.max
                  ? model.daysComingStore.max.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.daysComingStore!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div className={purchasesFilterCN("cost")}>
        <p className="h4">Себестоимость:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.totalCostPrice?.min
                  ? model.totalCostPrice.min.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.totalCostPrice!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.totalCostPrice?.max
                  ? model.totalCostPrice.max.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.totalCostPrice!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
