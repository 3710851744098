import { bemCN } from "../../../../configs/bem-classname";
import { InputText } from "../../../../ui/input-text/input-text";
import { SearchCheckFilter } from "../../../filter-module";
import { FilterOrder } from "../orders-component/orders-component";
import "./orders-filter-component.scss";
import { FC, useEffect, useState } from "react";

type PurchasesFilterProps = {
  id?: string;
  filters?: FilterOrder;
  onChange?: (model: FilterOrder) => void;
};

export const OrdersFilter: FC<PurchasesFilterProps> = ({
  id,
  filters,
  onChange,
}) => {
  const ordersFilterCN = bemCN("purchasesFilter");

  const [model, setModel] = useState<FilterOrder | undefined>(filters);
  useEffect(() => {
    model && onChange && onChange(model);
  }, [model]);
  return (
    <div id={id} className={ordersFilterCN()}>
      <div className={ordersFilterCN("cost")}>
        <p className="h4">Цена продажи:</p>
        <div>
          <div>
            <div className="h4">От</div>
            <InputText
              text={
                model && model.priceSelling?.min
                  ? model.priceSelling.min.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.priceSelling!.min = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
          <div>
            <div className="h4">До</div>
            <InputText
              text={
                model && model.priceSelling?.max
                  ? model.priceSelling.max.toString()
                  : ""
              }
              OnChange={(v) => {
                filters!.priceSelling!.max = Number(v);
                setModel({ ...model });
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <p className="h4">Статус:</p>
        <SearchCheckFilter
          text={model?.status?.join(",")}
          items={model && model.statusAll ? model.statusAll : []}
          onChange={(items) => {
            model!.status = [];
            model?.statusAll?.map((m) => {
              let s = items.get(m.subjectName);
              m.status = s;
              if (s == true) {
                model!.status?.push(m.subjectName);
              }
            });
            setModel({ ...model });
          }}
        />
      </div>
    </div>
  );
};
