import { TableObjectI } from "../interfaces/table-object-i";
import { TableDataItem } from "../view-models/table-data-item";
import { TableDataRow } from "../view-models/table-data-row";

export class Saler implements TableObjectI {
    id:number;
    name:string;
    link: string;
    country:string;
    owner:string;

    constructor(id:number, name: string, link:string, country:string,owner:string) {
        this.id = id;
        this.name = name;
        this.link = link;
        this.country = country;
        this.owner = owner
      }

    mapToTableDataRow(): TableDataRow {
        let tableDataItems: TableDataItem[] = [];
        Object.entries(this).forEach(([key, value]) => {
            if(key!=="id") tableDataItems.push(new TableDataItem(key, value))});
        return new TableDataRow(this.id,tableDataItems);
    }
}