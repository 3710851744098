import { bemCN } from "../../configs/bem-classname";
import { Employeers } from "../../modules/employees-module";


import "./employeers-page.scss";

const employeersCN = bemCN("employeers-page");

export const EmployeersPage = () => {
  return (
    <div className={employeersCN()}>
      <Employeers/>
    </div>
  );
};
    