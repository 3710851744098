import { useEffect, useState } from 'react';
import './toggle.scss';
import { bemCN } from '../../configs/bem-classname';
import { ReactComponent as Moon } from "../../shared/images/svg/moon.svg";
import { ReactComponent as Sun } from "../../shared/images/svg/sun.svg";

const Toggle = () => {
    const toggleCN = bemCN('toggle');

   const [isChecked, setIsCheked] = useState(localStorage.getItem("dark")==null||localStorage.getItem("dark")=="false"? true:false);


   useEffect(() => {
    let body = document.getElementsByTagName('body')[0];
    if(body){
        isChecked ? body.classList.remove("_dark") : body.classList.add("_dark");
    }

  }, [isChecked]);
    return (
      <div className={toggleCN()}>
          <div className={toggleCN("toggle-icon")}><Sun/> </div>
          <label className={toggleCN("check")}>
            <input onChange={() => {setIsCheked(!isChecked);
              localStorage.setItem("dark",isChecked.toString())
            }} className={toggleCN("input")} type="checkbox"></input>
            <span className={toggleCN("slider")}></span>
          </label>
          <div className={toggleCN("toggle-icon")}><Moon/></div>
      </div>
    
    );
  };
  
  export default Toggle;

