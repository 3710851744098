import { bemCN } from "../../configs/bem-classname";
import { ShipmentCard } from "../../modules/shipment-module";
import "./shipment-card-page.scss"

const rootCN = bemCN("ShipmentCardPage");

export const ShipmentCardPage = () => {
  return (
    <div className={rootCN()}>
      <ShipmentCard/>
    </div>
  );
};