import { bemCN } from "../../../../configs/bem-classname";
import "./search-radio-filter.scss";
import { FC, ReactNode, useState } from "react";
import { ReactComponent as ArrowBottom } from "../../../../shared/images/svg/arrow-bottom.svg";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";

import { Button } from "../../../../ui/button/button";
import { InputText } from "../../../../ui/input-text/input-text";
import Popup from "../../../../ui/popup/popup";
import { RadioGroup } from "../../../../components/radio-group-component/radio-group-component";
import { SelectItem } from "../../../../core/view-models/select-item";

type SearcRadioFilterProps = {
  items: SelectItem[];
  placeholder?:string;
  icons?:ReactNode[];
};

export const SearchRadioFilter: FC<SearcRadioFilterProps> = ({ items, placeholder, icons }) => {
  const searchRadioFilterCN = bemCN("searchRadioFilter");

  const [isShow, setIsShow] = useState(false);
  const onClick = () => {
    setIsShow(!isShow);
  };

  return (
    <div className={searchRadioFilterCN()}>
      <div className={searchRadioFilterCN("button")}>
        <Button
          onClick={onClick}
          text-image-right
          text="Не выбрано"
          icon={<ArrowBottom />}
        />
        <Popup isShow={isShow} setIsShow={setIsShow}>
          <InputText icon-right icon={<Search />}  placeholder={placeholder ? placeholder: "Поиск"}  />
          <RadioGroup double-image items={items} icons={icons} />
        </Popup>
      </div>
    </div>
  );
};
