import { bemCN } from "../../../../configs/bem-classname";
import { InputText } from "../../../../ui/input-text/input-text";
import { SearchCheckFilter } from "../../../filter-module";
import "./shipment-filter-component.scss";
import { FC } from "react";

type ShipmentFilterProps = {
  id?: string;
};

export const ShipmentFilter: FC<ShipmentFilterProps> = ({ id }) => {
  const shipmentFilterCN = bemCN("shipmentFilter");

  return (
    <div id={id} className={shipmentFilterCN()}>
      <div>
        <p className="h4">Номер отгрузки:</p>
        <InputText />
      </div>
      <div>
        <p className="h4">Номер счета:</p>
        <InputText />
      </div>
      <div>
        <p className="h4">Покупатель:</p>
        <InputText />
      </div>
      <div>
        <p className="h4">Наша организация:</p>
        <SearchCheckFilter items={["Организация 1", "Организация 2", "Организация 3"]} />
      </div>
      <div>
        <p className="h4">Товра:</p>
        <InputText />
      </div>

    </div>
  );
};
