import React from "react";
import AppRouter from "./configs/app-router";
import "./shared/styles/index.scss";
import AuthProvider from "./components/auth-provider/auth-provider";
import { Provider } from "react-redux";

function App() {
  return (
    <AuthProvider>
      <AppRouter />
    </AuthProvider>
  );
}

export default App;
