import { bemCN } from "../../../../configs/bem-classname";
import "./search-check-filter.scss";
import { FC, useState } from "react";
import { ReactComponent as ArrowBottom } from "../../../../shared/images/svg/arrow-bottom.svg";
import { ReactComponent as Search } from "../../../../shared/images/svg/search.svg";

import { Button } from "../../../../ui/button/button";
import { InputText } from "../../../../ui/input-text/input-text";
import { CheckGroup } from "../../../../components/check-group-component/check-group-component";
import Popup from "../../../../ui/popup/popup";

type SearchCheckFilterProps = {
  items: any[];
  text?:string,
  placeholder?: string;
  onChange?: (itemIndex: Map<any, any>) => void;
};

export const SearchCheckFilter: FC<SearchCheckFilterProps> = ({
  items,
  text,
  placeholder,
  onChange,
}) => {
  const SearchCheckFilterCN = bemCN("searchCheckFilter");

  const [isShow, setIsShow] = useState(false);
  const onClick = () => {
    setIsShow(!isShow);
  };

  return (
    <div className={SearchCheckFilterCN()}>
      <div className={SearchCheckFilterCN("button")}>
        <Button
          onClick={onClick}
          text-image-right
          text={text}
          icon={<ArrowBottom />}
        />
        <Popup isShow={isShow} setIsShow={setIsShow}>
          {/* <InputText icon-right icon={<Search />}  placeholder={placeholder ? placeholder: "Поиск"}  /> */}
          <CheckGroup items={items} onChange={onChange} />
        </Popup>
      </div>
    </div>
  );
};
