import React, { FC, useRef } from "react";
import { Swiper, SwiperSlide, SwiperClass } from "swiper/react";
import "./review-slider-component.scss";
import 'swiper/swiper-bundle.min.css';
import { Navigation } from "swiper";
import ReviewSlide from "../review-slide-component/review-slide-component";
import { reviews } from "../../../consts/reviews";
import { ReactComponent as Right } from "../../../shared/images/svg/arrow-right-line.svg";
import { ReactComponent as Left } from "../../../shared/images/svg/arrow-left-line.svg";
import { bemCN } from "../../../configs/bem-classname";

interface ReviewSliderProps {}
const topPageCn = bemCN("reviewSlider");
const ReviewSlider: FC<ReviewSliderProps> = () => {
  const swiperRef = useRef<SwiperClass>();

  return (
    <section className={topPageCn()}>
      <div className={topPageCn("content")}>
        <Swiper
          spaceBetween={20}
          navigation={true}
          slidesPerView={2}
          modules={[Navigation]}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {reviews.map((item) => (
            <SwiperSlide key={item.id} style={{ height: "100%" }}>
              <ReviewSlide {...item}></ReviewSlide>
            </SwiperSlide>
          ))}
          <div className={topPageCn("arrows")}>
            <button
              type="button"
              onClick={() => swiperRef?.current?.slidePrev()}
              className={topPageCn("arrow")}
            >
              <Right />
            </button>
            <button
              type="button"
              className={topPageCn("arrow")}
              onClick={() => swiperRef?.current?.slideNext()}
            >
              <Left />
            </button>
          </div>
        </Swiper>
      </div>
    </section>
  );
};

export default ReviewSlider;
