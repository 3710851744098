import { useState } from "react";
import "./shipment-card-component.scss";
import { bemCN } from "../../../../configs/bem-classname";
import { ReactComponent as ArrowBack } from "../../../../shared/images/svg/arrow-back.svg";
import { ReactComponent as Delete } from "../../../../shared/images/svg/delete.svg";
import { ReactComponent as Clip } from "../../../../shared/images/svg/clip.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { InputText } from "../../../../ui/input-text/input-text";
import { Button } from "../../../../ui/button/button";
import {
  assemblyGoodItems,
  headersAssemblyGoodTable,
} from "../../../../consts/values/assembly-goods";
import Table from "../../../../components/table-component/table-component";
import { ReactComponent as TrashBin } from "../../../../shared/images/svg/trash-bin.svg";
import { Modal } from "../../../../components/modal-component/modal-component";
import { DocumentsModal } from "../../../../components/modal-component/document-modal-component/document-modal-component";

const shipmentCardCN = bemCN("shipmentCard");

export const ShipmentCard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [shipment, SetShipment] = useState(
    location.state && location.state.stock
  );
  const [IsEdit, SetIsEdit] = useState(shipment ? false : true);
  const [isShowDialog, setIsShowDialog] = useState(false);

  return (
    <div className={shipmentCardCN()}>
      <div className={shipmentCardCN("header")}>
        <div>
          <button
            onClick={() => {
              shipment && IsEdit
                ? SetIsEdit(false)
                : navigate("/sales/shipment");
            }}
          >
            <ArrowBack />
          </button>
          <h2>{shipment ? shipment.fullName : "Оформление отгрузки"}</h2>
        </div>
        <div>
          <span className="h4">Не поставлено</span>
          <span className="h2">Петров В.В.</span>
        </div>
      </div>
      <div className={shipmentCardCN("info")}>
        <div>
          <InputText
            header="Контрагент:"
            text={shipment ? shipment.fullName : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Контактное лицо:"
            text={shipment ? shipment.shortName : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Наша организация:"
            text={shipment ? shipment.shortName : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Склад:"
            text={shipment ? shipment.shortName : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>

      <div className={shipmentCardCN("info")}>
        <div>
          <InputText
            header="НДС:"
            text={shipment ? shipment.address : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Сотрудник:"
            text={shipment ? shipment.comment : ""}
            isActive={!IsEdit}
          />
        </div>
        <div className={shipmentCardCN("info_com")}>
          <InputText
            id="comment"
            header="Комментарий:"
            text={shipment ? shipment.comment : ""}
            isActive={!IsEdit}
          />
        </div>
      </div>

      <div className={shipmentCardCN("info", ["expen"])}>
        <div>
          <InputText
            header="Сумма:"
            text={shipment ? shipment.address : ""}
            isActive={!IsEdit}
          />
        </div>
        <div>
          <InputText
            header="Расходы:"
            text={shipment ? shipment.comment : ""}
            isActive={!IsEdit}
          />
          <button>
            <Delete />
          </button>
        </div>
      </div>
      <div>
        <span className="plus">+</span>
        <span className="h4">Расходы</span>
      </div>

      <div className={shipmentCardCN("table_head")}>
        <div>
          <span className={shipmentCardCN("table_head_active", ["h3"])}>
            Наименования
          </span>
          <span className={shipmentCardCN("table_head_count", ["h3"])}>12</span>
        </div>
        <div className={shipmentCardCN("table_head_buttons")}>
          {/* <Button id="add" text="+" circle />
          <Button text="Документы" />
          <Button
            text="Прикрепить"
            icon={<Clip />}
            onClick={() => {
              setIsShowDialog(true);
            }}
            text-image-left
          />
          <Button text="Готово" blue /> */}
          <p className="h2">12 000.58</p>
        </div>
      </div>
      <Table
        isEditable={true}
        data={assemblyGoodItems}
        headers={headersAssemblyGoodTable}
      />
      <div className={shipmentCardCN("buttons")}>
        <Button text={"Закрыть"} white-outline />
        <Button image-red icon={<TrashBin />} />
      </div>

      <Modal
        title="Прикрепленные документы"
        isShowDialog={isShowDialog}
        setIsShowDialog={setIsShowDialog}
      >
        <DocumentsModal />
      </Modal>
    </div>
  );
};
