import "./bar-chart.scss";
import { bemCN } from "../../configs/bem-classname";
import { memo } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { BarChartDataItem } from "../../core/view-models/bar-chart-data-item";
import { StatisticsItemModel } from "../../core/api/data-contracts";
import { count } from "console";

export const availableColor = ["blue", "green"] as const;

type CustomBarChartProps = {
  [icon in (typeof availableColor)[number]]?: boolean;
} & {
  id?: string;
  data?: StatisticsItemModel[];
  isCount?: boolean;
  labelY?: string;
  labelX?: string;
  width?: number;
  height?: number;
};

const colors = new Map<string, string[]>([
  ["green", ["rgba(65, 188, 168, 0.8)", "rgba(65, 188, 168, 0.25)"]],
  ["blue", ["rgba(81, 149, 191, 0.8)", "rgba(81, 149, 191, 0.25)"]],
]);
export const CustomBarChart = memo((props: CustomBarChartProps) => {
  let { labelY, data, width, height, labelX, isCount } = props;
  const colorType = availableColor.find((i) => props[i]) ?? "";
  let colorGradient = colorType ? colors.get(colorType) : colors.get("blue");
  let idGradient = colorType ? colorType : "blue";
  let w = width !== undefined ? width : 854;
  let h = height !== undefined ? height : 378;

  const customBarChartCN = bemCN("barChart");

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={customBarChartCN("custom-tooltip")}>
          <div className={customBarChartCN("label", ["h5"])}>{label}:</div>
          <div className={customBarChartCN("value", ["h5"])}>
            {payload[0].value} {isCount ? "шт." : "руб."}
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={customBarChartCN()}>
      <ResponsiveContainer >
        <BarChart
          width={w}
          height={h}
          data={data}
          margin={{ top: 0, right: 0, left: 5, bottom: 0 }}
        >
          <CartesianGrid vertical={false} />
          <defs>
            <linearGradient id={idGradient} x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={colorGradient && colorGradient[0]} />
              <stop
                offset="95%"
                stopColor={colorGradient && colorGradient[1]}
              />
            </linearGradient>
          </defs>
          <XAxis
            dy={4}
            style={{
              fontSize: "12px",
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: "500",
              fill: "#222",
            }}
            axisLine={false}
            dataKey="name"
          />
          <YAxis
            style={{
              fontSize: "12px",
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: "500",
              fill: "#222",
            }}
            axisLine={false}
            allowDecimals={false}
            domain={[0, 'dataMax']}
            tickCount={7}
          >
            <Label
              offset={0}
              value={labelY}
              angle={-90}
              position={"insideLeft"}
              style={{
                textAnchor: 'middle',
                fontSize: "14px",
                fontFamily: "Manrope",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "33px",
                fill: "#222",
              }}
            />
          </YAxis>
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
          />

          <Bar
          maxBarSize={200}
            activeBar={false}
            dataKey={isCount ? "count" : "value"}
            fill={`url(#${idGradient})`}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
});

export default BarChart;
