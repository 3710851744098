export const monthsShort = [
  'янв',
  'фев',
  'мар',
  'апр',
  'май',
  'июн',
  'июл',
  'авг',
  'сен',
  'окт',
  'ноя',
  'дек',
];

export const weekdaysShort = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

export const calendarSelctedDate = (date?: Date | null) => {
  if (!date) return;

  const weekday = weekdaysShort[date.getDay()];
  const month = monthsShort[date.getMonth()];

  return `${weekday}, ${date.getDate()} ${month}`;
};
export const calendarDateMonths = (date?: Date | null) => {
  if (!date) return;

  const weekday = weekdaysShort[date.getDay()];
  const month = monthsShort[date.getMonth()];

  return `${date.getDate()} ${month}`;
};
