import React, { useState } from 'react';
import './main-tariff-item-component.scss';
import { Tariff } from '../../../core/entities/tariff';
import { bemCN } from '../../../configs/bem-classname';
import { ReactComponent as CheckMarkBlue } from "../../../shared/images/svg/check-mark-blue.svg";
import { ReactComponent as CheckMark } from "../../../shared/images/svg/check-mark-white.svg";

export interface MainTariffItemProps {
  tariff: Tariff;

  //period: string;
  //periodMonths?: number;
  //price?: number | null;
  isPopular?: boolean;
  //advantages: string[];
}

const mainTariffItemPageCn = bemCN('MainTariffItem');
const MainTariffItem = ({
  tariff,
  //period,
  //periodMonths,
  //price,
  isPopular,
} //advantages,
: MainTariffItemProps) => {
  const totalPrice =
    tariff.basePrice == 0 ? 'Бесплатно' : `${(tariff.basePrice / 100).toLocaleString()} ₽`;

  const pricePerMonth =
    (tariff.basePrice &&
      tariff.durationMonths &&
      `${Math.floor(tariff.basePrice / 100 / tariff.durationMonths).toLocaleString()} ₽ в месяц`) ||
    'Начать сейчас';

  const [isHover, setIsHover] = useState(false);
  return (
    <div
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      className={mainTariffItemPageCn()}
    >
      {isPopular && <div className={mainTariffItemPageCn('popular')}>Популярный</div>}
      <div className={mainTariffItemPageCn('period')}>{tariff.name}</div>
      <div className={mainTariffItemPageCn('price')}>{totalPrice}</div>
      <button
        onClick={() => {
          pricePerMonth == 'Начать сейчас' && (window as any).ym(97507982,'reachGoal', 'checkFreeButtonClick');
        }}
        type="button"
        className={mainTariffItemPageCn('button')}
      >
        {pricePerMonth}
      </button>
      <ul className={mainTariffItemPageCn('list')}>
        {tariff.description?.map((item: string) => (
          <li key={item} className={mainTariffItemPageCn('item')}>
            {isHover ? <CheckMark/> :<CheckMarkBlue/>}
            <span>{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MainTariffItem;
