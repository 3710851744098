import './check-group.scss';
import { FC, useEffect, useState } from 'react';
import { ReactComponent as CheckMark } from '../../../shared/images/svg/check-mark.svg';
import { bemCN } from '../../../configs/bem-classname';


type CheckGroupProps = {
  items: any[];
  activeIds: number[];
  onChange(ids: number[]): void;
};

export const CheckGroup: FC<CheckGroupProps> = ({ activeIds, items, onChange }) => {
  const checkGroupCN = bemCN('checkGroup');
  const [checkedIds, setChekedIds] = useState(activeIds);
  const [isAll, setIsAll] = useState(false);

  const onClickItem = (id:number) =>{
    checkedIds.includes(id) ? setChekedIds([...checkedIds.filter((i) => i!==id)]) : setChekedIds([...checkedIds.concat(id)]);
  }

  const onClickAll = () =>{
    if(isAll){
      setIsAll(false);
      setChekedIds([]);

    }
    else{
      setIsAll(true);
      setChekedIds(items.map((item:any) => item.id));

    }
  }

  useEffect(() =>{
    onChange(checkedIds);
  }, [checkedIds])

  return (
    <div className={checkGroupCN()}>
      <div onClick={() => onClickAll()} className={checkGroupCN('checkItem')}>
      <div>Выбрать все</div>
      <div className={isAll ? checkGroupCN("active") : ""}>  {isAll ? <CheckMark/> : null}</div>
        
      </div>
      {items &&
        items.map((item) => (
          <div onClick={() => onClickItem(item.id)} key={item.id} className={checkGroupCN('checkItem')}>
            <div>{item.value}</div>
            <div className={checkedIds.includes(item.id) ? checkGroupCN("active") : ""}>  {checkedIds.includes(item.id) ? <CheckMark/> : null}</div>
          </div>
        ))}
    </div>
  );
};
