import { useEffect, useState } from "react";
import { bemCN } from "../../../../configs/bem-classname";
import "./task-group-component.scss";
import { ReactComponent as Additionally } from "../../../../shared/images/svg/additionally_bold.svg";
import { TaskGroup } from "../../../../core/entities/task-group";
import { TaskItem } from "../task-item-component/task-item-component";
import { Button } from "../../../../ui/button/button";
import { ReactComponent as Plus } from "../../../../shared/images/svg/plus.svg";
import { ReactComponent as Pencil } from "../../../../shared/images/svg/pencil.svg";
import { ReactComponent as Delete } from "../../../../shared/images/svg/trash-bin.svg";
import Popup from "../../../../ui/popup/popup";
import { NewTaskItem } from "../task-new-item-component/task-new-item-component";
import { GuidGenerator } from "../../../../shared/helpers/guid-generator";
import { Task } from "../../../../core/api/data-contracts";

const groupCN = bemCN("group");
type TaskGroupProps = {
  taskGroup: TaskGroup;
  tasks: Task[];
  OnDelete?: (id: number) => void;
  OnSave?: (id: number, name: string) => void;
  OnAddTask?: (task: Task) => void;
  OnUpdateTask?: (task: Task) => void;
  OnDuplicateTask?: (task: Task) => void;
  OnDeleteTAsk?: (id: number) => void;
};

export const TaskGroupItem = (props: TaskGroupProps) => {
  let {
    taskGroup,
    tasks,
    OnDelete,
    OnSave,
    OnAddTask,
    OnUpdateTask,
    OnDeleteTAsk,
    OnDuplicateTask,
  } = props;
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [name, setName] = useState(taskGroup.name);
  const onUpdate = () => {
    setIsUpdate(true);
    setIsShowMenu(false);
  };
  const onSave = () => {
    setIsUpdate(false);
    OnSave && OnSave(taskGroup && taskGroup.id ? taskGroup.id : -1, name);
  };
  return (
    <div className={groupCN()}>
      <div className={groupCN("head")}>
        <div>
          {isUpdate ? (
            <div className={groupCN("head_update")}>
              <input
                className="h4"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <div className={groupCN("buttons")}>
                <Button
                  text="Сохранить"
                  onClick={() => {
                    onSave();
                  }}
                />
                <Button
                  text="Отмена"
                  onClick={() => {
                    setName(taskGroup.name);
                    setIsUpdate(false);
                  }}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="h4">{name}</div>
              <div className={groupCN("head_grey", ["h4"])}>{tasks.length}</div>
            </>
          )}
        </div>
        <button onClick={() => setIsShowMenu(true)}>
          <Additionally />
        </button>
        {isShowMenu && (
          <Popup isShow={isShowMenu} setIsShow={setIsShowMenu}>
            <Button
              icon={<Pencil />}
              onClick={onUpdate}
              text="Изменить"
              text-image-left
            />
            <Button
              id="del"
              onClick={() =>
                OnDelete &&
                OnDelete(taskGroup && taskGroup.id ? taskGroup.id : -1)
              }
              icon={<Delete />}
              text="Удалить"
              text-image-left
            />
          </Popup>
        )}
      </div>
      <div className={groupCN("tasks")}>
        {tasks.map((task) => {
          return <TaskItem Group={taskGroup} OnDelete={OnDeleteTAsk} OnDuplicate={OnDuplicateTask} OnUpdate={OnUpdateTask} key={task.id+GuidGenerator()} Task={task} />;
        })}
      </div>
      {isAdd ? (
        <NewTaskItem
          OnClose={() => setIsAdd(false)}
          OnAddNew={OnAddTask}
          FolderId={taskGroup.id}
        />
      ) : (
        <Button
          text="Добавить задачу"
          id={"addTask"}
          text-image-left
          icon={<Plus />}
          onClick={() => setIsAdd(true)}
        />
      )}
    </div>
  );
};
