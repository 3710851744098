import { bemCN } from "../../configs/bem-classname";
import { Shipments } from "../../modules/shipment-module";
import "./shipments-page.scss"
const rootCN = bemCN("shipment");

export const ShipmentPage = () => {
  return (
    <div className={rootCN()}>
      <Shipments/>
    </div>
  );
};