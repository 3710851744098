import React, { FC } from "react";
import "./top-component.scss";
import ClientAuthLink from "../client-auth-component/client-auth-component";
import { bemCN } from "../../../configs/bem-classname";
import { links } from "../../../consts/links";
import { ReactComponent as Logo } from "../../../shared/images/svg/logo.svg";
import { ReactComponent as Bottom } from "../../../shared/images/svg/arrow-bottom.svg";
import { ReactComponent as BackChart } from "../../../shared/images/svg/round-chart-main.svg";
import { ReactComponent as ChartsHome1 } from "../../../shared/images/svg/chart-home1.svg";
import { ReactComponent as ChartsHome2 } from "../../../shared/images/svg/chart-home2.svg";
import { ReactComponent as ChartsHome3 } from "../../../shared/images/svg/chart-home3.svg";
import { ReactComponent as Shadow } from "../../../shared/images/svg/shadow-chart.svg";

interface MainTopProps {}

const topPageCn = bemCN("mainTop");
const MainTop: FC<MainTopProps> = () => (
  <div className={topPageCn()}>
    <div className="container-client">
      <div className={topPageCn("ClientHeader")}>
        <div className={topPageCn("logo")}>
          <Logo />
          <div className={topPageCn("logo_text")}>STAT</div>
        </div>
        <div className={topPageCn("links")}>
          <a href={`#tariff_section`} className={topPageCn("links_link")}>
            <div className={topPageCn("links_text")}>Тарифы</div>
            <Bottom/>
          </a>
          <a href={`#advantages_section`} className={topPageCn("links_link")}>
            <div className={topPageCn("links_text")}>Возможности</div>
            <Bottom/>
          </a>
          <a href={links.blog} className={topPageCn("links_link")}>
            <div className={topPageCn("links_text")}>Блог</div>
          </a>
        </div>
        <ClientAuthLink />
      </div>
    </div>
    <div className={topPageCn("info")}>
      <div className={topPageCn("info_text")}>
        <div className={topPageCn("title")}>
          <span className={topPageCn("title_green")}>Упростите управление</span>
          <br />
          вашим бизнесом на
          <br />
          <span className={topPageCn("title_green")}>маркетплейсах!</span>
        </div>
        <div className={topPageCn("description")}>
          Все инструменты для успешной торговли в одном месте. Управляйте
          заказами, закупками и рекламой с легкостью.
          <br />
          <span className={topPageCn("title_green")}>
            Начните бесплатно уже сегодня!
          </span>
        </div>
        <button type="button" className={topPageCn("button")}>
          Попробовать бесплатно
        </button>
      </div>
      <div className={topPageCn("info_svgs")}>
        <div><Shadow/></div>
        <div className={topPageCn("info_svgs_1")}><ChartsHome1/></div>
        <div className={topPageCn("info_svgs_2")}><ChartsHome2/></div>
        <div className={topPageCn("info_svgs_3")}><ChartsHome3/></div>
      </div>
      <div className={topPageCn("info_back")}><BackChart/></div>
    </div>
  </div>
);

export default MainTop;
