import { bemCN } from "../../../../configs/bem-classname";
import "./task-new-item-component.scss";
import { useState } from "react";
import { ReactComponent as Cross } from "../../../../shared/images/svg/cross.svg";
import { ReactComponent as Check } from "../../../../shared/images/svg/check-mark.svg";
import { Button } from "../../../../ui/button/button";
import { Task } from "../../../../core/entities/task";
const newTaskCN = bemCN("new_task");
type ButtonNewGroupProps = {
  OnAddNew?: (task: Task) => void;
  OnClose?: () => void;
  FolderId?: number;
};

export const NewTaskItem = (props: ButtonNewGroupProps) => {
  let { OnAddNew, OnClose, FolderId } = props;
  const [task, setTask] = useState<Task>(
    new Task(0, FolderId ? FolderId : -1, "", "")
  );
  const onClose = () => {
    OnClose && OnClose();
    setTask(new Task());
  };
  const onAdd = () => {
    OnAddNew && OnAddNew(task);
  };
  return (
    <div className={newTaskCN()}>
      <input
        autoComplete="off"
        className="h4"
        type="text"
        placeholder="Название задачи"
        value={task?.name}
        onChange={(e) => setTask({ ...task, name: e.target.value })}
      />
      <input
        autoComplete="off"
        id="description"
        className="h5"
        type="text"
        placeholder="Название задачи"
        value={task?.text}
        onChange={(e) => setTask({ ...task, text: e.target.value })}
      />
      <div>
        <Button white icon={<Cross />} onClick={onClose} />
        <Button white icon={<Check />} onClick={onAdd} />
      </div>
    </div>
  );
};
