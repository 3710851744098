export class NoticeItem{
    type:string;
    count:number;
    moneyCount:number;

    constructor(type: string, count:number, moneyCount:number) {
        this.type = type;
        this.count = count;
        this.moneyCount = moneyCount;
      }
}