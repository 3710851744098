export const reviews = [
  {
    id: 1,
    mark: 5,
    text: 'С момента, как мы начали использовать CRM от XSTAT, управление нашим бизнесом стало гораздо проще. Раньше мы теряли много времени на обработку заказов и анализ данных. Теперь все это автоматизировано, и мы можем сосредоточиться на развитии. Особенно нравится раздел с аналитикой — он помогает нам быстро видеть, какие товары продаются лучше всего. Я рекомендую эту CRM всем, кто хочет упростить свою работу.',
    author: 'Иван Петров, директор магазина',
  },
  {
    id: 2,
    mark: 4,
    text: 'Программа стала настоящим открытием для нашей команды для управления заказами и закупками. С этой системой все данные собраны в одном месте, и мы можем легко отслеживать статусы заказов. Также очень полезно, что есть возможность получать отчеты по продажам - помогает нам принимать решения. Я довольна тем, как работает эта СРМ.',
    author: 'Анна Смирнова, продавец',
  },
  {
    id: 3,
    mark: 5,
    text: 'Работа с XSTAT значительно упростила все. Раньше мы использовали несколько разных систем для учета и анализа, и это создавало путаницу. Теперь есть все необходимое в одном месте, можно заходить с разных устройств, вся информация отображается в режиме реального времени.',
    author: 'Дмитрий Ковалев, управляющий интернет-магазином',
  },
  {
    id: 4,
    mark: 4,
    text: 'Я начала использовать CRM от XSTAT и сразу заметила, как это упростило работу. У нас много поставок и заказов, и теперь все данные легко доступны, и я могу быстро отслеживать статусы. Раздел с инструкциями тоже очень полезен, чтобы разобраться с вопросами. Я рада, что выбрала именно эту программу.',
    author: 'Елена Васильева, менеджер по закупкам',
  },
  {
    id: 5,
    mark: 4,
    text: 'Спасибо вам за программу. Я смог значительно сократить время на обработку заказов. Удобный интерфейс позволяет быстро находить нужную информацию. Теперь мы знаем, что работает, а что нет. Я рекомендую эту CRM всем, кто хочет повысить эффективность своего бизнеса, тратя при этом меньше времени!',
    author: 'Сергей Иванов, продавец',
  },
  {
    id: 6,
    mark: 5,
    text: 'Хочу поблагодарить сервис XStat за отличный инструмент, который помог нам навести порядок в управлении продажами. Все данные теперь доступны в одном месте, очень удобно. Я также могу смотреть аналитику в реальном времени или выбирать нужный период, сравнивать показатели, выгружать отчеты — это помогает быстро реагировать на любые изменения, например, проводить короткие тестовые запуски и сразу получать отчеты. Спасибо!',
    author: 'Мария Федорова, директор магазина',
  },
  {
    id: 7,
    mark: 5,
    text: 'Долго искали инструмент, который бы помогал в управлении рекламными кампаниями и заказами одновременно. поэтому выбрали XSTAT Цена очень приятная. Тем более мы сначала попробовали пробный период и даже обрадовались что все действительно работает на полную. Теперь у нас есть все необходимое в одном месте, мы можем с любых устройств отслеживать эффективность рекламы.',
    author: 'Алексей Сидоров, менеджер по маркетингу',
  },
  {
    id: 8,
    mark: 5,
    text: 'Работа с CRM от XSTAT значительно упростила работу. Я легко отслеживаю статусы заказов и закупок, а также получаю отчеты по продажам. Это помогает мне лучше понимать, как идет бизнес. Удобный интерфейс и возможность фильтрации данных делают работу с системой очень комфортной. Я рекомендую всем, кто ищет эффективное решение для управления продажами.',
    author: 'Ольга Николаева, менеджер по продажам:',
  },
];
